import React, { useState } from "react";
import Index from "../../../../Index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSearchParams } from "react-router-dom";
import { getTopSalesPartyTallyWiseInvoices } from "../../../../../redux/admin/action";
import { NEW_EXPORT_EXCEL } from "../../ExportAll";
function TopSalesPartyWise() {
  const navigate = Index.useNavigate();
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [totalRecordsCount, setTotalRecordsCount] = React.useState(0);

  // sales invoice basis
  const dispatch = Index.useDispatch();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);
  const [topSalesPartyRecords, setTopSalesPartyRecords] = React.useState([]);

  // when the page first time load then used...
  const getTopSalesPartyDetails = (values) => {
    setLoading(true);
    dispatch(
      getTopSalesPartyTallyWiseInvoices({
        ...values,
        companyName: searchParams?.get("companyName"),
        partyName: searchParams?.get("partyName"),
      })
    )
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setTopSalesPartyRecords(res?.data);
          setTotalRecordsCount(res?.totalCount);
          setCurrentPage(res?.currentPage);
        } else {
          setTopSalesPartyRecords([]);
          setLoading(false);
          setTotalRecordsCount(0);
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        setTopSalesPartyRecords([]);
        setLoading(false);
        setTotalRecordsCount(0);
        setCurrentPage(1);
      });
  };

  React.useEffect(() => {
    const values = {
      partyId: searchParams.get("partyId"),
      month: searchParams.get("month"),
      page: 1,
      size: 50,
      to: searchParams.get("to"),
      from: searchParams.get("from"),
    };
    getTopSalesPartyDetails(values);
  }, []);

  const handlePageChange = React.useCallback(
    (event, value) => {
      const values = {
        partyId: searchParams.get("partyId"),
        month: searchParams.get("month"),
        page: value,
        size: paginationPerPage,
        to: searchParams.get("to"),
        from: searchParams.get("from"),
      };
      getTopSalesPartyDetails(values);
      setCurrentPage(value);
    },
    [setCurrentPage, paginationPerPage]
  );

  const backBtn = () => {
    navigate("/admin/companyDashboard");
  };

  const getOverDueDays = (NewDate) => {
    const startDate = Index.moment(new Date());
    var currentDate = Index.moment(NewDate);
    var days = startDate.diff(currentDate, "days");
    return days;
  };

  const handleChangePaginationPerPage = React.useCallback(
    (e) => {
      const values = {
        partyId: searchParams.get("partyId"),
        month: searchParams.get("month"),
        page: currentPage,
        size: e.target.value,
        to: searchParams.get("to"),
        from: searchParams.get("from"),
      };
      getTopSalesPartyDetails(values);
      setPaginationPerPage(e.target.value);
    },
    [currentPage, setPaginationPerPage]
  );

  return (
    <div>
      <Index.DynamicTitle title="Invoice Management" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="invoice-details title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="invoice-back-title title-main">
                <Index.Button
                  type="submit"
                  className="Particular_model_submit back-invoice-btn"
                  onClick={backBtn}
                >
                  <ArrowBackIcon />
                </Index.Button>

                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {searchParams.get("labelPartyName")}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="common-button grey-button ">
                <Index.Button
                  variant="contained"
                  onClick={() => {
                    let curDate = Index.moment(new Date()).format("DD-MM-YYYY");
                    NEW_EXPORT_EXCEL(
                      "/admin/top-ten-sales-party-list",
                      "Top-Sales-Invoice-Details",
                      {
                        partyName: searchParams.get("partyName"),
                        to: searchParams.get("to"),
                        from: searchParams.get("from"),
                        companyName: searchParams.get("companyName"),
                      }
                    );
                  }}
                >
                  Export
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table invoice-table-set  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice No
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice Date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Party Name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Amount
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {topSalesPartyRecords?.length ? (
                      topSalesPartyRecords &&
                      topSalesPartyRecords?.map((row, index) => (
                        <Index.TableRow
                          key={
                            row?.guid +
                            new Date(row?.date).getMilliseconds() +
                            index
                          }
                        >
                          <Index.TableCell>
                            {row?.voucher_number ? row?.voucher_number : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.date
                              ? Index.moment(row?.date).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>{" "}
                          <Index.TableCell>
                            {row?.party_name ? row?.party_name : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.amount
                              ? parseFloat(row?.amount)?.toLocaleString(
                                  "en-IN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={12}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>

          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count pagination-loader">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) =>
                      //   {
                      //   setPaginationPerPage(e.target.value);
                      // }
                      handleChangePaginationPerPage(e)
                    }
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <Index.Pagination
                    count={Math.ceil(totalRecordsCount / paginationPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default TopSalesPartyWise;
