import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getActiveUserList,
  handleUserLatLongHideAndShow,
} from "../../../../redux/admin/action";
import PropTypes from "prop-types";
import GoogleMap from "./map/GoogleMap";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const ActionItem = Index.styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

// style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Design Tab
function SingleOrderTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

SingleOrderTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ActiveUserList() {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeUserList, setActiveUserList] = useState([]);
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();

  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  // get user
  const getActiveUser = () => {
    setLoading(true);
    dispatch(getActiveUserList()).then((res) => {
      if (res?.status === 200) {
        setActiveUserList(res?.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    });
  };
  useEffect(() => {
    getActiveUser();
  }, []);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(activeUserList?.reverse());
  }, [activeUserList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(activeUserList);
    setSearchedData(
      activeUserList?.filter(
        (item) =>
          item?.userId?.employeeName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.lat
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.long
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toLowerCase()) ||
          Index.moment(item?.updatedAt)
            .format("DD/MM/YYYY")
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    const serialNumber = (currentPage - 1) * PER_PAGE + 1;
    setCurrentRows(
      modifiedRows &&
        modifiedRows.map((ele, index) => {
          return { ...ele, sNo: serialNumber + index };
        })
    );
    // setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  // when the hide user lat long data then used....
  const handleHideUserLatLong = (userData) => {
    const values = {
      id: userData?._id,
      isHide: true,
    };
    dispatch(handleUserLatLongHideAndShow(values))
      .then((res) => {
        if (res.status === 200) {
          getActiveUser();
          setActionIndex();
          setAnchorEl(null);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // when the show user lat long data then used..
  const handleShowUserLatLong = (userData) => {
    const values = {
      id: userData?._id,
      isHide: false,
    };
    dispatch(handleUserLatLongHideAndShow(values))
      .then((res) => {
        if (res.status === 200) {
          getActiveUser();
          setActionIndex();
          setAnchorEl(null);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <Index.DynamicTitle title="Active Users" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Active Users
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="mb-20">
            {/* Design Tab */}
            <Index.Box sx={{ width: "100%", mt: 3 }}>
              <Index.Box
                className="tab-button-box"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Index.Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="dashboard-tab"
                >
                  <Index.Tab
                    className="tab-button"
                    label="List"
                    {...a11yProps(0)}
                  />
                  <Index.Tab
                    className="tab-button"
                    label="Map"
                    {...a11yProps(1)}
                  />
                </Index.Tabs>
              </Index.Box>
              <SingleOrderTab
                value={value}
                index={0}
                className="barge-common-box tab-content-box"
              >
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex active-user-list-header">
                    <Index.Box className="d-flex align-items-center res-set-search">
                      <Search className="search ">
                        <StyledInputBase
                          placeholder="Search"
                          inputProps={{ "aria-label": "search" }}
                          onChange={(e) =>
                            setTimeout(() => {
                              requestSearch(e.target.value.trim());
                            }, 1000)
                          }
                        />
                      </Search>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      aria-label="simple table"
                      className="table-design-main supply-table one-line-table invoice-table-set user-management-table "
                    >
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>S.No</Index.TableCell>
                          <Index.TableCell>User Name</Index.TableCell>
                          <Index.TableCell align="left">
                            UpdatedAt
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            Latitude
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            Longitude
                          </Index.TableCell>
                          <Index.TableCell align="left">Action</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      {loading ? (
                        <Index.TableBody>
                          <Index.TableRow>
                            <Index.TableCell
                              colSpan={12}
                              align="center"
                              className="loading-purchase"
                            >
                              <Index.CircularProgress
                                color="secondary"
                                size={20}
                              />
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableBody>
                      ) : (
                        <Index.TableBody className="user-table-body">
                          {currentRows?.length ? (
                            currentRows?.map((item, index) => (
                              <Index.TableRow
                                key={item?._id}
                                className={
                                  item?.is_deleted ? "hide-user-row-color" : ``
                                }
                              >
                                <Index.TableCell>{item?.sNo}</Index.TableCell>
                                <Index.TableCell>
                                  {item?.userId?.employeeName || "-"}
                                </Index.TableCell>

                                <Index.TableCell align="left">
                                  {item?.updatedAt
                                    ? Index.moment(item?.updatedAt).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </Index.TableCell>
                                <Index.TableCell align="left">
                                  {item?.lat || "-"}
                                </Index.TableCell>
                                <Index.TableCell align="left">
                                  {item?.long || "-"}
                                </Index.TableCell>
                                <Index.TableCell sx={{ display: "flex" }}>
                                  <Index.IconButton
                                    onClick={(e) => {
                                      handleClickMenu(e, index);
                                    }}
                                  >
                                    <Index.MenuIcon className="action-menu-icon" />
                                  </Index.IconButton>
                                  <Index.Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    elevation={2}
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMenu}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                  >
                                    {!currentRows?.[actionIndex]?.is_deleted ? (
                                      <>
                                        <ActionItem
                                          className="action-text"
                                          onClick={() => {
                                            handleHideUserLatLong(
                                              currentRows?.[actionIndex]
                                            );
                                          }}
                                        >
                                          Hide
                                        </ActionItem>
                                      </>
                                    ) : (
                                      <>
                                        <ActionItem
                                          className="action-text"
                                          onClick={() => {
                                            handleShowUserLatLong(
                                              currentRows?.[actionIndex]
                                            );
                                          }}
                                        >
                                          Show
                                        </ActionItem>
                                      </>
                                    )}
                                  </Index.Menu>
                                </Index.TableCell>
                              </Index.TableRow>
                            ))
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="no-data-in-list"
                                colSpan={15}
                                align="center"
                              >
                                No data available
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      )}
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
                <Index.Box className="pagination-design flex-end">
                  <Index.Stack spacing={2}>
                    <Index.Box className="pagination-count">
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          fullWidth
                          value={paginationPerPage}
                          onChange={(e) => {
                            setPaginationPerPage(e.target.value);
                          }}
                        >
                          <Index.MenuItem value={10}>10</Index.MenuItem>
                          <Index.MenuItem value={25}>25</Index.MenuItem>
                          <Index.MenuItem value={50}>50</Index.MenuItem>
                          <Index.MenuItem value={100}>100</Index.MenuItem>
                        </Index.Select>
                      </Index.Box>
                      <Index.Pagination
                        count={count}
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    </Index.Box>
                  </Index.Stack>
                </Index.Box>
              </SingleOrderTab>
              <SingleOrderTab
                value={value}
                index={1}
                className="barge-common-box tab-content-box"
              >
                <GoogleMap
                  loading={loading}
                  activeLocationData={activeUserList}
                />
              </SingleOrderTab>
            </Index.Box>
            {/* Design Tab */}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default ActiveUserList;
