import { Formik } from "formik";
import Index from "../../../Index";
import * as Yup from "yup";
import { Autocomplete, createFilterOptions } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  addOrder,
  deleteCurrentOrderDocument,
  getBargeListByCategory,
  getOrder,
  getOrderTraderList,
  getPortList,
} from "../../../../redux/admin/action";

import { terminalData } from "../../../../config/CommonTerminalItems";
import { commonCFData } from "../../../../config/CommonCF";
import {
  productData,
  transportationData,
} from "../../../../config/CommonProductsItem";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import APIService from "../../../../redux/APIService";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import NewCustomSearchVessel from "./Filteration/NewCustomSearchVessel";

const validationSchema = Yup.object().shape({
  wharfageFile: Yup.array()
    // .min(1, "Select at least one pdf file")
    .test("fileType", "Unsupported file format. only PDF allow", (value) => {
      // Check if each file has a valid image extension
      if (!value) return false;
      for (const file of value) {
        const validExtensions = ["pdf"]; // Add more valid extensions as needed
        const fileExtension = file?.name
          ? file?.name?.split(".")?.pop().toLowerCase()
          : file.split(".")[1]?.toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          return false;
        }
      }
      return true;
    }),
  nominationPdf: Yup.mixed().test(
    "FileType",
    "Unsupported file format. only PDF allow",
    (value) => {
      if (!value) return true;
      if (!value?.name) {
        const file = value.split(".")[1];
        return ["pdf"].includes(file); //edit
      } else {
        return ["application/pdf"].includes(value?.type); //add
      }
    }
  ),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function NewEditCurrentOrder() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const params = Index.useParams();

  const filter = createFilterOptions();
  const { nameOfVesselList } = Index.useSelector((state) => state.AdminReducer);
  const login = Index.useSelector((state) => state.AdminReducer.adminDetails);

  const [loading, setLoading] = useState(false);
  const [vesselNameList, setVesselNameList] = useState([]);
  const [allPortList, setAllPortList] = useState([]);
  const [bargeListCatData, setBargeListCatData] = useState([]);
  const [allOrderTraderList, SetAllOrderTraderList] = useState([]);
  const [editTimeOrderDetails, setEditTimeOrderDetails] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [wharfagePdfName, setWharfagePdfName] = useState("");

  let initialValues = useMemo(
    () => ({
      inquiryNo: editTimeOrderDetails?.orderNo
        ? editTimeOrderDetails?.orderNo
        : "-",
      nomination_rec_date: editTimeOrderDetails
        ? editTimeOrderDetails?.nomination_rec_date
        : "",
      vesselName: editTimeOrderDetails?.vesselName
        ? editTimeOrderDetails?.vesselName
        : "",
      exmiOrDelivered: editTimeOrderDetails?.exmiOrDelivered
        ? editTimeOrderDetails?.exmiOrDelivered
        : "",
      port: editTimeOrderDetails?.hasOwnProperty("port")
        ? editTimeOrderDetails?.port?._id
        : "",
      traderId: editTimeOrderDetails?.traderId
        ? editTimeOrderDetails?.traderId?._id
        : "",
      terminal: editTimeOrderDetails?.terminal
        ? editTimeOrderDetails?.terminal
        : "",
      foreign: editTimeOrderDetails?.foreign
        ? editTimeOrderDetails?.foreign
        : "",
      product: editTimeOrderDetails ? editTimeOrderDetails?.product : "",

      nominatedQty:
        editTimeOrderDetails?.lowerQuantity === "0" &&
        editTimeOrderDetails?.higherQuantity === "0"
          ? ""
          : editTimeOrderDetails?.lowerQuantity &&
            editTimeOrderDetails?.higherQuantity != "0"
          ? editTimeOrderDetails?.lowerQuantity +
            " - " +
            editTimeOrderDetails?.higherQuantity
          : editTimeOrderDetails?.lowerQuantity,
      agent: editTimeOrderDetails?.agent ? editTimeOrderDetails?.agent : "",
      laycam: editTimeOrderDetails?.laycam ? editTimeOrderDetails?.laycam : "",
      laydate:
        editTimeOrderDetails?.layStartDate && editTimeOrderDetails?.layEndDate
          ? [
              dayjs(new Date(editTimeOrderDetails?.layStartDate)),
              dayjs(new Date(editTimeOrderDetails?.layEndDate)),
            ]
          : [dayjs(""), dayjs("")],
      etb: editTimeOrderDetails?.etb
        ? Index.moment(editTimeOrderDetails?.etb).format("YYYY-MM-DDTHH:mm")
        : "",
      etd: editTimeOrderDetails?.etd
        ? Index.moment(editTimeOrderDetails?.etd).format(
            "YYYY-MM-DDTHH:mm:ssZZ"
          )
        : "",

      stayHours: editTimeOrderDetails?.stayHours
        ? editTimeOrderDetails?.stayHours
        : "",
      companyName: editTimeOrderDetails?.companyName
        ? editTimeOrderDetails?.companyName
        : "",
      loadingDate: editTimeOrderDetails?.loadingDate
        ? Index.moment(editTimeOrderDetails?.loadingDate).format("YYYY-MM-DD")
        : "",
      supplyDate: editTimeOrderDetails?.supplyDate
        ? editTimeOrderDetails?.supplyDate
        : "",
      basisTakenOn: editTimeOrderDetails?.basisTakenOn
        ? editTimeOrderDetails?.basisTakenOn
        : "",
      transportation: editTimeOrderDetails?.transportation
        ? editTimeOrderDetails?.transportation
        : "",
      // proposedBarge: editTimeOrderDetails?.proposedBarge?.length
      //   ? editTimeOrderDetails?.proposedBarge?.map((el) => el._id)
      //   : [],
      nominationPdf: "",
      wharfageFile: [],
      shippingBill: editTimeOrderDetails?.shippingBill
        ? editTimeOrderDetails?.shippingBill
        : false,
      wharfage: editTimeOrderDetails?.wharfage
        ? editTimeOrderDetails?.wharfage
        : false,
      harbourMaster: editTimeOrderDetails?.harbourMaster
        ? editTimeOrderDetails?.harbourMaster
        : false,
      bunkerCall: editTimeOrderDetails?.bunkerCall
        ? editTimeOrderDetails?.bunkerCall
        : false,
      remark: editTimeOrderDetails?.remark ? editTimeOrderDetails?.remark : "",
    }),
    [editTimeOrderDetails]
  );

  //   useEffect(() => {
  //     const vesselData = nameOfVesselList?.filter((d) =>
  //       d?.vesselName?.toUpperCase()
  //     );
  //     const uniqueArray = vesselData?.filter(
  //       (v, i, a) => a?.findIndex((t) => t?.vesselName === v?.vesselName) === i
  //     );
  //     if (login?.role === "Operation") {
  //       // Role Operation then vesselName show in uppercase
  //       const newData = uniqueArray?.sort((a, b) =>
  //         a?.vesselName?.localeCompare(b?.vesselName)
  //       );
  //       setVesselNameList(
  //         newData?.map((e) => ({
  //           ...e,
  //           vesselName: e?.vesselName?.toUpperCase(),
  //         }))
  //       );
  //     } else {
  //       setVesselNameList(
  //         uniqueArray?.sort((a, b) => a?.vesselName?.localeCompare(b?.vesselName))
  //       );
  //     }
  //   }, [nameOfVesselList]);

  //   when the get all port list then used...
  const handleGetAllPorts = () => {
    dispatch(getPortList())
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setAllPortList(res.data);
        } else {
          setAllPortList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setAllPortList([]);
      });
  };

  //   when the user get the barge list category wise then used...
  const handleGetBargeListByCategoryWise = () => {
    dispatch(getBargeListByCategory()).then((res) => {
      if (res.status === 200 || res.status === 201) {
        console.log("@@@@@@@@", res.data);
        setBargeListCatData(
          res?.data?.sort((a, b) => a?.bargeName?.localeCompare(b?.bargeName))
        );
      }
    });
  };

  //   when the get edit time order data then used...
  const handleGetCurrentOrderDetails = () => {
    const values = {
      id: params?.id,
    };
    dispatch(getOrder(values))
      .then((res) => {
        if (res.status === 200) {
          setEditTimeOrderDetails(res.data[0]);
          console.log("res.data[0]", res.data[0]);
          if (res.data[0]?.wharfageFile?.length) {
            setWharfagePdfName(res.data[0]?.wharfageFile);
          }
          setPdfName(res.data[0]?.nominationPdf);
        } else {
          setEditTimeOrderDetails("");
        }
      })
      .catch((err) => {
        setEditTimeOrderDetails("");
      });
  };

  //   when the get trader list then used...
  const handlGetAllTraders = () => {
    dispatch(getOrderTraderList())
      .then((res) => {
        if (res.status === 200) {
          SetAllOrderTraderList(res.data);
        } else {
          SetAllOrderTraderList([]);
        }
      })
      .catch((err) => {
        SetAllOrderTraderList([]);
      });
  };

  //   when the page first time load then used...
  useEffect(() => {
    handleGetAllPorts();
    handleGetBargeListByCategoryWise();
    handlGetAllTraders();
    // edit time get data basis...
    handleGetCurrentOrderDetails();
  }, []);

  // when the user is back then used...
  const handleBack = () => {
    navigate("/admin/order");
  };

  //   when the user should change the port then used...
  const handleChangePortSelection = async (e, setFieldValue) => {
    console.log("e.target.value", e.target.value);
    setFieldValue("port", e.target.value);
    // setFieldValue("proposedBarge", []);
    const response = await APIService.doGetQueryParams(
      "/admin/getBargeListByCategory",
      {
        params: { portId: e.target.value },
      }
    );
    if (response?.data?.status === 200) {
      setBargeListCatData(
        response?.data?.data?.sort((a, b) =>
          a?.bargeName?.localeCompare(b?.bargeName)
        )
      );
    }
  };

  // when the remove profoma pdf then used...
  const handleRemoveProformaPdf = (profomaFileName, currentOrderId) => {
    const values = {
      id: currentOrderId,
      file: profomaFileName,
      nominationPdf: "nominationPdf",
    };
    // here is reset the field
    setPdfName("");

    dispatch(deleteCurrentOrderDocument(values)).then((res) => {
      if (res.status === 200) {
        // here through get latest data for edit time
        handleGetCurrentOrderDetails();
      }
    });
  };

  // when the remove profoma pdf locally then used...
  const handleRemoveProformaPdfLocally = (
    proformaFileName,
    setFieldValue,
    values
  ) => {
    setPdfName("");
    setFieldValue("nominationPdf", "");
  };

  // when the remove wharfate pdf then used...
  const handleRemoveWharfagePdf = (wharfageFileName, currentOrderId) => {
    const values = {
      id: currentOrderId,
      file: wharfageFileName,
      wharfageFile: "wharfageFile",
    };
    dispatch(deleteCurrentOrderDocument(values)).then((res) => {
      if (res.status === 200) {
        // here through get latest data for edit time
        handleGetCurrentOrderDetails();
      }
    });
  };

  // when the user add wharfage pdf but not uploaded then used...
  const handleRemoveWharfagePdfLocally = (
    wharfageFileName,
    setFieldValue,
    values
  ) => {
    setWharfagePdfName((prev) => [
      ...prev.filter((res) => res !== wharfageFileName),
    ]);
    setFieldValue(
      "wharfageFile",
      values.wharfageFile.filter((file) => file.name !== wharfageFileName)
    );
  };

  //   when the user submit the form then used...
  const handleFormSubmit = (values) => {
    const data = new FormData();
    if (values?.nomination_rec_date) {
      data.append("nomination_rec_date", values?.nomination_rec_date);
    }
    if (values?.vesselName) {
      data.append("vesselName", values?.vesselName?.trim());
    }
    if (values?.port) {
      data.append("port", values?.port);
    }
    if (values?.traderId) {
      data.append("traderId", values?.traderId);
    }
    if (values?.terminal != undefined) {
      data.append("terminal", values?.terminal);
    }
    data.append("foreign", values?.foreign);
    if (values?.nominatedQty) {
      data.append("nominatedQty", values.nominatedQty);
    }
    data.append("product", values?.product);
    data.append("agent", values?.agent ? values?.agent : "");

    if (values?.laycam) {
      data.append(
        "laycam",
        Index.moment(values?.laycam).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }

    if (values?.laydate) {
      const layStartDate = values.laydate[0]["$D"];
      const layEndDate = values.laydate[1]["$D"];

      if (!isNaN(layStartDate) && !isNaN(layEndDate)) {
        data.append(
          "laydate",
          values?.laydate?.map((date) => {
            return Index.moment(date.$d).format("YYYY-MM-DD");
          })
        );
      }
    }
    if (values?.loadingDate) {
      data.append("loadingDate", values?.loadingDate);
    }

    if (values?.supplyDate) {
      data.append(
        "supplyDate",
        Index.moment(values?.supplyDate).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.basisTakenOn) {
      data.append("basisTakenOn", values?.basisTakenOn);
    }
    // if (values?.proposedBarge?.length) {
    //   data.append("proposedBarge", values?.proposedBarge);
    // }

    if (values?.etb) {
      data.append(
        "etb",
        Index.moment(values?.etb).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }

    if (values?.etd) {
      data.append(
        "etd",
        Index.moment(values?.etd).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    data.append("stayHours", values?.stayHours ? values?.stayHours : "");
    if (values?.companyName != undefined) {
      data.append("companyName", values?.companyName);
    }
    if (values?.transportation != undefined) {
      data.append("transportation", values?.transportation);
    }
    if (values?.nominationPdf) {
      data.append("nominationPdf", values?.nominationPdf);
    }
    if (values?.wharfageFile?.length > 0) {
      values.wharfageFile.forEach((ele, i) => {
        data.append("wharfageFile", ele);
      });
    }
    if (values?.exmiOrDelivered != undefined) {
      data.append("exmiOrDelivered", values?.exmiOrDelivered);
    }

    data.append("remark", values?.remark);
    data.append("shippingBill", values?.shippingBill);
    data.append("wharfage", values?.wharfage);
    data.append("harbourMaster", values?.harbourMaster);
    data.append("bunkerCall", values?.bunkerCall);

    setLoading(true);
    if (editTimeOrderDetails) {
      data.append("id", editTimeOrderDetails?._id);
    }

    data.forEach((obj, key) => {
      console.log(obj, "::::::", key);
    });
    dispatch(addOrder(data, navigate)).then((res) => {
      if (res.status === 200) {
        navigate("/admin/order");
      }
    });
  };

  console.log("editTimeOrderDetails", editTimeOrderDetails);

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editTimeOrderDetails ? "Edit Order" : "Add order"}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0 cancel-order-btn"
                  onClick={handleBack}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box>
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Enquiry No.
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Enquiry No."
                          disabled
                          variant="filled"
                          name="inquiryNo"
                          className="admin-input-design input-placeholder"
                          value={values?.inquiryNo}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Nomination Receiving Date
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box
                          className="set-date set-date-picker picker-box-set "
                          //   onFocus={handleFocus}
                        >
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  fullWidth
                                  format="DD/MM/YYYY"
                                  name="nomination_rec_date"
                                  maxDate={Index.dayjs(new Date())}
                                  value={
                                    values.nomination_rec_date
                                      ? Index.dayjs(
                                          new Date(values.nomination_rec_date)
                                        )
                                      : null
                                  }
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY/MM/DD");
                                    setFieldValue(
                                      "nomination_rec_date",
                                      formatedDate
                                    );
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Vessel Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border cus-search-marg">
                        <NewCustomSearchVessel
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Enter VesselName"
                          variant="filled"
                          className="admin-input-design input-placeholder cus-search-mar"
                          name="vesselName"
                          autoComplete="off"
                          setFieldValue={setFieldValue}
                          apiEndpoint={"/admin/get-vessel-name-by-search"}
                          value = {values.vesselName}
                        />
                      </Index.Box>
                    </Index.Grid>
                    {console.log("values", values)}
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Exmi Or Delivered
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="exmiOrDelivered"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={values ? values?.exmiOrDelivered : ""}
                          //   onChange={(e) => {
                          //     setFieldValue("exmiOrDelivered", e.target.value);
                          //   }}
                          onChange={handleChange}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select exmi or delivered
                            </Index.MenuItem>
                          </Index.MenuItem>
                          <Index.MenuItem value="Delivered">
                            Delivered
                          </Index.MenuItem>
                          <Index.MenuItem value="Exmi">Exmi</Index.MenuItem>
                        </Index.Select>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Port
                      </Index.Typography>

                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="terminal"
                          displayEmpty
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          value={
                            values.port
                            //   ? allPortList.find(
                            //       (item) => item._id === values.port
                            //     )
                            //   : null
                          }
                          onChange={(event) => {
                            // setFieldValue("port", e.target.value);
                            handleChangePortSelection(event, setFieldValue);
                          }}
                        >
                          <Index.MenuItem>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select Port
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {allPortList?.map((items, index) => (
                            <Index.MenuItem value={items?._id} key={items?._id}>
                              {items?.portName}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Order Received From / Trader
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {console.log("allOrderTraderList", allOrderTraderList)}
                        <Index.Autocomplete
                          disablePortal
                          className="invoice-select-drop"
                          id="combo-box-demo"
                          options={allOrderTraderList}
                          getOptionLabel={(option) => option?.traderName}
                          value={
                            values?.traderId
                              ? allOrderTraderList?.find(
                                  (res) => res._id === values.traderId
                                )
                              : null
                          }
                          renderOption={(props, option) => (
                            <Index.Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.traderName}
                            </Index.Box>
                          )}
                          onChange={(event, newValue) => {
                            setFieldValue("traderId", newValue?._id);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Order Received From / Trader"
                              variant="outlined"
                            />
                          )}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Terminal
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Autocomplete
                          disablePortal
                          className="invoice-select-drop"
                          id="combo-box-demo"
                          options={terminalData}
                          getOptionLabel={(option) => option?.item}
                          value={
                            values.terminal
                              ? terminalData.find(
                                  (item) => item.item === values.terminal
                                )
                              : null
                          }
                          renderOption={(props, option) => (
                            <Index.Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.item}
                            </Index.Box>
                          )}
                          onChange={(event, newValue) => {
                            setFieldValue("terminal", newValue?.item);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Terminal name"
                              variant="outlined"
                            />
                          )}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        C/F
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Autocomplete
                          disablePortal
                          className="invoice-select-drop"
                          id="combo-box-demo"
                          options={commonCFData}
                          getOptionLabel={(option) => option?.name}
                          value={
                            values.foreign
                              ? commonCFData.find(
                                  (res) => res.value === values.foreign
                                )
                              : null
                          }
                          renderOption={(props, option) => (
                            <Index.Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.name}
                            </Index.Box>
                          )}
                          onChange={(event, newValue) => {
                            setFieldValue("foreign", newValue?.value);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="C/F name"
                              variant="outlined"
                            />
                          )}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Product Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="terminal"
                          value={values?.product}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.product : ""}
                          onChange={(e) => {
                            setFieldValue("product", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select product
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {productData?.map((items, index) => (
                            <Index.MenuItem
                              value={items?.item}
                              key={items?._id}
                            >
                              {items?.item}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Nominated Qty (In MT)
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Nominated qty (In MT)"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="nominatedQty"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          //   onFocus={handleFocus}
                          value={values?.nominatedQty}
                          // inputProps={}
                          step={1.0}
                          error={Boolean(
                            errors?.nominatedQty
                              ? errors?.nominatedQty
                              : undefined
                          )}
                          helperText={
                            errors?.nominatedQty
                              ? errors?.nominatedQty
                              : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Agent
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Agent"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="agent"
                          //   onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.agent}
                          error={Boolean(
                            errors?.agent ? errors?.agent : undefined
                          )}
                          helperText={errors?.agent ? errors?.agent : undefined}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        ETA for Vessel
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="laycam"
                                clearButton={true}
                                value={
                                  values?.laycam
                                    ? dayjs(new Date(values?.laycam))
                                    : null
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("laycam", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        ETB
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="etb"
                                value={
                                  values?.etb
                                    ? dayjs(new Date(values?.etb))
                                    : null
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("etb", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                                renderInput={(params) => (
                                  <Index.TextField {...params} />
                                )}
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        ETD
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="etd"
                                value={
                                  values?.etd
                                    ? dayjs(new Date(values?.etd))
                                    : null
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("etd", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Stay Hours
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Stay hours"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="stayHours"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          //   onFocus={handleFocus}
                          value={values?.stayHours}
                          error={Boolean(
                            errors?.stayHours ? errors?.stayHours : undefined
                          )}
                          helperText={
                            errors?.stayHours ? errors?.stayHours : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Company Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="companyName"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={values.companyName}
                          onChange={(e) => {
                            setFieldValue("companyName", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select company
                            </Index.MenuItem>
                          </Index.MenuItem>
                          <Index.MenuItem value="BSJPL">BSJPL</Index.MenuItem>
                          <Index.MenuItem value="SKQPL">SKQPL</Index.MenuItem>
                        </Index.Select>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Laycan From to To
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker remove-name picker-box-set">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "DateRangePicker",
                                "DateRangePicker",
                              ]}
                            >
                              <DateRangePicker
                                format="DD/MM/YYYY"
                                value={values?.laydate}
                                onChange={(newValue) => {
                                  setFieldValue("laydate", newValue);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Loading Planned Date
                      </Index.Typography>
                      <Index.Box className="input-design-div ">
                        <Index.Box
                          className="set-date set-date-picker picker-box-set"
                          //   onFocus={handleFocus}
                        >
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  name="loadingDate"
                                  format="DD/MM/YYYY"
                                  value={
                                    editTimeOrderDetails
                                      ? Index.dayjs(
                                          new Date(values?.loadingDate)
                                        )
                                      : ""
                                  }
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DD");
                                    setFieldValue("loadingDate", formatedDate);
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Transportation
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="transportation"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={values?.transportation}
                          onChange={(e) => {
                            setFieldValue("transportation", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select transportation
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {transportationData?.map((item, index) => {
                            return (
                              <Index.MenuItem value={item?.name}>
                                {item?.name}
                              </Index.MenuItem>
                            );
                          })}
                        </Index.Select>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Supply Date
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="supplyDate"
                                value={
                                  values?.supplyDate
                                    ? dayjs(new Date(values?.supplyDate))
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("supplyDate", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Basis Taken On
                      </Index.Typography>
                      <Index.Box className="input-design-div ">
                        <Index.Box
                          className="set-date set-date-picker picker-box-set"
                          //   onFocus={handleFocus}
                        >
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  name="basisTakenOn"
                                  format="DD/MM/YYYY"
                                  value={
                                    editTimeOrderDetails
                                      ? Index.dayjs(
                                          new Date(
                                            editTimeOrderDetails?.basisTakenOn
                                          )
                                        )
                                      : ""
                                  }
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY/MM/DD");
                                    setFieldValue("basisTakenOn", formatedDate);
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Box className="check-wrapper">
                        <Index.Box className="input-design-div with-border">
                          <Index.FormControlLabel
                            control={
                              <Index.Checkbox
                                checked={values?.shippingBill}
                                onChange={(event) => {
                                  setFieldValue(
                                    "shippingBill",
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                            label="Shipping Bill"
                          />
                        </Index.Box>
                        <Index.Box className="input-design-div with-border">
                          <Index.FormControlLabel
                            control={
                              <Index.Checkbox
                                checked={values?.wharfage}
                                onChange={(event) => {
                                  setFieldValue(
                                    "wharfage",
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                            label="Wharfage"
                          />
                        </Index.Box>
                        <Index.Box className="input-design-div with-border">
                          <Index.FormControlLabel
                            control={
                              <Index.Checkbox
                                checked={values?.harbourMaster}
                                onChange={(event) => {
                                  setFieldValue(
                                    "harbourMaster",
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                            label="Harbour Master Permission"
                          />
                        </Index.Box>
                        <Index.Box className="input-design-div with-border">
                          <Index.FormControlLabel
                            control={
                              <Index.Checkbox
                                checked={values?.bunkerCall}
                                onChange={(event) => {
                                  setFieldValue(
                                    "bunkerCall",
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                            label="Bunker Call"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={6}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Proforma Invoice PDF
                      </Index.Typography>

                      <Index.Box className="input-design-div with-border position-relative">
                        <Index.Box className="pdf-design">
                          Upload PDF :
                        </Index.Box>
                        <Index.TextField
                          fullWidth
                          type="file"
                          name="nominationPdf"
                          id="filled-hidden-label-normal"
                          variant="filled"
                          //   onFocus={handleFocus}
                          onBlur={handleBlur}
                          className="admin-input-design input-placeholder pdf-upload-design"
                          onChange={(e) => {
                            setFieldValue("nominationPdf", e.target?.files[0]);
                            setPdfName(e.target?.files[0]?.name);
                          }}
                          inputProps={{
                            className: "input_props",
                          }}
                          FormHelperTextProps={{
                            className: "input_label_props",
                          }}
                        />
                      </Index.Box>
                      <Index.Box sx={{ marginTop: "5px" }}>
                        {pdfName !== "" && pdfName?.length ? (
                          <>
                            <Index.Box className="order-pdf-list-view">
                              {pdfName}
                              {editTimeOrderDetails?.nominationPdf !==
                              "pdfName" ? (
                                <>
                                  <Index.DeleteIcon
                                    color="error"
                                    fontSize="small"
                                    onClick={() => {
                                      handleRemoveProformaPdfLocally(
                                        pdfName,
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <Index.DeleteIcon
                                    color="error"
                                    fontSize="small"
                                    onClick={() =>
                                      handleRemoveProformaPdf(
                                        pdfName,
                                        editTimeOrderDetails?._id
                                      )
                                    }
                                  />
                                </>
                              )}
                            </Index.Box>
                          </>
                        ) : (
                          <></>
                        )}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={6}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Wharfage PDF
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border position-relative">
                        <Index.Box className="pdf-design">
                          Upload PDF :
                        </Index.Box>
                        <Index.TextField
                          fullWidth
                          type="file"
                          name="wharfageFile"
                          multiple
                          id="filled-hidden-label-normal"
                          variant="filled"
                          //   onFocus={handleFocus}
                          onBlur={handleBlur}
                          className="admin-input-design input-placeholder pdf-upload-design"
                          onChange={(event) => {
                            const files = Array.from(event.target.files);
                            const fileNames = files.map((item) => item.name);
                            setWharfagePdfName((prev) => [
                              ...prev,
                              ...fileNames,
                            ]);
                            setFieldValue("wharfageFile", [...files]);
                          }}
                          inputProps={{
                            multiple: true,
                          }}
                          FormHelperTextProps={{
                            className: "input_label_props",
                          }}
                        />
                      </Index.Box>
                      <Index.Box
                        sx={{
                          marginTop: "5px",
                        }}
                      >
                        {wharfagePdfName?.length > 0 &&
                          wharfagePdfName?.map((item, i) => (
                            <>
                              <Index.Box className="order-pdf-list-view">
                                {i + 1}.{" "}
                                {editTimeOrderDetails
                                  ? item
                                      .split("-")
                                      ?.filter((res, index) => index >= 1)
                                      ?.join("_")
                                  : item}
                                {/* </Index.Typography> */}
                                {!editTimeOrderDetails?.wharfageFile?.find(
                                  (res) => res === item
                                ) ? (
                                  <>
                                    {/* witout uploaded removed then used... */}
                                    <Index.DeleteIcon
                                      color="error"
                                      fontSize="small"
                                      onClick={() => {
                                        handleRemoveWharfagePdfLocally(
                                          item,
                                          setFieldValue,
                                          values
                                        );
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Index.DeleteIcon
                                      color="error"
                                      fontSize="small"
                                      onClick={() =>
                                        handleRemoveWharfagePdf(
                                          item,
                                          editTimeOrderDetails?._id
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </Index.Box>
                            </>
                          ))}
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Proposed Barge
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border ">
                        <Index.Select
                        className="propose-drop"
                          fullWidth
                          name="proposedBarge"
                          multiple
                          displayEmpty
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "without label" }}
                          value={
                            values?.proposedBarge ? values?.proposedBarge : []
                          }
                          renderValue={
                            values?.proposedBarge != ""
                              ? undefined
                              : () => "Please select barge"
                          }
                          //   onFocus={handleFocus}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            setFieldValue(
                              "proposedBarge",
                              typeof value === "string"
                                ? value.split(",")
                                : value
                            );
                          }}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Index.MenuItem disabled value="">
                            {bargeListCatData?.length > 0 ? (
                              <em>Please select a barge</em>
                            ) : (
                              ""
                            )}
                          </Index.MenuItem>
                          {bargeListCatData?.length === 0 ? (
                            <Index.MenuItem disabled>
                              <Index.Box className="multiselect-item">
                                No data found
                              </Index.Box>
                            </Index.MenuItem>
                          ) : (
                            bargeListCatData.map((item) => (
                              <Index.MenuItem
                                key={item?._id}
                                value={item?._id}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Index.Box className="multiselect-item">
                                  {item?.bargeName}
                                </Index.Box>
                              </Index.MenuItem>
                            ))
                          )}
                        </Index.Select>
                      </Index.Box>
                    </Index.Grid> */}
                    <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Remark
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextareaAutosize
                          hiddenLabel
                          minRows={3}
                          id="filled-hidden-label-normal"
                          className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set "
                          placeholder="Remark"
                          variant="filled"
                          name="remark"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.remark}
                        />
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {editTimeOrderDetails ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default NewEditCurrentOrder;
