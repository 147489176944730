import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  changePurchaseDirectIndirectType,
  getAllCompanyList,
  getNewPurchaseList,
  getPurchaseList,
} from "../../../../redux/admin/action";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const BetaPurchase = () => {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(true);
  const [purchaseExpenseType, setPurchaseExpenseType] = React.useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [totalPurchaseRecord, setTotalPurchaseRecord] = useState(0);
  const [searchedPurchaseText, setSearchedPurchaseText] = useState("");
  const [purchaseRecordList, setPurchaseRecordList] = useState([]);
  // const [serachedDateRange, setSearchedDateRange] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    "Ms. Bapus Shipping Jamnagar Pvt. Ltd. (15-18)"
  );
  const [serachedDateRange, setSearchedDateRange] = useState([
    dayjs().startOf("month").format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ]);

  const [selectedDate, setSelectedDate] = useState(dayjs().startOf("month"));

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        // search: searchedPurchaseText,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0 && serachedDateRange?.[0] !== null
            ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0 && serachedDateRange?.[1] !== null
            ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
            : "",
        companyName: selectedCompany,
      };
      getPurchase(values);
    },
    [
      paginationPerPage,
      searchedPurchaseText,
      selectedCompany,
      serachedDateRange,
      setCurrentPage,
    ]
  );

  // when the get all the company list then used...
  const handleGetAllCompanyList = () => {
    dispatch(getAllCompanyList())
      .then((res) => {
        if (res.status === 200) {
          setCompanyList(res.data);
        } else {
          setCompanyList([]);
        }
      })
      .catch((error) => {
        setCompanyList([]);
        console.error(error);
      });
  };

  // get purchase list
  const getPurchase = (values) => {
    setLoading(true);

    dispatch(getNewPurchaseList(values)).then((res) => {
      // dispatch(getPurchaseList(values)).then((res) => {
      if (res?.status === 200) {
        setTotalPurchaseRecord(res?.totalCount);
        setLoading(false);
        setCurrentPage(parseInt(res?.currentPage));
        setPurchaseRecordList(res.data);
        setPurchaseExpenseType([]);
      } else {
        setLoading(false);
        setPurchaseExpenseType([]);
        setPurchaseRecordList([]);
        setTotalPurchaseRecord(0);
        setCurrentPage(1);
      }
    });
  };
  useEffect(() => {
    handleGetAllCompanyList();
    const values = {
      page: 1,
      //   search: "",
      size: paginationPerPage,
      to:
        serachedDateRange?.length > 0 && serachedDateRange?.[0] !== null
          ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0 && serachedDateRange?.[1] !== null
          ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
          : "",
      companyName: selectedCompany,
    };
    getPurchase(values);
  }, []);

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = React.useCallback(
    (e) => {
      const { value } = e.target;
      setPaginationPerPage(value);
      const values = {
        page: 1,
        // search: searchedPurchaseText,
        size: value,
        to:
          serachedDateRange?.length > 0 && serachedDateRange?.[0] !== null
            ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0 && serachedDateRange?.[1] !== null
            ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
            : "",
        companyName: selectedCompany,
      };
      getPurchase(values);
    },
    [
      serachedDateRange,
      searchedPurchaseText,
      selectedCompany,
      setPaginationPerPage,
    ]
  );

  const handleChangeDate = React.useCallback(
    (data) => {
      setSelectedDate(data);

      const startOfMonth = dayjs(data).startOf("month").format("YYYY-MM-DD");
      let endOfMonth;
      const selectedMonth = data.month();
      const selectedYear = data.year();
      const currentMonth = dayjs().month();
      const currentYear = dayjs().year();
      if (selectedMonth === currentMonth && selectedYear === currentYear) {
        endOfMonth = dayjs().format("YYYY-MM-DD"); // Current date if it's the current month
      } else {
        endOfMonth = data.endOf("month").format("YYYY-MM-DD"); // End of the selected month
      }
      setSearchedDateRange([startOfMonth, endOfMonth]);
      if (startOfMonth !== null && endOfMonth !== null) {
        const values = {
          page: 1,
          size: paginationPerPage,
          to: startOfMonth !== null ? startOfMonth : "",
          from: endOfMonth !== null ? endOfMonth : "",
          companyName: selectedCompany,
        };
        getPurchase(values);
      }
    },
    [serachedDateRange, paginationPerPage, selectedCompany]
  );

  // when the user change the expense type then used...
  const handleChangeExpenseType = (e, data) => {
    const values = {
      guid: data?.guid,
      expenseType: e.target.value,
      typeOfPurchase: data?.voucher_type,
    };
    setPurchaseExpenseType((prev) => [
      ...prev.filter((res) => res.guid !== data?.guid),
      values,
    ]);
    dispatch(changePurchaseDirectIndirectType(values));
  };

  const bankData = {};
  return (
    <div>
      <Index.DynamicTitle title="Purchase Management" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Purchase Management
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Index.Box className="input-design-div">
                  <Index.LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month"]}
                      minDate={dayjs("2024-01-01")}
                      maxDate={dayjs()}
                      value={selectedDate}
                      onChange={(newValue) => handleChangeDate(newValue)}
                      renderInput={(params) => (
                        <Index.TextField {...params} helperText={null} />
                      )}
                    />
                  </Index.LocalizationProvider>
                </Index.Box>

                <Index.Box>
                  <Index.Box className="input-design-div with-border">
                    <Index.Select
                      className="placeholder-set-text-box"
                      fullWidth
                      name="companyId"
                      displayEmpty
                      inputProps={{ "aria-label": "without label" }}
                      value={selectedCompany}
                      MenuProps={MenuProps}
                      onChange={(event) => {
                        const {
                          target: { value },
                        } = event;
                        setSelectedCompany(value);
                        const values = {
                          page: 1,
                          //   search: "",
                          size: paginationPerPage,
                          to:
                            serachedDateRange?.length > 0 &&
                            serachedDateRange?.[0] !== null
                              ? dayjs(serachedDateRange[0]).format("YYYY-MM-DD")
                              : "",
                          from:
                            serachedDateRange?.length > 0 &&
                            serachedDateRange?.[1] !== null
                              ? dayjs(serachedDateRange[1]).format("YYYY-MM-DD")
                              : "",
                          companyName: value,
                        };
                        getPurchase(values);
                      }}
                    >
                      <Index.MenuItem value={""}>
                        <Index.MenuItem disabled className="all-select-label">
                          Select Company
                        </Index.MenuItem>
                      </Index.MenuItem>

                      {companyList?.map((item) => (
                        <Index.MenuItem
                          key={item?._id}
                          value={item?.tallyCompanyName}
                        >
                          {item?.tallyCompanyName}
                        </Index.MenuItem>
                      ))}
                    </Index.Select>
                  </Index.Box>
                </Index.Box>
                {purchaseRecordList?.length ? (
                  <Index.Box className="common-button grey-button">
                    <Index.Button
                      variant="contained"
                      onClick={() => {
                        let curDate = Index.moment(new Date()).format(
                          "YYYY-MM-DD"
                        );
                        NEW_EXPORT_EXCEL(
                          "/admin/new-purchase-export",
                          `Purchase_Beta_List_${curDate}`,
                          {
                            // search: searchedPurchaseText,
                            to:
                              serachedDateRange?.length > 0
                                ? dayjs(serachedDateRange[0]).format(
                                    "YYYY-MM-DD"
                                  )
                                : "",
                            from:
                              serachedDateRange?.length > 0
                                ? dayjs(serachedDateRange[1]).format(
                                    "YYYY-MM-DD"
                                  )
                                : "",
                            companyName: selectedCompany,
                          }
                        );
                      }}
                    >
                      Export
                    </Index.Button>
                  </Index.Box>
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.Box className="barge-common-box box-margin">
              {loading ? (
                <>
                  <Index.Box className="bank-dash-loader">
                    <Index.CircularProgress color="secondary" size={20} />
                  </Index.Box>
                </>
              ) : (
                <Index.Box className="mini-card-main mini-card-main-bank-details card-wrapper">
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total IGST
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {purchaseRecordList?.length
                          ? purchaseRecordList?.[0]?.hasOwnProperty("totalIgst")
                            ? parseFloat(
                                purchaseRecordList?.[0]?.totalIgst < 0
                                  ? purchaseRecordList?.[0]?.totalIgst * -1
                                  : purchaseRecordList?.[0]?.totalIgst
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : Number(0).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total CGST
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {purchaseRecordList?.length
                          ? purchaseRecordList?.[0]?.hasOwnProperty("totalCgst")
                            ? parseFloat(
                                purchaseRecordList?.[0]?.totalCgst < 0
                                  ? purchaseRecordList?.[0]?.totalCgst * -1
                                  : purchaseRecordList?.[0]?.totalCgst
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : Number(0).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total SGST
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {purchaseRecordList?.length
                          ? purchaseRecordList?.[0]?.hasOwnProperty("totalSgst")
                            ? parseFloat(
                                purchaseRecordList?.[0]?.totalSgst < 0
                                  ? purchaseRecordList?.[0]?.totalSgst * -1
                                  : purchaseRecordList?.[0]?.totalSgst
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : Number(0).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total TDS
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {purchaseRecordList?.length
                          ? purchaseRecordList?.[0]?.hasOwnProperty("totalTds")
                            ? parseFloat(
                                purchaseRecordList?.[0]?.totalTds < 0
                                  ? purchaseRecordList?.[0]?.totalTds * -1
                                  : purchaseRecordList?.[0]?.totalTds
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : Number(0).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total Invoice Value
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {purchaseRecordList?.length
                          ? purchaseRecordList?.[0]?.hasOwnProperty(
                              "totalInvoiceAmount"
                            )
                            ? parseFloat(
                                purchaseRecordList?.[0]?.totalInvoiceAmount < 0
                                  ? purchaseRecordList?.[0]
                                      ?.totalInvoiceAmount * -1
                                  : purchaseRecordList?.[0]?.totalInvoiceAmount
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : Number(0).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total Taxable Value
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {purchaseRecordList?.length
                          ? purchaseRecordList?.[0]?.hasOwnProperty(
                              "totalTaxableValue"
                            )
                            ? parseFloat(
                                purchaseRecordList?.[0]?.totalTaxableValue < 0
                                  ? purchaseRecordList?.[0]?.totalTaxableValue *
                                      -1
                                  : purchaseRecordList?.[0]?.totalTaxableValue
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : Number(0).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="mini-card-bank-details">
                    <Index.Box className="card-right-content">
                      <Index.Typography className="card-total" component="p">
                        Total Other
                      </Index.Typography>
                      <Index.Typography className="card-price">
                        ₹
                        {purchaseRecordList?.length
                          ? purchaseRecordList?.[0]?.hasOwnProperty(
                              "totalOther"
                            )
                            ? parseFloat(
                                purchaseRecordList?.[0]?.totalOther < 0
                                  ? purchaseRecordList?.[0]?.totalOther * -1
                                  : purchaseRecordList?.[0]?.totalOther
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : Number(0).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : Number(0).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              )}
            </Index.Box>
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Party Name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Expense Head
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Barges Or Tugs
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      IGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      CGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      SGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      TDS
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Voucher Number
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice Value
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Taxable Value
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Other
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Expense Type
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={15}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {purchaseRecordList?.length ? (
                      purchaseRecordList?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell>
                            {row?.hasOwnProperty("DATE")
                              ? Index.moment(row?.DATE).format("DD/MM/YYYY")
                              : row?.hasOwnProperty("date")
                              ? Index.moment(row?.date).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.party_name ? row?.party_name : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.expense_head ? row?.expense_head : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.cost_center ? row?.cost_center : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.igst
                              ? row?.igst >= 0
                                ? row?.igst
                                : (row?.igst * -1)?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.cgst
                              ? row?.cgst >= 0
                                ? row?.cgst
                                : (row?.cgst * -1)?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.sgst
                              ? row?.sgst >= 0
                                ? row?.sgst
                                : (row?.sgst * -1)?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.tds
                              ? row?.tds >= 0
                                ? row?.tds
                                : (row?.tds * -1)?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>

                          <Index.TableCell>
                            {row?.voucher_number ? row?.voucher_number : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.invoice_amount
                              ? row?.invoice_amount >= 0
                                ? row?.invoice_amount
                                : (row?.invoice_amount * -1)?.toLocaleString(
                                    "en-IN",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.taxable_value
                              ? row?.taxable_value >= 0
                                ? row?.taxable_value
                                : (row?.taxable_value * -1)?.toLocaleString(
                                    "en-IN",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.other
                              ? row?.other >= 0
                                ? row?.other
                                : (row?.other * -1)?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {/* <Index.Box className="input-design-div with-border">
                              <Index.Select
                                fullWidth
                                value={
                                  purchaseExpenseType?.length > 0 &&
                                  purchaseExpenseType.find(
                                    (res) => res?.guid === row?.guid
                                  ) !== undefined
                                    ? purchaseExpenseType.find(
                                        (res) => res?.guid === row?.guid
                                      ).expenseType
                                    : row?.expense_type === "Purchase Accounts"
                                    ? "Purchase Accounts"
                                    : row?.expense_type === "Direct Expenses"
                                    ? "Purchase Accounts"
                                    : row?.expense_type === "Indirect Expenses"
                                    ? "Indirect Expenses"
                                    : row?.expense_type === "Admin Cost"
                                    ? "Admin Cost"
                                    : ""
                                }
                                onChange={(e) =>
                                  handleChangeExpenseType(e, row)
                                }
                              >
                                <Index.MenuItem value="Purchase Accounts">
                                  Direct
                                </Index.MenuItem>
                                <Index.MenuItem value="Indirect Expenses">
                                  Indirect
                                </Index.MenuItem>
                                <Index.MenuItem value="Admin Cost">
                                  Admin Cost
                                </Index.MenuItem>
                              </Index.Select>
                            </Index.Box> */}
                            {row?.barge_expense_type
                              ? row?.barge_expense_type
                              : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={14}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count pagination-loader">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalPurchaseRecord / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default BetaPurchase;
