import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { FieldArray, Formik } from "formik";
import {
  addTraderEnquiry,
  getNameOfVesselForTrader,
  getTraderPortList,
} from "../../../../redux/admin/action";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { terminalData } from "../../../../config/CommonTerminalItems";
import { productData } from "../../../../config/CommonProductsItem";
import { commonCFData } from "../../../../config/CommonCF";

// vessle auto suggest
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

function TraderEnquiryAdd() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { id } = Index.useParams();
  let { state } = Index.useLocation();
  let editData = state?.row;
  if (editData) {
    editData.id = editData?._id;
    let portId = editData?.port?._id;
    if (portId) {
      editData.port = portId;
    }
  }

  const [loading, setLoading] = useState(false);
  const [vesselNameList, setVesselNameList] = useState([]);
  const [traderVessselNameList, setTraderVessselNameList] = useState([]);
  // const portList = Index.useSelector((state) => state.AdminReducer.portList);
  const { portListTrader } = Index.useSelector((state) => state.AdminReducer);
  const login = Index.useSelector((state) => state.AdminReducer.adminDetails);

  // nameOfVessele filter
  const filter = createFilterOptions();
  //getAll port
  // useEffect(() => {
  //   dispatch(getPortList());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getTraderPortList());
  }, [dispatch]);

  useEffect(() => {
    const vesselData =
      traderVessselNameList &&
      traderVessselNameList?.filter((d) => d?.vesselName);
    const uniqueArray = vesselData?.filter(
      (v, i, a) => a?.findIndex((t) => t?.vesselName === v?.vesselName) === i
    );
    if (login?.role === "Operation") {
      // Role Operation then vesselName show in uppercase
      const newData = uniqueArray?.sort((a, b) =>
        a?.vesselName?.localeCompare(b?.vesselName)
      );
      setVesselNameList(
        newData?.map((e) => ({
          ...e,
          vesselName: e?.vesselName?.toUpperCase(),
        }))
      );
    } else {
      setVesselNameList(
        uniqueArray?.sort((a, b) => a?.vesselName?.localeCompare(b?.vesselName))
      );
    }
  }, [traderVessselNameList]);

  let initialValues = {
    vesselName: null,
    port: "",
    terminal: "",
    CF: "",
    eta: "",
    laycam: [dayjs(""), dayjs("")],
    etb: "",
    etd: "",
    stayHours: "",
    // companyName: "",
    remark: "",
    orderDetails: [{ product: "", quantity: "" }],
  };
  // condition for manage Add/Edit together
  if (editData) {
    initialValues = editData;
    initialValues.laycam = [
      dayjs(editData?.laycanStartDate),
      dayjs(editData?.laycanEndDate),
    ];
    const orderDetailsModified = editData?.orderDetails?.map((item) => {
      console.log(item, 99);
      return { ...item, quantity: item.rangeQty };
    });
    initialValues.orderDetails = orderDetailsModified;
  }

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const data = new URLSearchParams();
    if (values?.vesselName) {
      data.append("vesselName", values?.vesselName);
    }
    if (values?.eta) {
      data.append("eta", values?.eta);
    }
    if (values?.etb) {
      data.append("etb", values?.etb);
    }
    if (values?.etd) {
      data.append("etd", values?.etd);
    }
    if (values?.stayHours) {
      data.append("stayHours", values?.stayHours);
    }
    // if (values?.companyName) {
    //   data.append("companyName", values?.companyName);
    // }
    if (values?.port) {
      data.append("port", values?.port);
    }
    if (values?.terminal) {
      data.append("terminal", values?.terminal);
    }
    //if (values?.CF) {
    data.append("CF", values?.CF);
    //}
    //if (values?.remark) {
    data.append("remark", values?.remark);
    //}

    if (values?.laycam) {
      const laycamStartDate = values.laycam[0]["$D"];
      const laycamEndDate = values.laycam[1]["$D"];
      if (!isNaN(laycamStartDate) && !isNaN(laycamEndDate)) {
        data.append(
          "laycam",
          values?.laycam?.map((date) => {
            return Index.moment(date.$d).format("YYYY-MM-DD");
          })
        );
      }
    }
    data.append("type", "Trader");
    data.append("traderId", id);
    if (editData) {
      data.append("id", editData?._id);
    }
    // console.log(orderDetails,155);

    values?.orderDetails.forEach((element, i) => {
      data.append(`orderDetails[${i}][product]`, element.product);
      data.append(`orderDetails[${i}][quantity]`, element.quantity);
    });
    dispatch(addTraderEnquiry(data, navigate)).then((res) => {
      setLoading(false);
      navigate(`/trader/enq/list/${id}`);
    });
  };

  const backBtn = () => {
    navigate(`/trader/enq/list/${id}`);
  };

  useEffect(() => {
    dispatch(getNameOfVesselForTrader()).then((res) => {
      if (res) {
        setTraderVessselNameList(res?.data);
      }
    });
  }, []);
  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box barge-common-box-set">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex-set">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit" : "Add"} Enquiry
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn save-btn-set ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              getFieldProps,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Vessel Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Autocomplete
                          className="invoice-select-drop enquiry-vessel-autocomplete"
                          fullWidth
                          value={values?.vesselName}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setFieldValue("vesselName", newValue);
                            } else if (newValue && newValue?.inputValue) {
                              setFieldValue("vesselName", newValue?.inputValue);
                            } else {
                              setFieldValue("vesselName", newValue?.vesselName);
                            }
                          }}
                          onBlur={(event, newValue) => {
                            setFieldValue("vesselName", event.target.value);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options?.some(
                              (option) => inputValue === option?.vesselName
                            );

                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                vesselName: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={vesselNameList}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xyz" option created dynamically
                            if (option?.inputValue) {
                              return option?.inputValue;
                            }
                            // Regular option
                            return option?.vesselName;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option?.vesselName}</li>
                          )}
                          freeSolo
                          renderInput={(params) => (
                            <Index.TextField
                              className="admin-input-design input-placeholder"
                              {...params}
                              fullWidth
                              placeholder="Vessel Name"
                              // label="Free solo with text demo"
                            />
                          )}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Port
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.port ? (
                          portListTrader?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={portListTrader}
                              getOptionLabel={(option) => option?.portName}
                              value={
                                portListTrader[
                                  portListTrader.findIndex(
                                    (e) => e._id === values?.port
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.portName}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("port", newValue?._id);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Port name"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={portListTrader}
                            getOptionLabel={(option) => option?.portName}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.portName}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("port", newValue?._id);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Port name"
                                variant="outlined"
                              />
                            )}
                          />
                        )}

                        {touched.port && errors.port && (
                          <Index.FormHelperText error>
                            {errors.port}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Terminal
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.terminal ? (
                          terminalData?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={terminalData}
                              getOptionLabel={(option) => option?.item}
                              value={
                                terminalData[
                                  terminalData.findIndex(
                                    (e) => e.item === values?.terminal
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.item}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("terminal", newValue?.item);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Terminal name"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={terminalData}
                            getOptionLabel={(option) => option?.item}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.item}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("terminal", newValue?.item);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Terminal name"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        ETA
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  format="DD/MM/YYYY"
                                  defaultValue={
                                    editData
                                      ? Index.dayjs(
                                          editData?.eta ? editData?.eta : "-"
                                        )
                                      : ""
                                  }
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DD");
                                    setFieldValue("eta", formatedDate);
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        ETA - Mobile
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                          <Index.DemoContainer components={["DatePicker"]}>
                            <MobileDatePicker
                              disableFuture={true}
                              minDate={dayjs().startOf("year")}
                              format="DD/MM/YYYY"
                              //value={value}
                              // onChange={(newValue) => {
                              //   setValue("packingDate", newValue);
                              // }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  //error: Boolean(errors.packingDate),
                                  // error : true
                                },
                              }}
                              //error={Boolean(errors.packingDate)}
                            />
                          </Index.DemoContainer>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Grid> */}

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        ETB
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  format="DD/MM/YYYY"
                                  defaultValue={
                                    editData
                                      ? Index.dayjs(
                                          editData?.etb ? editData?.etb : "-"
                                        )
                                      : ""
                                  }
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DD");
                                    setFieldValue("etb", formatedDate);
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                          {touched?.etb && errors?.etb && (
                            <Index.FormHelperText error>
                              {errors?.etb}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        ETD
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  format="DD/MM/YYYY"
                                  defaultValue={
                                    editData
                                      ? Index.dayjs(
                                          editData?.etd ? editData?.etd : "-"
                                        )
                                      : ""
                                  }
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DD");
                                    setFieldValue("etd", formatedDate);
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                          {touched?.etd && errors?.etd && (
                            <Index.FormHelperText error>
                              {errors?.etd}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        C/F
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          fullWidth
                          name="CF"
                          value={values?.CF}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.CF : ""}
                          onChange={(e) => {
                            setFieldValue("CF", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select CF
                            </Index.MenuItem>
                          </Index.MenuItem>
                          <Index.MenuItem value="Coastal">
                            Coastal
                          </Index.MenuItem>
                          <Index.MenuItem value="Foreign">
                            Foreign
                          </Index.MenuItem>
                        </Index.Select>
                      </Index.Box>
                    </Index.Grid> */}

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        C/F
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.CF ? (
                          commonCFData?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={commonCFData}
                              getOptionLabel={(option) => option?.name}
                              value={
                                commonCFData[
                                  commonCFData.findIndex(
                                    (e) => e?.name === values?.CF
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.name}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("CF", newValue?.name);
                                handleChange(event);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="C/F name"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={commonCFData}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.name}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("CF", newValue?.name);
                              handleChange(event);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="C/F name"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Stay Hours
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Stay Hours"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="stayHours"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.stayHours}
                          error={Boolean(
                            errors?.stayHours ? touched?.stayHours : undefined
                          )}
                          helperText={
                            touched?.stayHours ? errors?.stayHours : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Laycan From to To
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker remove-name">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "DateRangePicker",
                                "DateRangePicker",
                              ]}
                            >
                              <DateRangePicker
                                format="DD/MM/YYYY"
                                value={values?.laycam}
                                onChange={(newValue) => {
                                  setFieldValue("laycam", newValue);
                                }}
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Company Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          fullWidth
                          name="companyName"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.companyName : ""}
                          onChange={(e) => {
                            setFieldValue("companyName", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={values?.companyName}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select company
                            </Index.MenuItem>
                          </Index.MenuItem>
                          <Index.MenuItem value="BSJPL">BSJPL</Index.MenuItem>
                          <Index.MenuItem value="SKQPL">SKQPL</Index.MenuItem>
                        </Index.Select>
                        {touched?.companyName && errors?.companyName && (
                          <Index.FormHelperText error>
                            {errors?.companyName}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid> */}

                    <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Remark
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextareaAutosize
                          hiddenLabel
                          minRows={3}
                          id="filled-hidden-label-normal"
                          placeholder=" Remark"
                          variant="filled"
                          className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set"
                          name="remark"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // onFocus={handlefocus}
                          value={values?.remark}
                          error={Boolean(
                            errors?.remark ? touched?.remark : undefined
                          )}
                          helperText={
                            touched?.remark ? errors?.remark : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={12} md={12}>
                      {values.orderDetails?.length > 0 && (
                        <FieldArray name="orderDetails">
                          {({ push, remove }) => (
                            <>
                              {values.orderDetails.map((row, index) => (
                                <Index.Grid
                                  container
                                  spacing={2}
                                  mt={2}
                                  key={index}
                                >
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label"
                                    >
                                      Product Name
                                    </Index.Typography>
                                    <Index.Box className="input-design-div3 with-border">
                                      <Index.Select
                                        fullWidth
                                        name={`orderDetails.${index}.product`}
                                        value={
                                          values.orderDetails[index].product
                                        }
                                        displayEmpty
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                        onChange={handleChange}
                                      >
                                        <Index.MenuItem value={""}>
                                          <Index.MenuItem
                                            disabled
                                            className="all-select-label"
                                          >
                                            Select product
                                          </Index.MenuItem>
                                        </Index.MenuItem>
                                        {productData?.map((items, index) => (
                                          <Index.MenuItem
                                            value={items?.item}
                                            key={items?._id}
                                          >
                                            {items?.item}
                                          </Index.MenuItem>
                                        ))}
                                      </Index.Select>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label"
                                    >
                                      Quantity
                                    </Index.Typography>
                                    <Index.Box className="input-design-div with-border">
                                      <Index.TextField
                                        hiddenLabel
                                        id="filled-hidden-label-normal"
                                        placeholder="Enter quantity"
                                        variant="filled"
                                        InputProps={{
                                          inputProps: { min: 0, max: 10 },
                                        }}
                                        className="admin-input-design input-placeholder"
                                        name={`orderDetails.${index}.quantity`}
                                        defaultValue={
                                          values.orderDetails[index].rangeQty
                                        }
                                        autoComplete="off"
                                        onBlur={handleBlur}
                                        // defaultValue=""
                                        onChange={handleChange}
                                        helperText={
                                          touched &&
                                          touched.orderDetails &&
                                          touched.orderDetails[index] &&
                                          touched.orderDetails[index]
                                            .quantity &&
                                          errors &&
                                          errors.orderDetails &&
                                          errors.orderDetails[index] &&
                                          errors.orderDetails[index].quantity
                                        }
                                        error={Boolean(
                                          errors &&
                                            errors.orderDetails &&
                                            errors.orderDetails[index] &&
                                            errors.orderDetails[index]
                                              .quantity &&
                                            touched &&
                                            touched.orderDetails &&
                                            touched.orderDetails[index] &&
                                            touched.orderDetails[index].quantity
                                        )}
                                      />
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label"
                                    ></Index.Typography>

                                    <Index.Box className="incre-btn-main">
                                      {index ===
                                      values.orderDetails?.length - 1 ? (
                                        <>
                                          <Index.Button
                                            sx={{ fontSize: "20px" }}
                                            className="button-add inc-dic-btn"
                                            type="button"
                                            onClick={() =>
                                              push({
                                                product: "",
                                                quantity: "",
                                              })
                                            }
                                          >
                                            +
                                          </Index.Button>
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      {values.orderDetails?.length > 1 && (
                                        <Index.Button
                                          sx={{ fontSize: "20px" }}
                                          type="button"
                                          className="button-remove inc-dic-btn"
                                          disabled={
                                            values.orderDetails?.length > 1
                                              ? false
                                              : true
                                          }
                                          onClick={() => remove(index)}
                                        >
                                          -
                                        </Index.Button>
                                      )}
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      )}
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn inquiry-submit-btn">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Index.CircularProgress color="secondary" size={20} />
                    ) : editData ? (
                      "Update"
                    ) : (
                      "Submit"
                    )}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default TraderEnquiryAdd;
