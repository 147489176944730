import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getTankerDashboard,
  getFreeBreakdownTankers,
  freeDashboardTanker,
} from "../../../../redux/admin/action";

Index.moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few sec",
    ss: "%d sec",
    m: "a min",
    mm: "%d min",
    h: "an hrs",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const TankerDashboard = () => {
  const dispatch = Index.useDispatch();
  const { tankerDashboardList, freeBreakdownTankers } = Index.useSelector(
    (state) => state.AdminReducer
  );

  const [inTerminal, setInTerminal] = useState([]);
  const [inTransit, setInTransit] = useState([]);
  const [inKandla, setInKandla] = useState([]);
  const [inAssigned, setInAssigned] = useState([]);
  const navigate = Index.useNavigate();
  const [freeTankerId, setFreeTankerId] = useState("");
  const [openModal, setOpenModal] = useState(false);

  // free tanker popup
  const handleOpen = (id) => {
    setOpenModal(true);
    setFreeTankerId(id);
  };

  const handleClose = () => {
    setOpenModal(false);
    setFreeTankerId("");
  };

  const handleFreeTanker = () => {
    try {
      dispatch(freeDashboardTanker({ id: freeTankerId })).then((res) => {
        if (res?.status === 200) {
          dispatch(getTankerDashboard());
        }
      });
    } catch (error) {}
    handleClose();
  };

  useEffect(() => {
    dispatch(getTankerDashboard());
    dispatch(getFreeBreakdownTankers());
  }, []);

  useEffect(() => {
    if (tankerDashboardList) {
      // In Terminal Filter
      let terminal = tankerDashboardList.filter((tanker) => {
        return (
          tanker.loadedTTIn !== undefined &&
          tanker.loadedTTIn !== null &&
          tanker.loadedTTIn !== "" &&
          (tanker.loadingStart !== undefined || tanker.loadingStart !== null) &&
          (tanker.loadingCompleted !== undefined ||
            tanker.loadingCompleted !== null) &&
          (tanker.loadedTTOut === undefined || tanker.loadedTTOut === null)
        );
      });
      setInTerminal(terminal);

      // In Transit Filter
      let transit = tankerDashboardList.filter((tanker) => {
        return (
          tanker.loadedTTOut !== undefined &&
          tanker.loadedTTOut !== null &&
          (tanker.kandalaIn === undefined || tanker.kandalaIn === null)
        );
      });
      setInTransit(transit);

      // In Kandla Filter
      let kandla = tankerDashboardList.filter((tanker) => {
        return (
          tanker.kandalaIn !== undefined &&
          tanker.kandalaIn !== null &&
          tanker.kandalaIn !== ""
        );
      });
      setInKandla(kandla);

      // In Assigned Filter
      let assigned = tankerDashboardList.filter((tanker) => {
        return tanker.loadedTTIn === undefined || tanker.loadedTTIn === null;
      });
      setInAssigned(assigned);
    }
  }, [tankerDashboardList]);

  const FirstCapitalLetter = (string) => {
    // capitalize the first letter
    let result = string?.charAt(0).toUpperCase() + string?.slice(1);
    return result;
  };

  const handleTankerDashboardView = (id) => {
    navigate(`/admin/tankers/dashboard/view/${id}`);
  };

  return (
    <>
      <Index.DynamicTitle title="Dashboard" />
      <div className="main-dashboard">
        <Index.Box className="">
          <Index.Typography className="head-title" component="h1">
            Tankers Dashboard
          </Index.Typography>

          <Index.Box className="chart-main-wrapp category-sec-main chart-main-wrapp">
            {/* In Terminal */}
            <Index.Box className="chart-box tanker-chart-box">
              <Index.Box className="chart-header">
                <Index.Typography
                  className="chart-title tanker-dashboard-title"
                  component="p"
                >
                  In Terminal ({inTerminal?.length})
                </Index.Typography>
              </Index.Box>
              <Index.Box className="chart-graph-devider tanker-dash-table">
                <Index.Box className="cate-table-main cate-table-main-box">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="cate-table-container"
                  >
                    <Index.Table aria-label="simple table">
                      <Index.TableHead>
                        <Index.TableRow className="tanker-dashboard-div">
                          <Index.TableCell width="15%">No.</Index.TableCell>
                          <Index.TableCell align="left" width="30%">
                            Tanker/Vessel
                          </Index.TableCell>
                          <Index.TableCell align="left" width="30%">
                            Product
                          </Index.TableCell>
                          <Index.TableCell align="left" width="25%">
                            Time
                          </Index.TableCell>
                          <Index.TableCell align="left" width="25%">
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {inTerminal?.map((items, index) => {
                          return (
                            <Index.TableRow key={index}>
                              <Index.TableCell>{index + 1}</Index.TableCell>
                              <Index.TableCell>
                                {`${FirstCapitalLetter(
                                  items?.tanker?.tankerName
                                )} ${
                                  items?.hasOwnProperty("tankerTTSrNo") &&
                                  `( ${items?.tankerTTSrNo} )`
                                }`}{" "}
                                <br />
                                <Index.Box className="tanker-child-vessel">
                                  {FirstCapitalLetter(
                                    items?.orderId?.vesselName
                                  )}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell>
                                {items?.orderId?.product
                                  ? items?.orderId?.product
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell>
                                {items?.loadedTTIn
                                  ? Index.moment(items?.loadedTTIn).format(
                                      "hh:mm A"
                                    )
                                  : "-"}
                                <br />
                                <Index.Box className="tanker-child-hours">
                                  {items?.loadedTTIn
                                    ? Index.moment(items?.loadedTTIn).fromNow()
                                    : "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell>
                                {
                                  // <Index.Box className="common-button blue-button res-blue-button">
                                  <>
                                    <Index.Box className="free-tanker-flex-box">
                                      <Index.IconButton className="free-tanker-icon-btn">
                                        <Index.VisibilitySharpIcon
                                          className="free-tanker-view-icon"
                                          variant="contained"
                                          disableRipple
                                          onClick={() => {
                                            handleTankerDashboardView(
                                              items?._id
                                            );
                                          }}
                                        />
                                      </Index.IconButton>
                                      <Index.IconButton
                                        onClick={() => {
                                          handleOpen(items?._id);
                                        }}
                                        className="free-tanker-icon-btn"
                                      >
                                        <img
                                          src={Index.Png.freeTanker}
                                          className="free-tanker-free-icon"
                                          alt="free-tanker-icon"
                                        />
                                      </Index.IconButton>
                                    </Index.Box>
                                  </>
                                  // </Index.Box>
                                }
                              </Index.TableCell>
                            </Index.TableRow>
                          );
                        })}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* In Transit */}
            <Index.Box className="chart-box tanker-chart-box">
              <Index.Box className="chart-header">
                <Index.Typography
                  className="chart-title tanker-dashboard-title"
                  component="p"
                >
                  In Transit ({inTransit?.length})
                </Index.Typography>
              </Index.Box>
              <Index.Box className="chart-graph-devider tanker-dash-table">
                <Index.Box className="cate-table-main cate-table-main-box">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="cate-table-container"
                  >
                    <Index.Table aria-label="simple table">
                      <Index.TableHead>
                        <Index.TableRow className="tanker-dashboard-div">
                          <Index.TableCell width="15%">No.</Index.TableCell>
                          <Index.TableCell align="left" width="30%">
                            Tanker/Vessel
                          </Index.TableCell>
                          <Index.TableCell align="left" width="30%">
                            Product
                          </Index.TableCell>
                          <Index.TableCell align="left" width="25%">
                            Time
                          </Index.TableCell>
                          <Index.TableCell align="left" width="25%">
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {inTransit?.map((items, index) => {
                          return (
                            <Index.TableRow key={index}>
                              <Index.TableCell>{index + 1}</Index.TableCell>
                              <Index.TableCell>
                                {FirstCapitalLetter(items?.tanker?.tankerName)}
                                {items?.hasOwnProperty("tankerTTSrNo") ? (
                                  <>{`( ${items?.tankerTTSrNo} )`}</>
                                ) : (
                                  <></>
                                )}
                                <br />
                                <Index.Box className="tanker-child-vessel">
                                  {FirstCapitalLetter(
                                    items?.orderId?.vesselName
                                  )}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell>
                                {items?.orderId?.product
                                  ? items?.orderId?.product
                                  : "-"}
                                <br />
                                {items?.loadedQty
                                  ? "(" + items?.loadedQty + ")"
                                  : " -"}
                              </Index.TableCell>
                              <Index.TableCell>
                                {items?.loadedTTOut
                                  ? Index.moment(items?.loadedTTOut).format(
                                      "hh:mm A"
                                    )
                                  : "-"}
                                <br />
                                <Index.Box className="tanker-child-hours">
                                  {items?.loadedTTOut
                                    ? Index.moment(items?.loadedTTOut).fromNow()
                                    : "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell>
                                {
                                  // <Index.Box className="common-button blue-button res-blue-button">
                                  //   <Index.Button
                                  //     variant="contained"
                                  //     disableRipple
                                  //     sx={{ marginRight: 1 }}
                                  //     onClick={() => {
                                  //       handleTankerDashboardView(items?._id);
                                  //     }}
                                  //   >
                                  //     CheckOut
                                  //   </Index.Button>
                                  // </Index.Box>
                                  <>
                                    <Index.Box className="free-tanker-flex-box">
                                      <Index.IconButton className="free-tanker-icon-btn">
                                        <Index.VisibilitySharpIcon
                                          className="free-tanker-view-icon"
                                          variant="contained"
                                          disableRipple
                                          onClick={() => {
                                            handleTankerDashboardView(
                                              items?._id
                                            );
                                          }}
                                        />
                                      </Index.IconButton>
                                      <Index.IconButton
                                        onClick={() => {
                                          handleOpen(items?._id);
                                        }}
                                        className="free-tanker-icon-btn"
                                      >
                                        <img
                                          src={Index.Png.freeTanker}
                                          className="free-tanker-free-icon"
                                          alt="free-tanker-icon"
                                        />
                                      </Index.IconButton>
                                    </Index.Box>
                                  </>
                                }
                              </Index.TableCell>
                            </Index.TableRow>
                          );
                        })}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* In Kandla */}
            <Index.Box className="chart-box tanker-chart-box">
              <Index.Box className="chart-header">
                <Index.Typography
                  className="chart-title tanker-dashboard-title"
                  component="p"
                >
                  In Kandla Port ({inKandla?.length})
                </Index.Typography>
              </Index.Box>
              <Index.Box className="chart-graph-devider tanker-dash-table">
                <Index.Box className="cate-table-main cate-table-main-box">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="cate-table-container"
                  >
                    <Index.Table aria-label="simple table">
                      <Index.TableHead>
                        <Index.TableRow className="tanker-dashboard-div">
                          <Index.TableCell width="15%">No.</Index.TableCell>
                          <Index.TableCell align="left" width="30%">
                            Tanker/Vessel
                          </Index.TableCell>
                          <Index.TableCell align="left" width="30%">
                            Product
                          </Index.TableCell>
                          <Index.TableCell align="left" width="25%">
                            Time
                          </Index.TableCell>
                          <Index.TableCell align="left" width="25%">
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {inKandla?.map((items, index) => {
                          return (
                            <Index.TableRow key={index}>
                              <Index.TableCell>{index + 1}</Index.TableCell>
                              <Index.TableCell>
                                {FirstCapitalLetter(items?.tanker?.tankerName)}
                                {items?.hasOwnProperty("tankerTTSrNo") ? (
                                  <>{`( ${items?.tankerTTSrNo} )`}</>
                                ) : (
                                  <></>
                                )}
                                <br />
                                <Index.Box className="tanker-child-vessel">
                                  {FirstCapitalLetter(
                                    items?.orderId?.vesselName
                                  )}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell>
                                {items?.orderId?.product
                                  ? items?.orderId?.product
                                  : "-"}
                                <br />
                                {items?.loadedQty
                                  ? "(" + items?.loadedQty + ")"
                                  : " -"}
                              </Index.TableCell>
                              <Index.TableCell>
                                {items?.kandalaIn
                                  ? Index.moment(items?.kandalaIn).format(
                                      "hh:mm A"
                                    )
                                  : "-"}
                                <br />
                                <Index.Box className="tanker-child-hours">
                                  {items?.kandalaIn
                                    ? Index.moment(items?.kandalaIn).fromNow()
                                    : "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell>
                                {
                                  // <Index.Box className="common-button blue-button res-blue-button">
                                  //   <Index.Button
                                  //     variant="contained"
                                  //     disableRipple
                                  //     onClick={() => {
                                  //       handleTankerDashboardView(items?._id);
                                  //     }}
                                  //   >
                                  //     CheckOut
                                  //   </Index.Button>
                                  // </Index.Box>
                                  <>
                                    <Index.Box className="free-tanker-flex-box">
                                      <Index.IconButton className="free-tanker-icon-btn">
                                        <Index.VisibilitySharpIcon
                                          className="free-tanker-view-icon"
                                          variant="contained"
                                          disableRipple
                                          onClick={() => {
                                            handleTankerDashboardView(
                                              items?._id
                                            );
                                          }}
                                        />
                                      </Index.IconButton>
                                      <Index.IconButton
                                        onClick={() => {
                                          handleOpen(items?._id);
                                        }}
                                        className="free-tanker-icon-btn"
                                      >
                                        <img
                                          src={Index.Png.freeTanker}
                                          className="free-tanker-free-icon"
                                          alt="free-tanker-icon"
                                        />
                                      </Index.IconButton>
                                    </Index.Box>
                                  </>
                                }
                              </Index.TableCell>
                            </Index.TableRow>
                          );
                        })}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* Assigned */}
            <Index.Box className="chart-box tanker-chart-box">
              <Index.Box className="chart-header">
                <Index.Typography
                  className="chart-title tanker-dashboard-title"
                  component="p"
                >
                  Assigned ({inAssigned?.length})
                </Index.Typography>
              </Index.Box>
              <Index.Box className="chart-graph-devider tanker-dash-table">
                <Index.Box className="cate-table-main cate-table-main-box">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="cate-table-container"
                  >
                    <Index.Table aria-label="simple table">
                      <Index.TableHead>
                        <Index.TableRow className="tanker-dashboard-div">
                          <Index.TableCell width="15%">No.</Index.TableCell>
                          <Index.TableCell align="left" width="30%">
                            Tanker/Vessel
                          </Index.TableCell>
                          <Index.TableCell align="left" width="30%">
                            Product
                          </Index.TableCell>
                          <Index.TableCell align="left" width="25%">
                            Time
                          </Index.TableCell>
                          <Index.TableCell align="left" width="25%">
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {inAssigned?.map((items, index) => {
                          return (
                            <Index.TableRow key={index}>
                              <Index.TableCell>{index + 1}</Index.TableCell>
                              <Index.TableCell>
                                {FirstCapitalLetter(items?.tanker?.tankerName)}
                                {items?.hasOwnProperty("tankerTTSrNo") ? (
                                  <>{`( ${items?.tankerTTSrNo} )`}</>
                                ) : (
                                  <></>
                                )}
                                <br />
                                <Index.Box className="tanker-child-vessel">
                                  {FirstCapitalLetter(
                                    items?.orderId?.vesselName
                                  )}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell>
                                {items?.orderId?.product
                                  ? items?.orderId?.product
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell>
                                {items?.createdAt
                                  ? Index.moment(items?.createdAt).format(
                                      "hh:mm A"
                                    )
                                  : "-"}
                                <br />
                                <Index.Box className="tanker-child-hours">
                                  {items?.createdAt
                                    ? Index.moment(items?.createdAt).fromNow()
                                    : "-"}
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell>
                                {
                                  // <Index.Box className="common-button blue-button res-blue-button">
                                  //   <Index.Button
                                  //     variant="contained"
                                  //     disableRipple
                                  //     onClick={() => {
                                  //       handleTankerDashboardView(items?._id);
                                  //     }}
                                  //   >
                                  //     CheckOut
                                  //   </Index.Button>
                                  // </Index.Box>
                                  <>
                                    <Index.Box className="free-tanker-flex-box">
                                      <Index.IconButton className="free-tanker-icon-btn">
                                        <Index.VisibilitySharpIcon
                                          className="free-tanker-view-icon"
                                          variant="contained"
                                          disableRipple
                                          onClick={() => {
                                            handleTankerDashboardView(
                                              items?._id
                                            );
                                          }}
                                        />
                                      </Index.IconButton>
                                      <Index.IconButton
                                        onClick={() => {
                                          handleOpen(items?._id);
                                        }}
                                        className="free-tanker-icon-btn"
                                      >
                                        <img
                                          src={Index.Png.freeTanker}
                                          className="free-tanker-free-icon"
                                          alt="free-tanker-icon"
                                        />
                                      </Index.IconButton>
                                    </Index.Box>
                                  </>
                                }
                              </Index.TableCell>
                            </Index.TableRow>
                          );
                        })}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* Free */}
            <Index.Box className="chart-box tanker-chart-box">
              <Index.Box className="chart-header">
                <Index.Typography
                  className="chart-title tanker-dashboard-title"
                  component="p"
                >
                  Free ({freeBreakdownTankers?.freeTanker?.length})
                </Index.Typography>
              </Index.Box>
              <Index.Box className="chart-graph-devider tanker-dash-table">
                <Index.Box className="cate-table-main cate-table-main-box">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="cate-table-container"
                  >
                    <Index.Table aria-label="simple table">
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell width="15%">No.</Index.TableCell>
                          <Index.TableCell align="left" width="30%">
                            Tanker
                          </Index.TableCell>
                          <Index.TableCell align="left" width="25%">
                            Number
                          </Index.TableCell>
                          <Index.TableCell align="left" width="25%">
                            Time
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {freeBreakdownTankers?.freeTanker?.map(
                          (items, index) => {
                            return (
                              <Index.TableRow key={index}>
                                <Index.TableCell>{index + 1}</Index.TableCell>
                                <Index.TableCell>
                                  {FirstCapitalLetter(items?.tankerName)}
                                </Index.TableCell>
                                <Index.TableCell>
                                  {items?.tankerNumber}
                                </Index.TableCell>
                                <Index.TableCell>
                                  {items?.updatedAt
                                    ? Index.moment(items?.updatedAt).format(
                                        "hh:mm A"
                                      )
                                    : "-"}
                                  <br />
                                  <Index.Box className="tanker-child-hours">
                                    {items?.updatedAt
                                      ? Index.moment(items?.updatedAt).fromNow()
                                      : "-"}
                                  </Index.Box>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          }
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* Breakdown */}
            <Index.Box className="chart-box tanker-chart-box">
              <Index.Box className="chart-header">
                <Index.Typography
                  className="chart-title tanker-dashboard-title"
                  component="p"
                >
                  Breakdown ({freeBreakdownTankers?.breakdown?.length})
                </Index.Typography>
              </Index.Box>
              <Index.Box className="chart-graph-devider tanker-dash-table">
                <Index.Box className="cate-table-main cate-table-main-box">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="cate-table-container"
                  >
                    <Index.Table aria-label="simple table">
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell width="15%">No.</Index.TableCell>
                          <Index.TableCell align="left" width="30%">
                            Tanker
                          </Index.TableCell>
                          <Index.TableCell align="left" width="25%">
                            Breakdown Date
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {freeBreakdownTankers?.breakdown &&
                          freeBreakdownTankers?.breakdown?.map(
                            (items, index) => {
                              return (
                                <Index.TableRow key={index}>
                                  <Index.TableCell>{index + 1}</Index.TableCell>
                                  <Index.TableCell>
                                    {FirstCapitalLetter(items?.tankerName)}{" "}
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    {items?.breakDown?.[0]?.breakdownDate
                                      ? Index.moment(
                                          items?.breakDown?.[0]?.breakdownDate
                                        ).format("DD/MM/YYYY")
                                      : "-"}
                                  </Index.TableCell>
                                </Index.TableRow>
                              );
                            }
                          )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        {/* Free tanker model */}
        <Index.Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-delete modal"
        >
          <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
            <Index.ClearIcon
              className="model-close-icon"
              onClick={handleClose}
            />
            <Index.Box className="modal-circle-main">
              <img
                src={Index.Svg.close}
                className="user-circle-img"
                alt="close-icon"
              />
            </Index.Box>

            <Index.Typography
              className="delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="delete-modal-para common-para"
              component="p"
              variant="p"
            >
              Are you sure you want free this tanker ?
            </Index.Typography>

            <Index.Box className="delete-modal-btn-flex">
              <Index.Button
                className="modal-inquiry-success-btn modal-btn"
                onClick={handleFreeTanker}
              >
                {"Yes"}
              </Index.Button>
              <Index.Button
                className="modal-inquiry-error-btn modal-btn"
                onClick={() => {
                  handleClose();
                }}
              >
                {"No"}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
      </div>
    </>
  );
};

export default TankerDashboard;
