import { useEffect, useState } from "react";
import { getJobTypeWiseDashboardData } from "../../../../../redux/admin/action";
import Index from "../../../../Index";

function ElectricalDetails() {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [electricalBreakDownList, setElectricalBreakDownList] = useState([]);

  // when the user get the electrical breakdown's data then used....
  const handleGetElectricalBreakdownData = () => {
    setLoading(true);
    dispatch(getJobTypeWiseDashboardData("Electrical"))
      .then((res) => {
        console.log("res-Electrical", res);
        if (res.status === 200) {
          setElectricalBreakDownList(res.data);
          setLoading(false);
        } else {
          setElectricalBreakDownList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setElectricalBreakDownList([]);
        setLoading(false);
        console.error("Kotak bank data fetch time error", error);
      });
  };

  // when the page first time load then used...
  useEffect(() => {
    handleGetElectricalBreakdownData();
  }, []);
  return (
    <>
      <Index.Box className="cate-table-main cate-table-main-box">
        <Index.TableContainer component={Index.Paper}>
          <Index.Table sx={{ minWidth: 650 }} aria-label="cate-table-container">
            <Index.TableHead>
              <Index.TableRow className="tanker-dashboard-div">
                <Index.TableCell width="15%">No.</Index.TableCell>
                <Index.TableCell align="left" width="50%">
                  Craft Name | S. No
                </Index.TableCell>
                <Index.TableCell align="right" width="35%">
                  What Is Done
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody>
              {loading ? (
                <Index.TableRow>
                  <Index.TableCell
                    colSpan={12}
                    align="center"
                    className="loading-purchase"
                  >
                    <Index.CircularProgress color="secondary" size={20} />
                  </Index.TableCell>
                </Index.TableRow>
              ) : (
                <>
                  {electricalBreakDownList?.map((item, index) => {
                    return (
                      <Index.TableRow key={item?._id}>
                        <Index.TableCell>{index + 1}</Index.TableCell>
                        <Index.TableCell>
                          <Index.Box className="tanker-child-vessel">
                            {item?.barge?.bargeName
                              ? item?.barge?.bargeName
                              : "-"}
                          </Index.Box>
                          <Index.Box className="tanker-child-vessel">
                            {item?.maintenanceNumber}
                          </Index.Box>
                        </Index.TableCell>
                        <Index.TableCell>
                          {item?.whatIsDone ? item?.whatIsDone : "-"}
                        </Index.TableCell>
                      </Index.TableRow>
                    );
                  })}
                </>
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
    </>
  );
}
export default ElectricalDetails;
