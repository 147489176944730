import React, { useState } from "react";
import Index from "../../../Index";
import { webRoleMaster } from "../../../../config/CommonRoleMaster";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const subcompany = [
  { img: Index.Png.modalcom1, name: "BSJPL", selected: false },
  { img: Index.Png.modalcom2, name: "SKQPL", selected: false },
  { img: Index.Png.modalcom3, name: "ASK", selected: false },
];
export const SelectModal = (props) => {
  const { open, handleClose, companyName } = props;
  const [subCompany, setSubCompany] = useState(subcompany);
  const navigate = Index.useNavigate();
  const login = Index.useSelector((state) => state.AdminReducer.adminDetails);
  // const permissions = login?.permission?.webPermission;
  const roleName = login?.permission?.roleName;
  // const permissionsArray = Object.entries(login?.permission?.webPermission);
  const permission = login?.permission?.webPermission;
  const webrolemasterTags = webRoleMaster.map((item) => item.tag);
  const webrolemasterChild = webRoleMaster.filter((item) => item.isChild);
  console.log("webrolemasterTags", webrolemasterTags);
  console.log("permission", permission);
  const sortedPermission = {};
  webrolemasterTags.forEach((tag) => {
    if (permission[tag] !== undefined) {
      sortedPermission[tag] = permission[tag];
    }
  });

  webrolemasterChild.map((tag) => {
    // For child webRole master conditions
    tag.child.forEach((item) => {
      if (permission[item?.tag] !== undefined) {
        // console.log(sortedPermission[item?.tag], 'permission tag name', permission[item?.tag]);
        sortedPermission[item?.tag] = permission[item?.tag];
      }
    });
  });

  // console.log(webrolemasterChild, "webrolemasterChild");
  // console.log(sortedPermission, "sortedPermission");
  // console.log("webRoleMaster = ", webRoleMaster);
  // console.log("permissionsArray=", login?.permission?.webPermission);

  let firstMenu = null;
  for (const key in sortedPermission) {
    if (sortedPermission[key] === true) {
      firstMenu = key;
      break; // Stop the loop when the first true value is found
    }
  }
  // console.log(firstMenu, "firstMenu");
  return (
    <div>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box sx={style} className="select-modal-box">
          <Index.Box>
            <Index.Box className="select-modal-header">
              <Index.Box className="title-main ">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="select-modal-header-text"
                >
                  Company : {companyName}
                </Index.Typography>
              </Index.Box>
              <Index.Button
                disableRipple
                className="res-btn-close"
                onClick={handleClose}
              >
                <img src={Index.Svg.cancel} className="modal-close-img" alt="close-icon" />
              </Index.Button>
            </Index.Box>
            <Index.Box className="select-modal-img-box">
              <Index.Box className="select-img-box-main">
                <Index.Box className="modal-select-img-box">
                  {subCompany.map((data, index) => {
                    return (
                      <Index.Box
                        className={`select-img-main-modal ${
                          data.selected ? "active" : ""
                        }`}
                        onClick={() => {
                          const dataList = subCompany.map((data, index1) => {
                            if (index === index1) {
                              return { ...data, selected: true };
                            } else {
                              return { ...data, selected: false };
                            }
                          });
                          setSubCompany(dataList);
                          setTimeout(() => {
                            // navigate(`/admin/company-dashboard/${index}`);
                            if (data.name === "BSJPL") {
                              // navigate(`/admin/companyDashboard`);
                              if (
                                roleName === "Super Admin" ||
                                roleName === "Finance"
                              ) {
                                navigate(`/admin/companyDashboard`);
                              } else {
                                navigate(`/admin/${firstMenu}`);
                              }

                              // if (
                              //   login?.role == Index.Roles?.SuperAdmin ||
                              //   login?.role == Index.Roles?.Finance
                              // ) {
                              //   navigate(`/admin/company-dashboard`);
                              // } else if (
                              //   login?.role == Index.Roles?.Operation
                              // ) {
                              //   navigate(`/admin/enquiry`);
                              // } else {
                              //   navigate("/");
                              // }
                            }
                          }, 500);
                        }}
                      >
                        <Index.Box className="modal-compny-img">
                          <Index.Box className="modal-set-select-img ">
                            <img
                              src={data.img}
                              className="modal-compny-img-inner"
                              alt="icon"
                            />
                          </Index.Box>
                          <Index.Box className="select-text-set-modal">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="modal-select-compny-text"
                            >
                              {data.name}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="select-check-box modal-icon-btn">
                          {!data.selected ? (
                            <img
                              src={Index.Svg.righticon}
                              className="modal-close-icon-small modal-close-icon-small-grey"
                              alt="right-icon"
                            />
                          ) : (
                            <img
                              src={Index.Svg.righticonblue}
                              className="modal-close-icon-small modal-close-icon-small-blue"
                              alt="right-icon"
                            />
                          )}
                        </Index.Box>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </div>
  );
};
