import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import {
  getInvoiceActivityLog,
  updateMultipleWaterSupplyOrderStatus,
  getWaterSupplyList,
  handleGetUpdateSingleWaterSupplyOrderField,
} from "../../../../redux/admin/action";
import WaterSupplyUpdateVesselNameModel from "./updateModels/WaterSupplyUpdateVesselNameModel";
import SingleUpdateDateTimePopUp from "../../../../component/common/SingleUpdateDateTimePopUp";
import SingleUpdateTextFieldPopUp from "../../../../component/common/SingleUpdateTextFieldPopUp";
import WaterSupplyUpdateCFModel from "./updateModels/WaterSupplyUpdateCFModel";
import WaterSupplyUpdatePortModel from "./updateModels/WaterSupplyUpdatePortModel";
import WaterSupplyUpdateQuantityModel from "./updateModels/WaterSupplyUpdateQuantityModel";
import WaterSupplyUpdateWaterTypeModel from "./updateModels/WaterSupplyUpdateWaterTypeModel";
import WaterSupplyUpdateWaterBargeMdoel from "./updateModels/WaterSupplyUpdateWaterBargeMdoel";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function WaterSupplyCompleted() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [orderCheckId, setOrderCheckId] = useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [searchingText, setSearchingText] = useState("");
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [serachedDateRange, setSearchedDateRange] = useState("");
  const [waterSupplyCompletedOrdersList, setWaterSupplyCompletedOrdersList] =
    useState([]);

  // ##########################################
  // when the update vessel name then used...
  const [openVesselEditPopUp, setOpenVesselEditPopUp] = useState("");
  const [editTimeVesselName, setEditTimeVesselName] = useState("");
  const [editTimeOrderId, setEditTimeOrderId] = useState("");
  const [isSingleFieldUpdate, setIsSingleFieldUpdate] = useState(false);

  // when the date and time should be update then used...
  const [openUpdateDateTimePopUp, setOpenUpdateDateTimePopUp] = useState(false);
  const [editTimeDateData, setEditTimeDateData] = useState({
    fieldName: "",
    fieldValue: "",
    fieldLabel: "",
    popUpTitle: "",
    fieldInitialValues: "",
    fieldPlaceholder: "",
  });

  // when the single textfield should be update then used...
  const [openUpdateTextFieldPopUp, setOpenUpdateTextFieldPopUp] =
    useState(false);

  // when the open CF pop up then used...
  const [openUpdateOrderCFPopUp, setOpenUpdateOrderCFPopUp] = useState(false);
  const [editTimeCFData, setEditTimeCFData] = useState("");

  // when the open port pop up then used...
  const [openUpdateOrderPortPopUp, setOpenUpdateOrderPortPopUp] =
    useState(false);
  const [editTimePortData, setEditTimePortData] = useState("");

  // when the open NominationQTY pop up then used...
  const [
    openUpdateOrderNominationQTYPopUp,
    setOpenUpdateOrderNominationQTYPopUp,
  ] = useState(false);
  const [editTimeNominationQTYData, setEditTimeNominationQTYData] =
    useState("");

  // when the open Water type p
  const [openUpdateOrderWaterTypePopUp, setOpenUpdateOrderWaterTypePopUp] =
    useState(false);
  const [editTimeWaterTypeData, setEditTimeWaterTypeData] = useState("");

  // when the open Water Barge
  const [openUpdateOrderWaterBargePopUp, setOpenUpdateOrderWaterBargePopUp] =
    useState(false);
  const [editTimeWaterBargeData, setEditTimeWaterBargeData] = useState("");

  // get completed order list
  const getWaterSupplyCompletedOrderList = (values) => {
    setLoading(true);
    dispatch(getWaterSupplyList({ ...values, type: "Completed" })).then(
      (res) => {
        if (res?.status === 200) {
          setLoading(false);
          setTotalRecordCount(res?.totalCount);
          setCurrentPage(res?.currentPage);
          setWaterSupplyCompletedOrdersList(res?.data);
        } else {
          setLoading(false);
          setTotalRecordCount(0);
          setCurrentPage(1);
          setWaterSupplyCompletedOrdersList([]);
        }
      }
    );
  };

  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      // to:
      //   serachedDateRange?.length > 0
      //     ? serachedDateRange[0].format("YYYY-MM-DD")
      //     : "",
      // from:
      //   serachedDateRange?.length > 0
      //     ? serachedDateRange[1].format("YYYY-MM-DD")
      //     : "",
    };
    getWaterSupplyCompletedOrderList(values);
  }, []);

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        // to:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[0].format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[1].format("YYYY-MM-DD")
        //     : "",
      };
      getWaterSupplyCompletedOrderList(values);
    },
    [searchingText, paginationPerPage, setCurrentPage]
  );

  // order view page
  const orderView = (id) => {
    navigate(`/admin/waterSupply/view-order/${id}`, {
      state: { type: "completedOrder" },
    });
  };

  // get order log
  const getOrderLog = () => {
    dispatch(getInvoiceActivityLog(orderCheckId));
  };

  useEffect(() => {
    getOrderLog();
  }, []);

  // tug order cancel
  const orderMultipleStatusCancel = (status, comment) => {
    const data = new URLSearchParams();
    orderCheckId?.forEach((items) => {
      data.append("id[]", items);
    });
    data.append("supplyOrderStatus", "Cancel");
    data.append("comment", comment || "Comment");
    dispatch(updateMultipleWaterSupplyOrderStatus(data, navigate)).then(
      (res) => {
        if (res?.status === 200) {
          setOrderCheckId([]);
          getWaterSupplyCompletedOrderList();
          getOrderLog();
        }
      }
    );
  };

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      search: searchingText,
      size: value,
      //   to:
      //     serachedDateRange?.length > 0
      //       ? serachedDateRange[0].format("YYYY-MM-DD")
      //       : "",
      //   from:
      //     serachedDateRange?.length > 0
      //       ? serachedDateRange[1].format("YYYY-MM-DD")
      //       : "",
    };
    getWaterSupplyCompletedOrderList(values);
  };

  // when the user search the journal records then used...
  const handleSearchCompletedListData = (e) => {
    const { value } = e.target;
    setSearchingText(value);
    if (value?.length > 0) {
      const values = {
        page: 1,
        search: value,
        size: paginationPerPage,
        // to:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[0].format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[1].format("YYYY-MM-DD")
        //     : "",
      };
      getWaterSupplyCompletedOrderList(values);
    } else {
      const values = {
        page: 1,
        search: "",
        size: paginationPerPage,
        // to:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[0].format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[1].format("YYYY-MM-DD")
        //     : "",
      };
      getWaterSupplyCompletedOrderList(values);
    }
  };

  // ######################################################################
  // when the user open edit the vesselname then used...
  const handleOpenEditVesselNamePopUp = (orderData) => {
    setOpenVesselEditPopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeVesselName(orderData?.vesselName);
  };

  //  when the user close edit the vesselname then used..
  const handleCloseEditVesselNamePopUp = () => {
    setOpenVesselEditPopUp(false);
    setEditTimeOrderId("");
    setEditTimeVesselName("");
  };

  // when the user update the vessel name then used...
  const handleUpdateVesselNameViaApi = (values) => {
    setIsSingleFieldUpdate(true);
    const data = new URLSearchParams();
    data.append("id", editTimeOrderId);
    if (values?.vesselName) {
      data.append("vesselName", values?.vesselName?.trim());
    }

    dispatch(handleGetUpdateSingleWaterSupplyOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseEditVesselNamePopUp();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
          };
          getWaterSupplyCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open update date and time modal then used...
  const handleOpenDateTimeEditModal = (orderData, editTimeData) => {
    setOpenUpdateDateTimePopUp(true);
    setEditTimeDateData({
      fieldName: editTimeData?.fieldName,
      fieldValue: editTimeData?.fieldValue,
      fieldLabel: editTimeData?.fieldLabel,
      popUpTitle: editTimeData?.popUpTitle,
      fieldInitialValues: editTimeData?.initialValues,
    });
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close update date and time modal then used...
  const handleCloseDateTimeEditModal = () => {
    setOpenUpdateDateTimePopUp(false);
    setEditTimeDateData({
      fieldName: "",
      fieldValue: "",
      fieldTitle: "",
      popUpTitle: "",
      fieldInitialValues: "",
      fieldPlaceholder: "",
    });
    setEditTimeOrderId("");
  };

  // when the user update the date and time  data then used...
  const handleUpdateDateTimeViaApi = (formValues, editTimeDetails) => {
    setIsSingleFieldUpdate(true);

    const data = new URLSearchParams();
    data.append("id", editTimeOrderId);
    if (formValues[editTimeDetails?.fieldName]) {
      data.append(
        editTimeDetails?.fieldName,
        formValues[editTimeDetails?.fieldName]
      );
      // data.append("fieldName", editTimeDetails?.fieldName);
      // data.append("fieldValue", formValues[editTimeDetails?.fieldName]);
    }

    dispatch(handleGetUpdateSingleWaterSupplyOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseDateTimeEditModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            // to:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[0].format("YYYY-MM-DD")
            //     : "",
            // from:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[1].format("YYYY-MM-DD")
            //     : "",
          };
          getWaterSupplyCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open  edit the single text field pop up then used...
  const handleOpenTextFieldEditModal = (orderData, editTimeData) => {
    setOpenUpdateTextFieldPopUp(true);
    setEditTimeDateData({
      fieldName: editTimeData?.fieldName,
      fieldValue: editTimeData?.fieldValue,
      fieldLabel: editTimeData?.fieldLabel,
      popUpTitle: editTimeData?.popUpTitle,
      fieldInitialValues: editTimeData?.initialValues,
      fieldPlaceholder: editTimeData?.fieldPlaceholder,
    });
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close  edit the single text field pop up then used...
  const handleCloseTextFieldEditModal = () => {
    setOpenUpdateTextFieldPopUp(false);
    setEditTimeDateData({
      fieldName: "",
      fieldValue: "",
      fieldTitle: "",
      popUpTitle: "",
      fieldInitialValues: "",
      fieldPlaceholder: "",
    });
    setEditTimeOrderId("");
  };

  // when the user update the date and time  data then used...
  const handleUpdateTextFieldViaApi = (formValues, editTimeDetails) => {
    setIsSingleFieldUpdate(true);

    const data = new URLSearchParams();
    data.append("id", editTimeOrderId);

    if (formValues[editTimeDetails?.fieldName]) {
      data.append(
        editTimeDetails?.fieldName,
        formValues[editTimeDetails?.fieldName]
      );
    }

    dispatch(handleGetUpdateSingleWaterSupplyOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseTextFieldEditModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            // to:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[0].format("YYYY-MM-DD")
            //     : "",
            // from:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[1].format("YYYY-MM-DD")
            //     : "",
          };
          getWaterSupplyCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the CF  modal then used...
  const handleOpenUpdateOrderCFModal = (orderData) => {
    setOpenUpdateOrderCFPopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeCFData(orderData?.coastal);
  };

  // when the user close the update CF modal then used...
  const handleCloseUpdateOrderCFModal = () => {
    setOpenUpdateOrderCFPopUp(false);
    setEditTimeOrderId("");
    setEditTimeCFData("");
  };

  // when the user update the CF then used...
  const handleUpdateOrderCFViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new URLSearchParams();
    data.append("id", editTimeOrderId);

    if (formValues?.coastal) {
      data.append("coastal", formValues?.coastal);
    }

    dispatch(handleGetUpdateSingleWaterSupplyOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderCFModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getWaterSupplyCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the update port modal then used...
  const handleOpenUpdateOrderPortModal = (orderData) => {
    setOpenUpdateOrderPortPopUp(true);
    setEditTimePortData(orderData?.port?._id);
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close the update port modal then used...
  const handleCloseUpdateOrderPortModal = () => {
    setOpenUpdateOrderPortPopUp(false);
    setEditTimePortData("");
    setEditTimeOrderId("");
  };

  // when the user update the port then used...
  const handleUpdateOrderPortViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new URLSearchParams();
    data.append("id", editTimeOrderId);

    if (formValues?.port) {
      data.append("port", formValues?.port);
    }

    dispatch(handleGetUpdateSingleWaterSupplyOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderPortModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            // to:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[0].format("YYYY-MM-DD")
            //     : "",
            // from:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[1].format("YYYY-MM-DD")
            //     : "",
          };
          getWaterSupplyCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the NominationQTY  modal then used...
  const handleOpenUpdateOrderNominationQTYModal = (orderData) => {
    setOpenUpdateOrderNominationQTYPopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeNominationQTYData(orderData?.qty);
  };

  // when the user close the update NominationQTY modal then used...
  const handleCloseUpdateOrderNominationQTYModal = () => {
    setOpenUpdateOrderNominationQTYPopUp(false);
    setEditTimeOrderId("");
    setEditTimeNominationQTYData("");
  };

  // when the user update the NominationQTY then used...
  const handleUpdateOrderNominationQTYViaApi = (formValues) => {
    const data = new URLSearchParams();
    data.append("id", editTimeOrderId);
    setIsSingleFieldUpdate(true);

    if (formValues?.qty) {
      data.append("qty", formValues?.qty);
    }

    dispatch(handleGetUpdateSingleWaterSupplyOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderNominationQTYModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            // to:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[0].format("YYYY-MM-DD")
            //     : "",
            // from:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[1].format("YYYY-MM-DD")
            //     : "",
          };
          getWaterSupplyCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the NominationQTY  modal then used...
  const handleOpenUpdateOrderWaterTypeModal = (orderData) => {
    setOpenUpdateOrderWaterTypePopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeWaterTypeData(orderData?.waterType);
  };

  // when the user close the update NominationQTY modal then used...
  const handleCloseUpdateOrderWaterTypeModal = () => {
    setOpenUpdateOrderWaterTypePopUp(false);
    setEditTimeOrderId("");
    setEditTimeWaterTypeData("");
  };

  // when the user update the NominationQTY then used...
  const handleUpdateOrderWaterTypeViaApi = (formValues) => {
    const data = new URLSearchParams();
    data.append("id", editTimeOrderId);
    setIsSingleFieldUpdate(true);

    if (formValues?.waterType) {
      data.append("waterType", formValues?.waterType);
    }

    dispatch(handleGetUpdateSingleWaterSupplyOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderWaterTypeModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            // to:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[0].format("YYYY-MM-DD")
            //     : "",
            // from:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[1].format("YYYY-MM-DD")
            //     : "",
          };
          getWaterSupplyCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the water barge  modal then used...
  const handleOpenUpdateOrderWaterBargeModal = (orderData) => {
    setOpenUpdateOrderWaterBargePopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeWaterBargeData(orderData?.waterBarge?._id);
  };

  // when the user close the update water barge modal then used...
  const handleCloseUpdateOrderWaterBargeModal = () => {
    setOpenUpdateOrderWaterBargePopUp(false);
    setEditTimeOrderId("");
    setEditTimeWaterBargeData("");
  };

  // when the user update the water barge then used...
  const handleUpdateOrderWaterBargeViaApi = (formValues) => {
    const data = new URLSearchParams();
    data.append("id", editTimeOrderId);
    setIsSingleFieldUpdate(true);

    if (formValues?.waterBarge) {
      data.append("waterBarge", formValues?.waterBarge);
    }

    dispatch(handleGetUpdateSingleWaterSupplyOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderWaterBargeModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            // to:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[0].format("YYYY-MM-DD")
            //     : "",
            // from:
            //   serachedDateRange?.length > 0
            //     ? serachedDateRange[1].format("YYYY-MM-DD")
            //     : "",
          };
          getWaterSupplyCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  return (
    <>
      <Index.DynamicTitle title="Water Supply Completed Orders" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Water Supply Completed Orders
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchCompletedListData(e)}
                  />
                </Search>

                <Index.Box className="common-button grey-button">
                  <Index.Button
                    variant="contained"
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "DD-MM-YYYY"
                      );
                      NEW_EXPORT_EXCEL(
                        "/admin/supply-order-export",
                        `Water_Supply_Current_Order_List_${curDate}`,
                        {
                          type: "Completed",
                          search: searchingText,
                          // to:
                          //   serachedDateRange?.length > 0
                          //     ? serachedDateRange[0].format("YYYY-MM-DD")
                          //     : "",
                          // from:
                          //   serachedDateRange?.length > 0
                          //     ? serachedDateRange[1].format("YYYY-MM-DD")
                          //     : "",
                        }
                      );
                    }}
                  >
                    Export
                  </Index.Button>
                </Index.Box>
                <Index.Box className="common-button blue-button res-blue-button">
                  {" "}
                  {waterSupplyCompletedOrdersList?.length &&
                  orderCheckId?.length ? (
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={orderMultipleStatusCancel}
                    >
                      Cancel Selected Order
                    </Index.Button>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container table-max-width"
            >
              <Index.Table
                // aria-label="simple table"
                stickyHeader
                aria-label="sticky table"
                className="table-design-main supply-table tug-supply-table one-line-table invoice-table-set "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell> </Index.TableCell>
                    <Index.TableCell></Index.TableCell>
                    <Index.TableCell align="left">
                      Name of Vessel
                    </Index.TableCell>
                    <Index.TableCell align="left">Agent</Index.TableCell>
                    <Index.TableCell align="left">Port</Index.TableCell>
                    <Index.TableCell align="left">Vessel ETA</Index.TableCell>
                    <Index.TableCell align="left">Vessel ETB</Index.TableCell>
                    <Index.TableCell align="left">Vessel ETD</Index.TableCell>
                    <Index.TableCell align="left">Coastal</Index.TableCell>
                    <Index.TableCell align="left">Quantity</Index.TableCell>
                    <Index.TableCell align="left">Water Type</Index.TableCell>
                    <Index.TableCell align="left">Water Barge</Index.TableCell>
                    <Index.TableCell align="left">Order By</Index.TableCell>
                    <Index.TableCell align="left">
                      Supply Date & Time
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={17} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {waterSupplyCompletedOrdersList?.length ? (
                      waterSupplyCompletedOrdersList?.map((item, index) => (
                        <Index.TableRow
                          key={item?._id}
                          className={
                            item?.bunkerCall === true
                              ? "tanker-row-color"
                              : item?.hasOwnProperty("challan") &&
                                item?.challan?.hasOwnProperty("distance") &&
                                item?.challan?.distance > 13
                              ? "tanker-grey-color"
                              : ""
                          }
                          sx={{ cursor: "pointer" }}
                        >
                          <Index.TableCell>
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  onChange={(event) => {
                                    if (event.target.checked === true) {
                                      setOrderCheckId([
                                        ...orderCheckId,
                                        item?._id,
                                      ]);
                                    } else {
                                      setOrderCheckId(
                                        orderCheckId?.filter((ele) => {
                                          return ele !== item?._id;
                                        })
                                      );
                                    }
                                  }}
                                />
                              }
                              // label="Shipping Bill"
                            />
                          </Index.TableCell>
                          <Index.TableCell>
                            {" "}
                            <Index.Box className="challan-icon-align">
                              <Index.Box className="d-flex">
                                {item?.supplyChallanAvailable === false ? (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    sx={{ color: "black" }}
                                    className="challan-icon icon challan-icon-hidden"
                                  />
                                ) : item?.supplyChallanStatus === "" ? (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    sx={{ color: "black" }}
                                    className="challan-icon"
                                  />
                                ) : item?.supplyChallanStatus === "Approved" ? (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    color="success"
                                    className="challan-icon"
                                  />
                                ) : (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    color="error"
                                    className="challan-icon"
                                  />
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.vesselName ? item?.vesselName : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenEditVesselNamePopUp(item)
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.agentName ? item?.agentName : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenTextFieldEditModal(item, {
                                      fieldName: "agentName",
                                      fieldValue: item?.agentName,
                                      fieldLabel: "Agent",
                                      popUpTitle: "Edit Agent",
                                      fieldPlaceholder: "Agent",
                                      initialValues: {
                                        agentName: item?.agentName,
                                      },
                                    })
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.port?.supplyPortName
                                  ? item?.port?.supplyPortName
                                  : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenUpdateOrderPortModal(item)
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.vesselEta
                                  ? Index.moment(item?.vesselEta).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                  : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenDateTimeEditModal(item, {
                                      fieldName: "vesselEta",
                                      fieldValue: item?.vesselEta,
                                      fieldLabel: "Vessel ETA",
                                      popUpTitle: "Edit Vessel ETA",
                                      initialValues: {
                                        vesselEta: item?.vesselEta,
                                      },
                                    })
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.vesselEtb
                                  ? Index.moment(item?.vesselEtb).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                  : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenDateTimeEditModal(item, {
                                      fieldName: "vesselEtb",
                                      fieldValue: item?.vesselEtb,
                                      fieldLabel: "Vessel ETB",
                                      popUpTitle: "Edit Vessel ETB",
                                      initialValues: {
                                        vesselEtb: item?.vesselEtb,
                                      },
                                    })
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.vesselEtd
                                  ? Index.moment(item?.vesselEtd).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                  : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenDateTimeEditModal(item, {
                                      fieldName: "vesselEtd",
                                      fieldValue: item?.vesselEtd,
                                      fieldLabel: "Vessel ETD",
                                      popUpTitle: "Edit Vessel ETD",
                                      initialValues: {
                                        vesselEtd: item?.vesselEtd,
                                      },
                                    })
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.coastal ? item?.coastal : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenUpdateOrderCFModal(item)
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.qty ? item?.qty : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenUpdateOrderNominationQTYModal(
                                      item
                                    )
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.waterType ? item?.waterType : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenUpdateOrderWaterTypeModal(item)
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.hasOwnProperty("waterBarge") &&
                                item?.waterBarge?.bargeName
                                  ? item?.waterBarge?.bargeName
                                  : "-"}
                              </Index.Box>
                              {item?.waterType === "Freshwater" ? (
                                <>
                                  <Index.Box className="single-edit-btn-box">
                                    <Index.Button
                                      className="seperate-edit-icon"
                                      type="button"
                                      onClick={() =>
                                        handleOpenUpdateOrderWaterBargeModal(
                                          item
                                        )
                                      }
                                    >
                                      <Index.EditIcon className="edit-btn-icon" />
                                    </Index.Button>
                                  </Index.Box>
                                </>
                              ) : (
                                <></>
                              )}
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.orderBy ? item?.orderBy : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenTextFieldEditModal(item, {
                                      fieldName: "orderBy",
                                      fieldValue: item?.orderBy,
                                      fieldLabel: "Order By",
                                      popUpTitle: "Edit Order By",
                                      fieldPlaceholder: "Order By",
                                      initialValues: {
                                        orderBy: item?.orderBy,
                                      },
                                    })
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.supplyDateTime
                                  ? Index.moment(item?.supplyDateTime).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                  : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenDateTimeEditModal(item, {
                                      fieldName: "supplyDateTime",
                                      fieldValue: item?.supplyDateTime,
                                      fieldLabel: "Supply Date & Time",
                                      popUpTitle: "Edit Supply Date & Time",
                                      initialValues: {
                                        supplyDateTime: item?.supplyDateTime,
                                      },
                                    })
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={18}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <WaterSupplyUpdateVesselNameModel
        openVesselEditPopUp={openVesselEditPopUp}
        handleCloseEditVesselNamePopUp={handleCloseEditVesselNamePopUp}
        orderId={editTimeOrderId}
        handleUpdateVesselNameViaApi={handleUpdateVesselNameViaApi}
        editTimeVesselName={editTimeVesselName}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <SingleUpdateDateTimePopUp
        openUpdateDatePopUp={openUpdateDateTimePopUp}
        handleCloseDateEditModal={handleCloseDateTimeEditModal}
        editTimeOrderId={editTimeOrderId}
        handleUpdateDateViaApi={handleUpdateDateTimeViaApi}
        editTimeDateData={editTimeDateData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <SingleUpdateTextFieldPopUp
        openUpdateDatePopUp={openUpdateTextFieldPopUp}
        handleCloseDateEditModal={handleCloseTextFieldEditModal}
        editTimeOrderId={editTimeOrderId}
        handleUpdateDateViaApi={handleUpdateTextFieldViaApi}
        editTimeDateData={editTimeDateData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <WaterSupplyUpdateCFModel
        openUpdateDatePopUp={openUpdateOrderCFPopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderCFModal}
        handleUpdateDateViaApi={handleUpdateOrderCFViaApi}
        editTimeDateData={editTimeDateData}
        editTimeCFData={editTimeCFData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <WaterSupplyUpdatePortModel
        openUpdateDatePopUp={openUpdateOrderPortPopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderPortModal}
        handleUpdateDateViaApi={handleUpdateOrderPortViaApi}
        editTimePortData={editTimePortData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <WaterSupplyUpdateQuantityModel
        openUpdateDatePopUp={openUpdateOrderNominationQTYPopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderNominationQTYModal}
        handleUpdateDateViaApi={handleUpdateOrderNominationQTYViaApi}
        editTimeDateData={editTimeDateData}
        editTimeNominationQTYData={editTimeNominationQTYData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <WaterSupplyUpdateWaterTypeModel
        openUpdateDatePopUp={openUpdateOrderWaterTypePopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderWaterTypeModal}
        handleUpdateDateViaApi={handleUpdateOrderWaterTypeViaApi}
        editTimeWaterTypeData={editTimeWaterTypeData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <WaterSupplyUpdateWaterBargeMdoel
        openUpdateDatePopUp={openUpdateOrderWaterBargePopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderWaterBargeModal}
        handleUpdateDateViaApi={handleUpdateOrderWaterBargeViaApi}
        editTimeWaterBargeData={editTimeWaterBargeData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />
    </>
  );
}
export default WaterSupplyCompleted;
