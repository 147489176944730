import React, { useState } from "react";
import Index from "../../../../container/Index";
const TraderEnquiryStatusModal = ({
  updateOpen,
  handleClose,
  inquiryStatusUpdate,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const [comment, setComment] = useState("");

  return (
    <Index.Modal
      open={updateOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-delete modal"
    >
      <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
        <Index.ClearIcon className="model-close-icon" onClick={handleClose} />
        <Index.Box className="modal-circle-main">
          <img src={Index.Svg.close} className="user-circle-img" alt="icon" />
        </Index.Box>

        <Index.Typography
          className="delete-modal-title"
          component="h2"
          variant="h2"
        >
          Are you sure?
        </Index.Typography>
        <Index.Typography
          className="delete-modal-para common-para"
          component="p"
          variant="p"
        >
          Are you sure you want to update this record?
        </Index.Typography>
        <Index.Box className="title-main">
          <Index.Grid item xs={12} sm={12} md={12}>
            <Index.Typography
              variant="label"
              component="label"
              className="input-label"
            >
              Comment
            </Index.Typography>
            <Index.Box className="input-design-div with-border">
              <Index.TextareaAutosize
                hiddenLabel
                minRows={3}
                id="filled-hidden-label-normal"
                className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set "
                placeholder="Comment"
                variant="filled"
                name="comment"
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </Index.Box>
          </Index.Grid>

          <Index.Box className="line" mt={3} mb={3}></Index.Box>
        </Index.Box>

        <Index.Box className="delete-modal-btn-flex">
          <Index.Button
            className="modal-inquiry-success-btn modal-btn"
            onClick={() => inquiryStatusUpdate()}
          >
            Confirm
          </Index.Button>
          <Index.Button
            className="modal-inquiry-error-btn modal-btn"
            onClick={handleClose}
          >
            Cancel
          </Index.Button>
        </Index.Box>
      </Index.Box>
    </Index.Modal>
  );
};

export default TraderEnquiryStatusModal;
