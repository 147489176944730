import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getSalesInquiryList } from "../../../../redux/admin/action";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function SalesEnquiryList() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const { id } = Index.useParams();

  // state for trader list
  const [traderList, setTraderList] = useState([]);
  // state for checkbox

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  //get single trader list
  const singleSalesList = () => {
    setLoading(true);
    const data = new URLSearchParams();
    data.append("salesId", id);
    dispatch(getSalesInquiryList(data))
      .then((res) => {
        setTraderList(res?.data);
        if (res?.status === 200) {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
      })
      .catch((err) => {});
  };

  // get single trader list api
  useEffect(() => {
    singleSalesList();
  }, []);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(traderList?.reverse());
  }, [traderList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(traderList);
    setSearchedData(
      traderList?.filter(
        (item) =>
          item?.inquiryNo
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.vesselName?.toLowerCase().includes(searched?.toLowerCase()) ||
          item?.traderId?.traderName
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.salesId?.salesName
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.lowerQuantity
            ?.toString()
            .toLowerCase()
            ?.includes(searched?.toLowerCase()) ||
          item?.higherQuantity
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.port?.portName
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.terminal?.toLowerCase().includes(searched?.toLowerCase()) ||
          item?.CF?.toLowerCase().includes(searched?.toLowerCase()) ||
          item?.product?.toLowerCase().includes(searched?.toLowerCase()) ||
          Index.moment(item?.eta)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          Index.moment(item?.laycanStartDate)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          Index.moment(item?.laycanEndDate)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math?.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Pagination calculation end

  // edit enq
  const handleEdit = (row) => {
    navigate(`/sales/enq/add/${id}`, {
      state: { row },
    });
  };

  return (
    <>
      <Index.DynamicTitle title="Enquiry" />
      <Index.Box className="">
        <Index.Box className="barge-common-box barge-common-box-set">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Current Enquiry
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => requestSearch(e.target.value)}
                  />
                </Search>

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to={`/sales/enq/add/${id}`}
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Enquiry
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>Enquiry No</Index.TableCell>
                    <Index.TableCell align="left">
                      Name of Vessel
                    </Index.TableCell>
                    <Index.TableCell align="left">Sales Person</Index.TableCell>
                    <Index.TableCell align="left">Trader Name</Index.TableCell>
                    <Index.TableCell align="left">Port</Index.TableCell>
                    <Index.TableCell align="left">Terminal</Index.TableCell>
                    <Index.TableCell align="left">C/F</Index.TableCell>
                    <Index.TableCell align="left">Quantity(MT)</Index.TableCell>
                    <Index.TableCell align="left">Product</Index.TableCell>
                    <Index.TableCell align="left">ETA</Index.TableCell>
                    <Index.TableCell align="left">Laycan </Index.TableCell>
                    <Index.TableCell align="left">Remark </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.inquiryNo ? item?.inquiryNo : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.vesselName ? item?.vesselName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.salesId?.salesName
                              ? item?.salesId?.salesName
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.traderId?.traderName
                              ? item?.traderId?.traderName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.port?.portName ? item?.port?.portName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.terminal ? item?.terminal : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.CF ? item?.CF : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.orderDetails?.length > 0
                              ? item?.orderDetails
                                  .map((items, index) => {
                                    if (
                                      items.lowerQuantity &&
                                      items.higherQuantity !== 0
                                    ) {
                                      return `${items.lowerQuantity} - ${items.higherQuantity}`;
                                    } else if (items.lowerQuantity) {
                                      return items.lowerQuantity.toString();
                                    } else {
                                      return "-";
                                    }
                                  })
                                  .join(" ")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.orderDetails?.length
                              ? item?.orderDetails &&
                                item?.orderDetails?.map((items, index) => {
                                  if (items?.product) {
                                    return items?.product + " ";
                                  } else {
                                    return "-";
                                  }
                                })
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.eta
                              ? Index.moment(item?.eta).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.laycanStartDate || item?.laycanEndDate ? (
                              <>
                                {Index.moment(item?.laycanStartDate).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                -{" "}
                                {Index.moment(item?.laycanEndDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            <Index.Tooltip title={item?.remark} arrow>
                              <Index.Button className="remark-text">
                                {item?.remark ? (
                                  <>
                                    {item?.remark?.length > 10 ? (
                                      <>{`${item?.remark?.substring(
                                        0,
                                        10
                                      )}...`}</>
                                    ) : (
                                      item?.remark
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </Index.Button>
                            </Index.Tooltip>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>

          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default SalesEnquiryList;
