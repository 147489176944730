import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getGSTR } from "../../../../redux/admin/action";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const GSTRList = () => {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [gstrData, setGstrData] = useState();
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  const [nominationFromDate, setNominationFromDate] = useState();
  const [nominationToDate, setNominationToDate] = useState();
  const [nominationFromError, setNominationFromError] = useState();
  const [nominationToError, setNominationToError] = useState();

  // pagination logic
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  // Filter By Date

  // filter
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  function isValidDateFormat(input) {
    // Regular expression for YYYY-MM-DD format
    const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
    return dateFormat.test(input);
  }

  const handleFilterSubmit = (anchor) => {
    if (!nominationFromDate) {
      setNominationFromError("Please select date");
    }

    if (!nominationToDate) {
      setNominationToError("Please select data");
    }
    if (
      nominationFromDate &&
      nominationToDate &&
      isValidDateFormat(nominationFromDate) &&
      isValidDateFormat(nominationToDate)
    ) {
      const filterData = gstrData?.filter((ele) => {
        return (
          Index.moment(ele?.invoiceDate, "YYYY-MM-DD").isSameOrAfter(
            Index.moment(nominationFromDate, "YYYY-MM-DD"),
            "day"
          ) &&
          Index.moment(ele?.invoiceDate, "YYYY-MM-DD").isSameOrBefore(
            Index.moment(nominationToDate, "YYYY-MM-DD"),
            "day"
          )
        );
      });
      setSearchedData(filterData);
      setState({ ...state, ["right"]: false });
    }
  };

  const handleFilterClear = (anchor) => {
    toggleDrawer(anchor, false);
    setNominationFromDate(null);
    setNominationToDate(null);
    setSearchedData(gstrData);
    setState({ ...state, ["right"]: false });
  };

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(gstrData);
  }, [gstrData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(gstrData);
    setSearchedData(
      gstrData?.filter(
        (item) =>
          Index.moment(item?.invoiceDate)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            ?.includes(searched?.toString().toLowerCase()) ||
          item?.invoiceNo
            ?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.name?.partyName
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.name?.gstNo
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.totalTaxableValue
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.totalIGSTAmount
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.totalCGSTAmount
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.totalSGSTAmount
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.invoiceAmount
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  //  get gstr list
  const getGSTRList = () => {
    setLoading(true);
    dispatch(getGSTR()).then((res) => {
      if (res?.status === 200) {
        setGstrData(res?.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    });
  };

  useEffect(() => {
    getGSTRList();
  }, []);

  return (
    <div>
      <Index.DynamicTitle title="GSTR" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  GSTR
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) =>
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000)
                    }
                  />
                </Search>

                <Index.Box className="filter-main">
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Index.Box className="filter-btn-main common-button filter-btn blue-button ">
                        <Index.Button
                          className="filter-btn"
                          onClick={toggleDrawer(anchor, true)}
                          disableRipple
                        >
                          <img src={Index.Svg.filter} alt="" />
                        </Index.Button>
                      </Index.Box>

                      <Index.SwipeableDrawer
                        className="filter-main"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        <Index.Box className="filter-header">
                          <Index.Box className="d-flex align-items-center filter-header-inner">
                            <Index.Typography className="filter-title">
                              GSTR
                            </Index.Typography>
                            <Index.Box className="close-btn filter-close-btn">
                              <img
                                src={Index.Svg.close}
                                onClick={toggleDrawer(anchor, false)}
                                alt=""
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="filter-inner-main">
                          <Index.Box>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Nomination From
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border filter-date-overflow">
                                  <Index.LocalizationProvider
                                    dateAdapter={Index.AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={["DatePicker"]}
                                      className="filter-demo-item"
                                    >
                                      <DemoItem label="">
                                        <Index.DatePicker
                                          fullWidth
                                          value={nominationFromDate}
                                          className="filter-datepicker"
                                          disableFuture
                                          format="DD/MM/YYYY"
                                          onChange={(value) => {
                                            const NewDate = Index.moment(
                                              value.$d
                                            ).format("YYYY-MM-DD");
                                            setNominationFromError("");
                                            setNominationFromDate(NewDate);
                                          }}
                                        />
                                      </DemoItem>
                                    </DemoContainer>
                                  </Index.LocalizationProvider>
                                  <Index.FormHelperText error>
                                    {nominationFromError}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="mt-10">
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Nomination To
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border filter-date-overflow">
                                  <Index.LocalizationProvider
                                    dateAdapter={Index.AdapterDayjs}
                                  >
                                    <DemoContainer components={["DatePicker"]}>
                                      <DemoItem label="">
                                        <Index.DatePicker
                                          fullWidth
                                          value={nominationToDate}
                                          disableFuture
                                          format="DD/MM/YYYY"
                                          name="InvoiceDate"
                                          className="filter-datepicker"
                                          onChange={(value) => {
                                            const NewDate = Index.moment(
                                              value.$d
                                            ).format("YYYY-MM-DD");
                                            setNominationToError("");
                                            setNominationToDate(NewDate);
                                          }}
                                        />
                                      </DemoItem>
                                    </DemoContainer>
                                  </Index.LocalizationProvider>
                                  <Index.FormHelperText error>
                                    {nominationToError}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="filter-footer ">
                          <Index.Box className="flex-end">
                            <Index.Box className="common-button blue-button mr-15">
                              <Index.Button
                                variant="contained"
                                disableRipple
                                onClick={() => handleFilterSubmit(anchor)}
                              >
                                Filter
                              </Index.Button>
                            </Index.Box>
                            <Index.Box className="common-button outline-button ">
                              <Index.Button
                                variant="contained"
                                disableRippl
                                onClick={() => handleFilterClear(anchor)}
                              >
                                Clear All
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.SwipeableDrawer>
                    </React.Fragment>
                  ))}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Invoice No
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice Date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Party Name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      GST IN
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Taxable Value
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      IGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      CGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      SGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice Value
                    </Index.TableCell>

                    {/* <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Action
                    </Index.TableCell> */}
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell>
                            {row?.invoiceNo ? row?.invoiceNo : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.invoiceDate
                              ? Index.moment(row?.invoiceDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.name?.partyName ? row?.name?.partyName : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.name?.gstNo ? row?.name?.gstNo : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalTaxableValue
                              ? row?.totalTaxableValue?.toLocaleString(
                                  "en-IN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalIGSTAmount
                              ? row?.totalIGSTAmount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalCGSTAmount
                              ? row?.totalCGSTAmount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalSGSTAmount
                              ? row?.totalSGSTAmount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.invoiceAmount
                              ? row?.invoiceAmount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          {/* <Index.TableCell>
                            <Index.IconButton>
                              <Index.DeleteIcon
                                color="error"
                                onClick={() => {
                                  setRowId(row?._id);
                                  handleDeleteOpen();
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell> */}
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={14}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default GSTRList;
