import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import dayjs from "dayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import UpdateVesselNamePopUp from "./UpdateVesselNamePopUp";
import SingleUpdateDatePopUp from "../../../../component/common/SingleUpdateDatePopUp";
import SingleUpdateDateTimePopUp from "../../../../component/common/SingleUpdateDateTimePopUp";
import SingleUpdateTextFieldPopUp from "../../../../component/common/SingleUpdateTextFieldPopUp";
import UdpateOrderTerminalModal from "./EditModels/UpdateOrderTerminalModal";
import UpdateOrderTraderModal from "./EditModels/UpdateOrderTraderModal";
import UpdateOrderPortModal from "./EditModels/UpdateOrderPortModal";
import UdpateOrderProposedBargeModal from "./EditModels/UdpateOrderProposedBargeModal";
import UpdateOrderCFModal from "./EditModels/UpdateOrderCFModal";
import UpdateOrderProductModal from "./EditModels/UpdateOrderProductModal";
import UpdateOrderNominationQTYModal from "./EditModels/UpdateOrderNominationQTYModal";

import {
  allCancelOrder,
  getInvoiceActivityLog,
  orderShippingBillBoxCategory,
  completedOrderList,
  handleGetUpdateSingleOrderField,
} from "../../../../redux/admin/action";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

function CompleteOrder() {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [orderCheckId, setOrderCheckId] = useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [searchingText, setSearchingText] = useState("");
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [serachedDateRange, setSearchedDateRange] = useState("");
  const [completedOrdersList, setCompletedOrdersList] = useState([]);

  // when the update vessel name then used...
  const [openVesselEditPopUp, setOpenVesselEditPopUp] = useState("");
  const [editTimeVesselName, setEditTimeVesselName] = useState("");
  const [editTimeOrderId, setEditTimeOrderId] = useState("");

  // when the date should be update then used...
  const [openUpdateDatePopUp, setOpenUpdateDatePopUp] = useState(false);
  const [editTimeDateData, setEditTimeDateData] = useState({
    fieldName: "",
    fieldValue: "",
    fieldLabel: "",
    popUpTitle: "",
    fieldInitialValues: "",
    fieldPlaceholder: "",
  });

  // when the date and time should be update then used...
  const [openUpdateDateTimePopUp, setOpenUpdateDateTimePopUp] = useState(false);

  // when the single textfield should be update then used...
  const [openUpdateTextFieldPopUp, setOpenUpdateTextFieldPopUp] =
    useState(false);

  // when the open terminal pop up then used...
  const [openUpdateOrderTerminalPopUp, setOpenUpdateOrderTerminalPopUp] =
    useState(false);
  const [editTimeTerminalData, setEditTimeTerminalData] = useState("");

  // when the open trader pop up then used...
  const [openUpdateOrderTraderPopUp, setOpenUpdateOrderTraderPopUp] =
    useState(false);
  const [editTimeTraderData, setEditTimeTraderData] = useState("");

  // when the open port pop up then used...
  const [openUpdateOrderPortPopUp, setOpenUpdateOrderPortPopUp] =
    useState(false);
  const [editTimePortData, setEditTimePortData] = useState("");

  // when the open proposed Barge pop up then used...
  const [
    openUpdateOrderProposedBargePopUp,
    setOpenUpdateOrderProposedBargePopUp,
  ] = useState(false);
  const [editTimeProposedBargeData, setEditTimeProposedBargeData] =
    useState("");
  const [proposedBargeTimePortData, setProposedBargeTimePortData] =
    useState("");

  // when the open CF pop up then used...
  const [openUpdateOrderCFPopUp, setOpenUpdateOrderCFPopUp] = useState(false);
  const [editTimeCFData, setEditTimeCFData] = useState("");

  // when the open CF pop up then used...
  const [openUpdateOrderProductPopUp, setOpenUpdateOrderProductPopUp] =
    useState(false);
  const [editTimeProductData, setEditTimeProductData] = useState("");

  // when the open NominationQTY pop up then used...
  const [
    openUpdateOrderNominationQTYPopUp,
    setOpenUpdateOrderNominationQTYPopUp,
  ] = useState(false);
  const [editTimeNominationQTYData, setEditTimeNominationQTYData] =
    useState("");

  // when the single field update then used...
  const [isSingleFieldUpdate, setIsSingleFieldUpdate] = useState(false);

  // multiple order cancel
  const cancelMultipleOrder = () => {
    const data = new URLSearchParams();
    orderCheckId?.forEach((items) => {
      data.append("id[]", items);
    });
    data.append("orderStatus", "Cancel");
    dispatch(allCancelOrder(data)).then((res) => {
      if (res == 200) {
        setOrderCheckId([]);
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        getCompletedOrderList(values);
        getOrderLog();
        setSearchingText("");
      }
    });
  };

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      getCompletedOrderList(values);
    },
    [searchingText, paginationPerPage, serachedDateRange, setCurrentPage]
  );

  // order view function
  const orderView = (id) => {
    window.open(`/admin/order/view-order/${id}`, "_blank");
  };

  // order permission
  const orderCheckPermission = (status, id, name) => {
    const data = new URLSearchParams();
    data.append("id", id);
    if (name === "wharfage") {
      data.append("wharfage", status);
    }
    if (name === "shippingBill") {
      data.append("shippingBill", status);
    }
    if (name === "harbourMaster") {
      data.append("harbourMaster", status);
    }

    dispatch(orderShippingBillBoxCategory(data, completedOrderList));
  };

  // get log for order
  const getOrderLog = () => {
    dispatch(getInvoiceActivityLog(orderCheckId));
  };

  // get completed order list
  const getCompletedOrderList = (values) => {
    setLoading(true);
    dispatch(completedOrderList(values)).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setTotalRecordCount(res?.totalCount);
        setCurrentPage(res?.currentPage);
        setCompletedOrdersList(res?.data);
      } else {
        setLoading(false);
        setTotalRecordCount(0);
        setCurrentPage(1);
        setCompletedOrdersList([]);
      }
    });
  };

  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0].format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1].format("YYYY-MM-DD")
          : "",
    };
    getCompletedOrderList(values);
    getOrderLog();
  }, []);

  // when the use change the date then used...
  const handleChangeDate = React.useCallback(
    (data) => {
      setSearchedDateRange(data);
      if (data[0] !== null && data[1] !== null) {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
          from: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
        };
        getCompletedOrderList(values);
      } else if (data[0] === null && data[1] === null) {
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        getCompletedOrderList(values);
      }
    },
    [setSearchedDateRange, searchingText, paginationPerPage]
  );

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      search: searchingText,
      size: value,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0].format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1].format("YYYY-MM-DD")
          : "",
    };
    getCompletedOrderList(values);
  };

  // when the user search the journal records then used...
  const handleSearchCompletedListData = (e) => {
    const { value } = e.target;
    setSearchingText(value);
    if (value?.length > 0) {
      const values = {
        page: 1,
        search: value,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      getCompletedOrderList(values);
    } else {
      const values = {
        page: 1,
        search: "",
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      getCompletedOrderList(values);
    }
  };

  // #################################################################################################
  // when the user edit the vesselname then used...
  const handleOpenEditVesselNamePopUp = (orderData) => {
    setOpenVesselEditPopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeVesselName(orderData?.vesselName);
  };

  const handleCloseEditVesselNamePopUp = () => {
    setOpenVesselEditPopUp(false);
    setEditTimeOrderId("");
    setEditTimeVesselName("");
  };

  // when the user update the vessel name then used...
  const handleUpdateVesselNameViaApi = (values) => {
    setIsSingleFieldUpdate(true);
    const data = new FormData();
    data.append("id", editTimeOrderId);
    if (values?.vesselName) {
      data.append("vesselName", values?.vesselName?.trim());
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseEditVesselNamePopUp();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user update the date field then used...
  const handleOpenDateEditModal = (orderData, editTimeData) => {
    setOpenUpdateDatePopUp(true);
    setEditTimeDateData({
      fieldName: editTimeData?.fieldName,
      fieldValue: editTimeData?.fieldValue,
      fieldLabel: editTimeData?.fieldLabel,
      popUpTitle: editTimeData?.popUpTitle,
      fieldInitialValues: editTimeData?.initialValues,
    });
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close the edit date modal then used...
  const handleCloseDateEditModal = () => {
    setOpenUpdateDatePopUp(false);
    setEditTimeDateData({
      fieldName: "",
      fieldValue: "",
      fieldTitle: "",
      popUpTitle: "",
      fieldInitialValues: "",
      fieldPlaceholder: "",
    });
    setEditTimeOrderId("");
  };

  // when the user update the date data then used...
  const handleUpdateDateViaApi = (formValues, editTimeDetails) => {
    setIsSingleFieldUpdate(true);
    const data = new FormData();
    data.append("id", editTimeOrderId);
    if (formValues[editTimeDetails?.fieldName]) {
      data.append(
        editTimeDetails?.fieldName,
        formValues[editTimeDetails?.fieldName]
      );
      // data.append("fieldName", editTimeDetails?.fieldName);
      // data.append("fieldValue", formValues[editTimeDetails?.fieldName]);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseDateEditModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open update date and time modal then used...
  const handleOpenDateTimeEditModal = (orderData, editTimeData) => {
    setOpenUpdateDateTimePopUp(true);
    setEditTimeDateData({
      fieldName: editTimeData?.fieldName,
      fieldValue: editTimeData?.fieldValue,
      fieldLabel: editTimeData?.fieldLabel,
      popUpTitle: editTimeData?.popUpTitle,
      fieldInitialValues: editTimeData?.initialValues,
    });
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close update date and time modal then used...
  const handleCloseDateTimeEditModal = () => {
    setOpenUpdateDateTimePopUp(false);
    setEditTimeDateData({
      fieldName: "",
      fieldValue: "",
      fieldTitle: "",
      popUpTitle: "",
      fieldInitialValues: "",
      fieldPlaceholder: "",
    });
    setEditTimeOrderId("");
  };

  // when the user update the date and time  data then used...
  const handleUpdateDateTimeViaApi = (formValues, editTimeDetails) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);
    if (formValues[editTimeDetails?.fieldName]) {
      data.append(
        editTimeDetails?.fieldName,
        formValues[editTimeDetails?.fieldName]
      );
      // data.append("fieldName", editTimeDetails?.fieldName);
      // data.append("fieldValue", formValues[editTimeDetails?.fieldName]);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseDateTimeEditModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open  edit the single text field pop up then used...
  const handleOpenTextFieldEditModal = (orderData, editTimeData) => {
    setOpenUpdateTextFieldPopUp(true);
    setEditTimeDateData({
      fieldName: editTimeData?.fieldName,
      fieldValue: editTimeData?.fieldValue,
      fieldLabel: editTimeData?.fieldLabel,
      popUpTitle: editTimeData?.popUpTitle,
      fieldInitialValues: editTimeData?.initialValues,
      fieldPlaceholder: editTimeData?.fieldPlaceholder,
    });
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close  edit the single text field pop up then used...
  const handleCloseTextFieldEditModal = () => {
    setOpenUpdateTextFieldPopUp(false);
    setEditTimeDateData({
      fieldName: "",
      fieldValue: "",
      fieldTitle: "",
      popUpTitle: "",
      fieldInitialValues: "",
      fieldPlaceholder: "",
    });
    setEditTimeOrderId("");
  };

  // when the user update the date and time  data then used...
  const handleUpdateTextFieldViaApi = (formValues, editTimeDetails) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues[editTimeDetails?.fieldName]) {
      data.append(
        editTimeDetails?.fieldName,
        formValues[editTimeDetails?.fieldName]
      );
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseTextFieldEditModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the update terminal modal then used...
  const handleOpenUpdateOrderTerminalModal = (orderData) => {
    setOpenUpdateOrderTerminalPopUp(true);
    setEditTimeTerminalData(orderData?.terminal);
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close the update terminal modal then used...
  const handleCloseUpdateOrderTerminalModal = () => {
    setOpenUpdateOrderTerminalPopUp(false);
    setEditTimeTerminalData("");
    setEditTimeOrderId("");
  };

  // when the user update the terminal then used...
  const handleUpdateOrderTerminalViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.terminal) {
      data.append("terminal", formValues?.terminal);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderTerminalModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the update trader modal then used...
  const handleOpenUpdateOrderTraderModal = (orderData) => {
    setOpenUpdateOrderTraderPopUp(true);
    setEditTimeTraderData(orderData?.traderId?._id);
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close the update trader modal then used...
  const handleCloseUpdateOrderTraderModal = () => {
    setOpenUpdateOrderTraderPopUp(false);
    setEditTimeTraderData("");
    setEditTimeOrderId("");
  };

  // when the user update the trader then used...
  const handleUpdateOrderTraderViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.traderId) {
      data.append("traderId", formValues?.traderId);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderTraderModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the update port modal then used...
  const handleOpenUpdateOrderPortModal = (orderData) => {
    setOpenUpdateOrderPortPopUp(true);
    setEditTimePortData(orderData?.port?._id);
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close the update port modal then used...
  const handleCloseUpdateOrderPortModal = () => {
    setOpenUpdateOrderPortPopUp(false);
    setEditTimePortData("");
    setEditTimeOrderId("");
  };

  // when the user update the port then used...
  const handleUpdateOrderPortViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.port) {
      data.append("port", formValues?.port);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderPortModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the update proposed barge modal then used...
  const handleOpenUpdateOrderProposedBargeModal = (orderData) => {
    setOpenUpdateOrderProposedBargePopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeProposedBargeData(orderData?.proposedBarge);
    setProposedBargeTimePortData(orderData?.port?._id);
  };

  // when the user close the update port modal then used...
  const handleCloseUpdateOrderProposedBargeModal = () => {
    setOpenUpdateOrderProposedBargePopUp(false);
    setEditTimePortData("");
    setEditTimeOrderId("");
    setProposedBargeTimePortData("");
  };

  // when the user update the port then used...
  const handleUpdateOrderProposedBargeViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.proposedBarge) {
      data.append("proposedBarge", formValues?.proposedBarge);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderProposedBargeModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the CF  modal then used...
  const handleOpenUpdateOrderCFModal = (orderData) => {
    setOpenUpdateOrderCFPopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeCFData(orderData?.foreign);
  };

  // when the user close the update CF modal then used...
  const handleCloseUpdateOrderCFModal = () => {
    setOpenUpdateOrderCFPopUp(false);
    setEditTimeOrderId("");
    setEditTimeCFData("");
  };

  // when the user update the CF then used...
  const handleUpdateOrderCFViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.foreign) {
      data.append("foreign", formValues?.foreign);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderCFModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the Product  modal then used...
  const handleOpenUpdateOrderProductModal = (orderData) => {
    setOpenUpdateOrderProductPopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeProductData(orderData?.product);
  };

  // when the user close the update Product modal then used...
  const handleCloseUpdateOrderProductModal = () => {
    setOpenUpdateOrderProductPopUp(false);
    setEditTimeOrderId("");
    setEditTimeProductData("");
  };

  // when the user update the Product then used...
  const handleUpdateOrderProductViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.product) {
      data.append("product", formValues?.product);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderProductModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the NominationQTY  modal then used...
  const handleOpenUpdateOrderNominationQTYModal = (orderData) => {
    setOpenUpdateOrderNominationQTYPopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeNominationQTYData(orderData?.lowerQuantity);
  };

  // when the user close the update NominationQTY modal then used...
  const handleCloseUpdateOrderNominationQTYModal = () => {
    setOpenUpdateOrderNominationQTYPopUp(false);
    setEditTimeOrderId("");
    setEditTimeNominationQTYData("");
  };

  // when the user update the NominationQTY then used...
  const handleUpdateOrderNominationQTYViaApi = (formValues) => {
    const data = new FormData();
    data.append("id", editTimeOrderId);
    setIsSingleFieldUpdate(true);

    if (formValues?.nominatedQty) {
      data.append("nominatedQty", formValues?.nominatedQty);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderNominationQTYModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getCompletedOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  return (
    <>
      <Index.DynamicTitle title="Completed Orders" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Completed Orders
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Index.Box className="input-design-div">
                  <Index.Box className="set-date set-date-picker remove-name">
                    <Index.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <Index.DemoContainer
                        components={["SingleInputDateRangeField"]}
                        slotProps={{ textField: { size: "small" } }}
                      >
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          slotProps={{
                            shortcuts: {
                              items: shortcutsItems,
                            },
                            textField: {
                              readOnly: true,
                              size: "small",
                            },
                            actionBar: { actions: [] },
                          }}
                          onChange={(value) => handleChangeDate(value)}
                          name="allowedRange"
                          format="DD/MM/YYYY"
                        />
                      </Index.DemoContainer>
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box>
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    value={searchingText}
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchCompletedListData(e)}
                  />
                </Search>

                <Index.Box className="common-button grey-button">
                  <Index.Button
                    variant="contained"
                    // disabled={exportDetails?.length ? false : true}
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "DD-MM-YYYY"
                      );
                      NEW_EXPORT_EXCEL(
                        "/admin/export-completed-order",
                        `Complete_Order_List_${curDate}`,
                        {
                          search: searchingText,
                          to:
                            serachedDateRange?.length > 0
                              ? serachedDateRange[0].format("YYYY-MM-DD")
                              : "",
                          from:
                            serachedDateRange?.length > 0
                              ? serachedDateRange[1].format("YYYY-MM-DD")
                              : "",
                        }
                      );
                    }}
                  >
                    Export
                  </Index.Button>
                </Index.Box>
                {/* <Index.Box className="common-button blue-button res-blue-button">
                  {" "}
                  {completedOrdersList?.length && orderCheckId?.length ? (
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={cancelMultipleOrder}
                    >
                      Cancel Selected Order
                    </Index.Button>
                  ) : (
                    ""
                  )}
                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container table-max-width"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="table-design-main supply-table one-line-table invoice-table-set "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>No</Index.TableCell>
                    {/* <Index.TableCell> </Index.TableCell> */}
                    <Index.TableCell></Index.TableCell>
                    <Index.TableCell align="left">Status</Index.TableCell>

                    <Index.TableCell align="left">Vessel Name</Index.TableCell>
                    <Index.TableCell align="left">Terminal</Index.TableCell>
                    <Index.TableCell align="left">Trader Name</Index.TableCell>
                    <Index.TableCell align="left">Port</Index.TableCell>
                    <Index.TableCell align="left">
                      Proposed Barge
                    </Index.TableCell>
                    <Index.TableCell align="left">Agent</Index.TableCell>
                    <Index.TableCell align="left">C/F</Index.TableCell>
                    <Index.TableCell align="left">Product Name</Index.TableCell>
                    <Index.TableCell align="left">
                      Nominated Qty (In MT)
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Loading Planned Date
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      ETA for Vessel
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Basis Taken On
                    </Index.TableCell>
                    <Index.TableCell align="left">Remark</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={31} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {completedOrdersList?.length ? (
                      completedOrdersList
                        ?.sort(
                          (a, b) =>
                            Index.moment(b.updatedAt).valueOf() -
                            Index.moment(a.updatedAt).valueOf()
                        )
                        .map((item, index) => (
                          <Index.TableRow
                            key={item?._id + (index + 1)}
                            className={
                              item?.bunkerCall === true
                                ? "tanker-row-color"
                                : ""
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            <Index.TableCell>
                              {paginationPerPage * (currentPage - 1) +
                                (index + 1)}
                            </Index.TableCell>
                            {/* <Index.TableCell>
                              <Index.FormControlLabel
                                control={
                                  <Index.Checkbox
                                    onChange={(event) => {
                                      if (event.target.checked === true) {
                                        setOrderCheckId([
                                          ...orderCheckId,
                                          item?._id,
                                        ]);
                                      } else {
                                        setOrderCheckId(
                                          orderCheckId?.filter((ele) => {
                                            return ele !== item?._id;
                                          })
                                        );
                                      }
                                    }}
                                  />
                                }
                              />
                            </Index.TableCell> */}
                            <Index.TableCell>
                              {" "}
                              <Index.Box className="challan-icon-align">
                                <Index.Box className="d-flex">
                                  {item?.challanAvailable === false ? (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      sx={{ color: "black" }}
                                      className="challan-icon icon challan-icon-hidden"
                                    />
                                  ) : item?.challanStatus === "" ? (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      sx={{ color: "black" }}
                                      className="challan-icon"
                                    />
                                  ) : item?.challanStatus === "Approve" ? (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      color="success"
                                      className="challan-icon"
                                    />
                                  ) : (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      color="error"
                                      className="challan-icon"
                                    />
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.orderStatusForAdmin ? (
                                <Index.Typography
                                  className={` common-status-details ${
                                    item?.orderStatusForAdmin ===
                                    "Under Loading"
                                      ? `blue-status`
                                      : item?.orderStatusForAdmin === "Loaded"
                                      ? `green-status`
                                      : ""
                                  }`}
                                >
                                  {item?.orderStatusForAdmin}
                                </Index.Typography>
                              ) : (
                                "-"
                              )}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              <Index.Box className="nm-tag">
                                {item?.hasOwnProperty("challan") &&
                                item?.challan?.length ? (
                                  item?.challan.some(
                                    (element) =>
                                      element.distance !== undefined &&
                                      element.distance > 30 &&
                                      item?.hasOwnProperty("port") &&
                                      (item?.port?.portName ===
                                        "Kandla Inner" ||
                                        item?.port?.portName === "Kandla OTB")
                                  ) ? (
                                    <span className="nm-tag-span">NM</span>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </Index.Box>
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.vesselName ? item?.vesselName : "-"}
                                </Index.Box>
                                <Index.Box className="single-edit-btn-box">
                                  <Index.Button
                                    className="seperate-edit-icon"
                                    type="button"
                                    onClick={() =>
                                      handleOpenEditVesselNamePopUp(item)
                                    }
                                  >
                                    <Index.EditIcon className="edit-btn-icon" />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.terminal ? item?.terminal : "-"}
                                </Index.Box>
                                <Index.Box className="single-edit-btn-box">
                                  <Index.Button
                                    className="seperate-edit-icon"
                                    type="button"
                                    onClick={() =>
                                      handleOpenUpdateOrderTerminalModal(item)
                                    }
                                  >
                                    <Index.EditIcon className="edit-btn-icon" />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.trader
                                    ? item?.trader
                                    : item?.traderId?.traderName
                                    ? item?.traderId?.traderName
                                    : "-"}
                                </Index.Box>
                                <Index.Box className="single-edit-btn-box">
                                  <Index.Button
                                    className="seperate-edit-icon"
                                    type="button"
                                    onClick={() =>
                                      handleOpenUpdateOrderTraderModal(item)
                                    }
                                  >
                                    <Index.EditIcon className="edit-btn-icon" />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.port?.portName
                                    ? item?.port?.portName
                                    : "-"}
                                </Index.Box>
                                <Index.Box className="single-edit-btn-box">
                                  <Index.Button
                                    className="seperate-edit-icon"
                                    type="button"
                                    onClick={() =>
                                      handleOpenUpdateOrderPortModal(item)
                                    }
                                  >
                                    <Index.EditIcon className="edit-btn-icon" />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.proposedBarge?.length
                                    ? item?.proposedBarge
                                      ? Array.prototype.map
                                          .call(
                                            item?.proposedBarge,
                                            function (ele) {
                                              return ele.bargeName;
                                            }
                                          )
                                          .join(",")
                                      : ""
                                    : "-"}
                                </Index.Box>
                                {/* <Index.Box className="single-edit-btn-box">
                                  <Index.Button
                                    className="seperate-edit-icon"
                                    type="button"
                                    onClick={() =>
                                      handleOpenUpdateOrderProposedBargeModal(
                                        item
                                      )
                                    }
                                  >
                                    <Index.EditIcon className="edit-btn-icon" />
                                  </Index.Button>
                                </Index.Box> */}
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.agent ? item?.agent : "-"}
                                </Index.Box>
                                <Index.Box className="single-edit-btn-box">
                                  <Index.Box>
                                    <Index.Button
                                      className="seperate-edit-icon"
                                      type="button"
                                      onClick={() =>
                                        handleOpenTextFieldEditModal(item, {
                                          fieldName: "agent",
                                          fieldValue: item?.agent,
                                          fieldLabel: "Agent",
                                          popUpTitle: "Edit Agent",
                                          fieldPlaceholder: "Agent",
                                          initialValues: {
                                            agent: item?.agent,
                                          },
                                        })
                                      }
                                    >
                                      <Index.EditIcon className="edit-btn-icon" />
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.foreign ? item?.foreign : "-"}
                                </Index.Box>
                                <Index.Box className="single-edit-btn-box">
                                  <Index.Box>
                                    <Index.Button
                                      className="seperate-edit-icon"
                                      type="button"
                                      onClick={() =>
                                        handleOpenUpdateOrderCFModal(item)
                                      }
                                    >
                                      <Index.EditIcon className="edit-btn-icon" />
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.product ? item?.product : "-"}
                                </Index.Box>
                                <Index.Box className="single-edit-btn-box">
                                  <Index.Button
                                    className="seperate-edit-icon"
                                    type="button"
                                    onClick={() =>
                                      handleOpenUpdateOrderProductModal(item)
                                    }
                                  >
                                    <Index.EditIcon className="edit-btn-icon" />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.lowerQuantity === "0" &&
                                  item?.higherQuantity === "0"
                                    ? "-"
                                    : item?.lowerQuantity &&
                                      item?.higherQuantity != "0"
                                    ? item?.lowerQuantity +
                                      " - " +
                                      item?.higherQuantity
                                    : item?.lowerQuantity}
                                </Index.Box>
                                <Index.Box className="single-edit-btn-box">
                                  <Index.Button
                                    className="seperate-edit-icon"
                                    type="button"
                                    onClick={() =>
                                      handleOpenUpdateOrderNominationQTYModal(
                                        item
                                      )
                                    }
                                  >
                                    <Index.EditIcon className="edit-btn-icon" />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.loadingDate
                                    ? Index.moment(item?.loadingDate).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </Index.Box>
                                <Index.Box className="single-edit-btn-box">
                                  <Index.Button
                                    className="seperate-edit-icon"
                                    type="button"
                                    onClick={() =>
                                      handleOpenDateEditModal(item, {
                                        fieldName: "loadingDate",
                                        fieldValue: item?.loadingDate,
                                        fieldLabel: "Loading Planned Date",
                                        popUpTitle: "Edit Loading Planned Date",
                                        initialValues: {
                                          loadingDate: item?.loadingDate,
                                        },
                                      })
                                    }
                                  >
                                    <Index.EditIcon className="edit-btn-icon" />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.laycam
                                    ? Index.moment(item?.laycam).format(
                                        "DD/MM/YYYY HH:mm"
                                      )
                                    : "-"}
                                </Index.Box>
                                <Index.Box className="single-edit-btn-box">
                                  <Index.Button
                                    className="seperate-edit-icon"
                                    type="button"
                                    onClick={() =>
                                      handleOpenDateTimeEditModal(item, {
                                        fieldName: "laycam",
                                        fieldValue: item?.laycam,
                                        fieldLabel: "ETA for Vessel",
                                        popUpTitle: "Edit ETA for Vessel",
                                        initialValues: {
                                          laycam: item?.laycam,
                                        },
                                      })
                                    }
                                  >
                                    <Index.EditIcon className="edit-btn-icon" />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Box className="single-edit-box">
                                <Index.Box
                                  className="single-edit-text"
                                  onClick={() => {
                                    orderView(item?._id);
                                  }}
                                >
                                  {item?.basisTakenOn
                                    ? Index.moment(item?.basisTakenOn).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </Index.Box>
                                <Index.Box className="single-edit-btn-box">
                                  <Index.Button
                                    className="seperate-edit-icon"
                                    type="button"
                                    onClick={() =>
                                      handleOpenDateEditModal(item, {
                                        fieldName: "basisTakenOn",
                                        fieldValue: item?.basisTakenOn,
                                        fieldLabel: "Basis Taken On",
                                        popUpTitle: "Edit Basis Taken On",
                                        initialValues: {
                                          basisTakenOn: item?.basisTakenOn,
                                        },
                                      })
                                    }
                                  >
                                    <Index.EditIcon className="edit-btn-icon" />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              <Index.Tooltip title={item?.remark} arrow>
                                <Index.Button className="remark-text">
                                  {item?.remark ? (
                                    <>
                                      {item.remark?.length > 10 ? (
                                        <>{`${item.remark.substring(
                                          0,
                                          10
                                        )}...`}</>
                                      ) : (
                                        item.remark
                                      )}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </Index.Button>
                              </Index.Tooltip>
                            </Index.TableCell>

                            {/* <Index.TableCell
                              align="left"
                            >
                              {item?.vesselName ? item?.vesselName : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.salesId?.salesName
                                ? item?.salesId?.salesName
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.terminal ? item?.terminal : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.trader
                                ? item?.trader
                                : item?.traderId?.traderName
                                ? item?.traderId?.traderName
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.port?.portName
                                ? item?.port?.portName
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.proposedBarge?.length
                                ? item?.proposedBarge
                                  ? Array.prototype.map
                                      .call(
                                        item?.proposedBarge,
                                        function (ele) {
                                          return ele.bargeName;
                                        }
                                      )
                                      .join(",")
                                  : ""
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.transportation
                                ? item?.transportation
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.agent ? item?.agent : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.foreign ? item?.foreign : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.product ? item?.product : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.lowerQuantity === "0" &&
                              item?.higherQuantity === "0"
                                ? "-"
                                : item?.lowerQuantity &&
                                  item?.higherQuantity != "0"
                                ? item?.lowerQuantity +
                                  " - " +
                                  item?.higherQuantity
                                : item?.lowerQuantity}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.loadingDate
                                ? Index.moment(item?.loadingDate).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.supplyDate
                                ? Index.moment(item?.supplyDate).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.basisTakenOn
                                ? Index.moment(item?.basisTakenOn).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.stayHours ? item?.stayHours : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.laycam
                                ? Index.moment(item?.laycam).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.etb
                                ? Index.moment(item?.etb).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                            >
                              {item?.exmiOrDelivered
                                ? item?.exmiOrDelivered
                                : "-"}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                            >
                              {item?.layStartDate || item?.layEndDate ? (
                                <>
                                  {Index.moment(item?.layStartDate).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  -{" "}
                                  {Index.moment(item?.layEndDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.createdAt
                                ? Index.moment(item?.createdAt).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                            >
                              <Index.Box className="challan-icon-align">
                                {item?.inquiryNo ? item?.inquiryNo : "-"}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                            >
                              {item?.nominationNo ? item?.nominationNo : "-"}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                            >
                              {item?.nomination_rec_date
                                ? Index.moment(
                                    item?.nomination_rec_date
                                  ).format("DD/MM/YYYY")
                                : "-"}
                            </Index.TableCell>

                            <Index.TableCell
                              align="left"
                            >
                              <Index.Tooltip title={item?.remark} arrow>
                                <Index.Button className="remark-text">
                                  {item?.remark ? (
                                    <>
                                      {item.remark?.length > 10 ? (
                                        <>{`${item.remark.substring(
                                          0,
                                          10
                                        )}...`}</>
                                      ) : (
                                        item.remark
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Index.Button>
                              </Index.Tooltip>
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              <Index.FormControlLabel
                                control={
                                  <Index.Checkbox
                                    checked={item?.shippingBill}
                                    onChange={(event) => {
                                      orderCheckPermission(
                                        event.target.checked,
                                        item?._id,
                                        "shippingBill"
                                      );
                                    }}
                                  />
                                }
                              />
                            </Index.TableCell>

                            <Index.TableCell align="center">
                              <Index.FormControlLabel
                                control={
                                  <Index.Checkbox
                                    checked={item?.wharfage}
                                    onChange={(event) => {
                                      orderCheckPermission(
                                        event.target.checked,
                                        item?._id,
                                        "wharfage"
                                      );
                                    }}
                                  />
                                }
                              />
                            </Index.TableCell>

                            <Index.TableCell align="center">
                              <Index.FormControlLabel
                                control={
                                  <Index.Checkbox
                                    checked={item?.harbourMaster}
                                    onChange={(event) => {
                                      orderCheckPermission(
                                        event.target.checked,
                                        item?._id,
                                        "harbourMaster"
                                      );
                                    }}
                                  />
                                }
                              />
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.challanStatus ? (
                                <b>{item?.challanStatus}</b>
                              ) : (
                                "-"
                              )}
                            </Index.TableCell> */}
                          </Index.TableRow>
                        ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={31}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>

        <UpdateVesselNamePopUp
          openVesselEditPopUp={openVesselEditPopUp}
          handleCloseEditVesselNamePopUp={handleCloseEditVesselNamePopUp}
          orderId={editTimeOrderId}
          handleUpdateVesselNameViaApi={handleUpdateVesselNameViaApi}
          editTimeVesselName={editTimeVesselName}
          isSingleFieldUpdate={isSingleFieldUpdate}
        />

        <SingleUpdateDatePopUp
          openUpdateDatePopUp={openUpdateDatePopUp}
          handleCloseDateEditModal={handleCloseDateEditModal}
          editTimeOrderId={editTimeOrderId}
          handleUpdateDateViaApi={handleUpdateDateViaApi}
          editTimeDateData={editTimeDateData}
          isSingleFieldUpdate={isSingleFieldUpdate}
        />

        <SingleUpdateDateTimePopUp
          openUpdateDatePopUp={openUpdateDateTimePopUp}
          handleCloseDateEditModal={handleCloseDateTimeEditModal}
          editTimeOrderId={editTimeOrderId}
          handleUpdateDateViaApi={handleUpdateDateTimeViaApi}
          editTimeDateData={editTimeDateData}
          isSingleFieldUpdate={isSingleFieldUpdate}
        />

        <SingleUpdateTextFieldPopUp
          openUpdateDatePopUp={openUpdateTextFieldPopUp}
          handleCloseDateEditModal={handleCloseTextFieldEditModal}
          editTimeOrderId={editTimeOrderId}
          handleUpdateDateViaApi={handleUpdateTextFieldViaApi}
          editTimeDateData={editTimeDateData}
          isSingleFieldUpdate={isSingleFieldUpdate}
        />

        <UdpateOrderTerminalModal
          openUpdateDatePopUp={openUpdateOrderTerminalPopUp}
          handleCloseDateEditModal={handleCloseUpdateOrderTerminalModal}
          handleUpdateDateViaApi={handleUpdateOrderTerminalViaApi}
          editTimeDateData={editTimeDateData}
          editTimeTerminalData={editTimeTerminalData}
          isSingleFieldUpdate={isSingleFieldUpdate}
        />

        <UpdateOrderTraderModal
          openUpdateDatePopUp={openUpdateOrderTraderPopUp}
          handleCloseDateEditModal={handleCloseUpdateOrderTraderModal}
          handleUpdateDateViaApi={handleUpdateOrderTraderViaApi}
          editTimeDateData={editTimeDateData}
          editTimeTraderData={editTimeTraderData}
          isSingleFieldUpdate={isSingleFieldUpdate}
        />

        <UpdateOrderPortModal
          openUpdateDatePopUp={openUpdateOrderPortPopUp}
          handleCloseDateEditModal={handleCloseUpdateOrderPortModal}
          handleUpdateDateViaApi={handleUpdateOrderPortViaApi}
          editTimeDateData={editTimeDateData}
          editTimePortData={editTimePortData}
          isSingleFieldUpdate={isSingleFieldUpdate}
        />

        <UdpateOrderProposedBargeModal
          openUpdateDatePopUp={openUpdateOrderProposedBargePopUp}
          handleCloseDateEditModal={handleCloseUpdateOrderProposedBargeModal}
          handleUpdateDateViaApi={handleUpdateOrderProposedBargeViaApi}
          editTimeDateData={editTimeDateData}
          editTimeProposedBargeData={editTimeProposedBargeData}
          proposedBargeTimePortData={proposedBargeTimePortData}
          isSingleFieldUpdate={isSingleFieldUpdate}
        />

        <UpdateOrderCFModal
          openUpdateDatePopUp={openUpdateOrderCFPopUp}
          handleCloseDateEditModal={handleCloseUpdateOrderCFModal}
          handleUpdateDateViaApi={handleUpdateOrderCFViaApi}
          editTimeDateData={editTimeDateData}
          editTimeCFData={editTimeCFData}
          isSingleFieldUpdate={isSingleFieldUpdate}
        />

        <UpdateOrderProductModal
          openUpdateDatePopUp={openUpdateOrderProductPopUp}
          handleCloseDateEditModal={handleCloseUpdateOrderProductModal}
          handleUpdateDateViaApi={handleUpdateOrderProductViaApi}
          editTimeDateData={editTimeDateData}
          editTimeProductData={editTimeProductData}
          isSingleFieldUpdate={isSingleFieldUpdate}
        />

        <UpdateOrderNominationQTYModal
          openUpdateDatePopUp={openUpdateOrderNominationQTYPopUp}
          handleCloseDateEditModal={handleCloseUpdateOrderNominationQTYModal}
          handleUpdateDateViaApi={handleUpdateOrderNominationQTYViaApi}
          editTimeDateData={editTimeDateData}
          editTimeNominationQTYData={editTimeNominationQTYData}
          isSingleFieldUpdate={isSingleFieldUpdate}
        />
      </Index.Box>
    </>
  );
}

export default CompleteOrder;
