import React, { useState } from "react";
import Index from "../../../Index";
import {
  getAllTerminalList,
  getAllTankersForMaps,
} from "../../../../redux/admin/action";

import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//Marker icon
const myIcon = new L.Icon({
  iconUrl: Index.Png.location,
  iconRetinaUrl: Index.Png.location,
  popupAnchor: [-0, -0],
  iconSize: [20, 36],
});

function TankerMap() {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [tankerMasterList, setTankerMasterList] = React.useState([]);
  const [selectedTerminalValue, setSelectedTerminalValue] = useState("All");
  const [getAllTerminals, setGetAllTerminals] = useState([]);

  //Geo location center
  const center = [23.026734, 70.193113];

  const getTankerList = () => {
    setLoading(true);
    dispatch(getAllTankersForMaps()).then((res) => {
      if (res?.status === 200) {
        setTankerMasterList(res?.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    });
  };

  // when the get all terminals then used...
  const handleGetAllTerminals = () => {
    dispatch(getAllTerminalList())
      .then((res) => {
        if (res.status === 200) {
          setGetAllTerminals(res.data);
        } else {
          setGetAllTerminals([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setGetAllTerminals([]);
      });
  };

  // when the page is first time load then used..
  React.useEffect(() => {
    getTankerList();
    handleGetAllTerminals();
  }, []);

  // when the user change the terminal then used...
  const handleChangeTerminal = (e) => {
    const { value } = e.target;
    setSelectedTerminalValue(value);
  };

  return (
    <>
      <Index.Box className="google-map-main">
        <Index.Box className="tanker-map-terminal">
          <Index.Box className="tanker-map-terminal-dropdown">
            <Index.Select
              className="placeholder-set-text-box terminal-dropdown"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              value={selectedTerminalValue}
              MenuProps={MenuProps}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleChangeTerminal}
            >
              <Index.MenuItem value={"All"}>All</Index.MenuItem>
              {getAllTerminals?.map((items, index) => (
                <Index.MenuItem value={items} key={items?._id}>
                  {items?.name}
                </Index.MenuItem>
              ))}
            </Index.Select>
          </Index.Box>
        </Index.Box>
        <MapContainer
          center={center}
          zoom={11}
          scrollWheelZoom={true}
          maxZoom={25}
          className="geo-location-map"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {tankerMasterList?.length &&
            (selectedTerminalValue !== "All"
              ? tankerMasterList?.filter(
                  (res) =>
                    res?.orderId?.terminal === selectedTerminalValue?.name
                )
              : tankerMasterList
            )?.map((ele, index) => {
              return (
                <Marker
                  position={[
                    ele?.lat || ele?.tanker?.latitude,
                    ele?.long || ele?.tanker?.longitude,
                  ]}
                  icon={myIcon}
                  key={ele?._id}
                >
                  {ele.hasOwnProperty("tankerName") ? (
                    <>
                      <Tooltip>
                        {/* <span>{ele?.tankerName}</span> <br /> */}
                        <span>{ele?.tanker?.tankerNumber}</span>
                      </Tooltip>
                    </>
                  ) : (
                    <></>
                  )}

                  <Popup>
                    <Index.Box className="location-popup-main">
                      <Index.Typography
                        component="p"
                        className="location-popup-name"
                      >{`Number: ${ele?.tanker?.tankerNumber}`}</Index.Typography>
                      <Index.Typography
                        component="p"
                        className="location-popup-time"
                      >{`Time: ${
                        ele?.tanker?.updatedAt
                          ? Index.moment(ele?.tanker?.updatedAt).format(
                              "DD/MM/YYYY hh:mm:ss A"
                            )
                          : "-"
                      }`}</Index.Typography>
                    </Index.Box>
                  </Popup>
                </Marker>
              );
            })}
        </MapContainer>
      </Index.Box>
    </>
  );
}

export default TankerMap;
