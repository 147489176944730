import { useCallback, useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getPurchaseSelectionList,
  getUpdatedPurchaseSelectionList,
  updatePurchaseSelectionList,
} from "../../../../redux/admin/action";
import { values } from "lodash";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function SalesSelection() {
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [purchaseSelctionList, setPurchaseSelectionList] = useState([]);
  const [purchaseOptionsSelctionList, setPurchaseOptionsSelectionList] =
    useState([]);
  const [searchingText, setSearchingText] = useState("");
  const [searchedResult, setSearchedResult] = useState([]);
  const [selectedPurchaseOptions, setSelectedPurchaseOptions] = useState({
    cgst: [],
    sgst: [],
    igst: [],
    // tds: [],
    other: [],
    expense_head: [],
    company_name: id,
    expense_type: "Sales",
  });

  //   when the get purchase selection list then used...
  const handleGetPurchaseSelectionList = () => {
    // setLoading(true);
    dispatch(getPurchaseSelectionList({ companyName: id }))
      .then((res) => {
        if (res.status === 200) {
          //   setLoading(false);
          setPurchaseSelectionList(res.data);
        } else {
          //   setLoading(false);
          setPurchaseSelectionList([]);
        }
      })
      .catch((error) => {
        console.error(error);
        // setLoading(false);
        setPurchaseSelectionList([]);
      });
  };

  //   when the get updated purchase selection list then used...
  const handleGetUpdatePurchaseSelectionList = useCallback(() => {
    setLoading(true);
    dispatch(
      getUpdatedPurchaseSelectionList({
        companyName: id,
        expenseType: "Sales",
      })
    )
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.length) {
            let finalOptionList = [];
            const uniqueData = res?.data?.reduce((acc, current) => {
              const x = acc.find((item) => item.guid === current.guid);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            if (purchaseSelctionList?.length) {
              uniqueData?.map((element) => {
                const findRecord = purchaseSelctionList?.find(
                  (res) => res.guid === element?.guid
                );
                if (findRecord !== undefined) {
                  finalOptionList.push(findRecord);
                }
              });
            }
            // here set selected option list onto table side
            setPurchaseOptionsSelectionList(finalOptionList);

            const groupedByType = res?.data?.reduce((acc, item) => {
              if (!acc[item.type]) {
                acc[item.type] = [];
              }
              acc[item.type].push(item);
              return acc;
            }, {});
            setSelectedPurchaseOptions({
              cgst: groupedByType?.cgst?.map((item) => item.guid) || [],
              sgst: groupedByType?.sgst?.map((item) => item.guid) || [],
              igst: groupedByType?.igst?.map((item) => item.guid) || [],
              // tds: groupedByType?.tds?.map((item) => item.guid) || [],
              other: groupedByType?.other?.map((item) => item.guid) || [],
              expense_head:
                groupedByType?.expense_head?.map((item) => item.guid) || [],
              company_name: id,
              expense_type: "Sales",
            });
            setLoading(false);
            // setSelectedPurchaseOptions()
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [dispatch, purchaseSelctionList, id]);

  //   when the first time page load then used...
  useEffect(() => {
    handleGetPurchaseSelectionList();
    // handleGetUpdatePurchaseSelectionList();
  }, []);

  useEffect(() => {
    if (purchaseSelctionList?.length) {
      handleGetUpdatePurchaseSelectionList();
    }
  }, [purchaseSelctionList]);

  const handleSelectOptions = (event, item, optionType) => {
    const { checked } = event.target;
    const value = item?.guid ? item?.guid : "";
    setSelectedPurchaseOptions((prev) => {
      const updatedOptionType = checked
        ? [...prev[optionType], value]
        : prev[optionType].filter((item) => item !== value);

      return {
        ...prev,
        [optionType]: updatedOptionType,
      };
    });
  };
  //   When the user submit the purchase options then used...
  const handleSubmitPurchaseOptions = useCallback(() => {
    dispatch(updatePurchaseSelectionList(selectedPurchaseOptions))
      .then((res) => {
        if (res.status === 200) {
          handleGetUpdatePurchaseSelectionList();
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [selectedPurchaseOptions]);

  //   when the user go to back page
  const handleBackPage = () => {
    navigate("/admin/companies");
  };

  //   when the user search the selection options then used...
  const handleSearchPurchaseOptions = (e) => {
    const { value } = e.target;
    setSearchingText(value);
    if (value?.length <= 0) {
      setSearchedResult([]);
    }
  };

  const handleSelectSuggestionOption = (value) => {
    // setPurchaseOptionsSelectionList((prev) => [
    //   ...prev.filter((res) => res.guid !== value.guid),
    //   value,
    // ]);

    setPurchaseOptionsSelectionList((prev) => {
      const findRecord = prev.find((res) => res?.guid === value?.guid);
      if (findRecord === undefined) {
        return [...prev, value];
      } else {
        // return [...prev.filter((res) => res?.guid !== value?.guid)];
        return [...prev];
      }
    });
  };

  //   when click on search then used...
  const handleSearchedResult = useCallback(() => {
    setSearchedResult(
      searchingText?.length
        ? purchaseSelctionList?.filter((res) =>
            res.name.toLowerCase().includes(searchingText.toLowerCase())
          )
        : []
    );
  }, [purchaseSelctionList, searchingText]);

  return (
    <>
      <Index.DynamicTitle title="Purchase Selection" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  ({id})
                </Index.Typography>
              </Index.Box>
              <Search className="search pu">
                <StyledInputBase
                  placeholder="Search"
                  // key={searchingText}
                  inputProps={{ "aria-label": "search" }}
                  value={searchingText}
                  onChange={(e) => handleSearchPurchaseOptions(e)}
                  className="purchase-option-search"
                />

                {searchedResult?.length > 0 ? (
                  <>
                    <Index.Box className="searching-suggestion-box">
                      {searchedResult?.map((el) => (
                        <li onClick={() => handleSelectSuggestionOption(el)}>
                          {el?.name}
                        </li>
                      ))}
                    </Index.Box>
                  </>
                ) : (
                  <></>
                )}
              </Search>

              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={handleSearchedResult}
                >
                  Search
                </Index.Button>
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={handleBackPage}
                >
                  Back
                </Index.Button>
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={handleSubmitPurchaseOptions}
                >
                  Save
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>No</Index.TableCell>
                    <Index.TableCell>Name</Index.TableCell>
                    <Index.TableCell align="center">CGST</Index.TableCell>
                    <Index.TableCell align="center">SGST</Index.TableCell>
                    <Index.TableCell align="center">IGST</Index.TableCell>
                    {/* <Index.TableCell align="center">TDS</Index.TableCell> */}
                    <Index.TableCell align="center">
                      EXPENSE_HEAD
                    </Index.TableCell>
                    <Index.TableCell align="center">OTHER</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {purchaseOptionsSelctionList?.length ? (
                      purchaseOptionsSelctionList?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>{index + 1}</Index.TableCell>
                          <Index.TableCell>
                            {item?.name ? item?.name : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="center">
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  key={item?.guid}
                                  checked={
                                    selectedPurchaseOptions?.cgst?.find(
                                      (res) => res === item?.guid
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => {
                                    handleSelectOptions(event, item, "cgst");
                                  }}
                                />
                              }
                              // label="Shipping Bill"
                            />
                          </Index.TableCell>
                          <Index.TableCell align="center">
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  key={item?.guid}
                                  checked={
                                    selectedPurchaseOptions?.sgst?.find(
                                      (res) => res === item?.guid
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => {
                                    handleSelectOptions(event, item, "sgst");
                                  }}
                                />
                              }
                              // label="Shipping Bill"
                            />
                          </Index.TableCell>
                          <Index.TableCell align="center">
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  key={item?.guid}
                                  checked={
                                    selectedPurchaseOptions?.igst?.find(
                                      (res) => res === item?.guid
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => {
                                    handleSelectOptions(event, item, "igst");
                                  }}
                                />
                              }
                              // label="Shipping Bill"
                            />
                          </Index.TableCell>
                          {/* <Index.TableCell align="center">
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  key={item?.guid}
                                  checked={
                                    selectedPurchaseOptions?.tds?.find(
                                      (res) => res === item?.guid
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => {
                                    handleSelectOptions(event, item, "tds");
                                  }}
                                />
                              }
                            />
                          </Index.TableCell> */}

                          <Index.TableCell align="center">
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  key={item?.guid}
                                  checked={
                                    selectedPurchaseOptions?.expense_head?.find(
                                      (res) => res === item?.guid
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => {
                                    handleSelectOptions(
                                      event,
                                      item,
                                      "expense_head"
                                    );
                                  }}
                                />
                              }
                              // label="Shipping Bill"
                            />
                          </Index.TableCell>
                          <Index.TableCell align="center">
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  key={item?.guid}
                                  checked={
                                    selectedPurchaseOptions?.other?.find(
                                      (res) => res === item?.guid
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(event) => {
                                    handleSelectOptions(event, item, "other");
                                  }}
                                />
                              }
                              // label="Shipping Bill"
                            />
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                    <Index.Box className="common-button blue-button save-btn ">
                      <Index.Button
                        variant="contained"
                        type="submit"
                        className="Particular_model_submit ml-0"
                        onClick={handleSubmitPurchaseOptions}
                      >
                        Save
                      </Index.Button>
                    </Index.Box>
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
export default SalesSelection;
