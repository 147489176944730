import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import CompanyDashboard from "./CompanyDash";
import TankerDashboard from "../tanker/TankerDashboard";
import TripDashboard from "../tanker/TripDashboard";
import BunkerOrderDashboard from "../orderadd/dashboard/BunkerOrderDashboard";
import Index from "../../../Index";
import BankDashboard from "../orderadd/dashboard/BankDashboard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="tab-comman-padding py-0">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DashboardTab = () => {
  let { state } = useLocation();
  const navigate = useNavigate();
  const { adminDetails } = Index.useSelector((state) => state.AdminReducer);
  const { webPermission } = adminDetails?.permission;

  const [value, setValue] = useState(state?.value ? state?.value : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    navigate("/admin/companyDashboard");
  }, []);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            // value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="dashboard-tab"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label="Dashboard"
              className={value === 0 ? "active-dashboard-tab" : ""}
              value={0}
            />
            {webPermission?.dashboardTankerDashboard ? (
              <Tab
                key={1}
                label="Tankers dashboard"
                value={1}
                className={value === 1 ? "active-dashboard-tab" : ""}
                onChange={handleChange}
              />
            ) : (
              <></>
            )}
            {webPermission?.dashboardOrderViewTab ? (
              <>
                <Tab
                  label="Orders View"
                  key={2}
                  value={2}
                  className={value === 2 ? "active-dashboard-tab" : ""}
                  onChange={handleChange}
                />
              </>
            ) : (
              <></>
            )}
            {webPermission?.dashboardOrderDashboard ? (
              <>
                <Tab
                  label="Orders Dashboard"
                  key={3}
                  value={3}
                  className={value === 3 ? "active-dashboard-tab" : ""}
                  onChange={handleChange}
                />
              </>
            ) : (
              <></>
            )}
            {webPermission?.dashboardBankDashboard ? (
              <>
                <Tab
                  label="Bank Dashboard"
                  key={4}
                  value={4}
                  className={value === 4 ? "active-dashboard-tab" : ""}
                  onChange={handleChange}
                />
              </>
            ) : (
              <></>
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <CompanyDashboard />
        </TabPanel>
        <TabPanel key={1} value={value} index={1}>
          {webPermission?.dashboardTankerDashboard ? (
            <>
              <TankerDashboard />
            </>
          ) : (
            <></>
          )}
        </TabPanel>

        <TabPanel key={2} value={value} index={2}>
          {webPermission?.dashboardOrderViewTab ? (
            <>
              <TripDashboard />
            </>
          ) : (
            <></>
          )}
        </TabPanel>

        <TabPanel key={3} value={value} index={3}>
          {webPermission?.dashboardOrderDashboard ? (
            <>
              <BunkerOrderDashboard />
            </>
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel key={4} value={value} index={4}>
          {webPermission?.dashboardOrderDashboard ? (
            <>
              <BankDashboard />
            </>
          ) : (
            <></>
          )}
        </TabPanel>

        {/* <TabPanel value={value} index={3}>
          <BunkerOrderDashboard />
        </TabPanel> */}
      </Box>
    </>
  );
};

export default DashboardTab;
