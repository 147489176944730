import React, { useState } from "react";
import Index from "../../../Index";
import { SelectModal } from "./SelectModal";
import Header from "../../../../component/admin/defaulLayout/Header";

const company = [
  { img: Index.Png.compny4, name: "BSJPL", selected: false },
  { img: Index.Png.compny2, name: "APEX OFFSHORE LLP", selected: false },
  { img: Index.Png.compny3, name: "MALARA SEA LOGISTICS", selected: false },
];

function SelectCompny() {
  const [companyData, setCompanyData] = useState(company);
  const [companyName, setCompanyName] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = (data) => {
    if (data === "BSJPL") {
      setOpen(true);
    }
  };
  return (
    <div>
      <Header />
      <Index.Box className="choose-your-company-screen background-ed profile-content flex-center res-profile-content ">
        <Index.Box className=" h-100">
          <Index.Box className="card-center">
            <Index.Box className="select-card-main  select-compny-cad-main">
              <Index.Box className="title-main mb-41">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="select-page-title"
                >
                  Choose your company.
                </Index.Typography>
              </Index.Box>
              <Index.Box className="select-img-box-main">
                <Index.Box className="select-img-box">
                  {companyData.map((data, index) => {
                    return (
                      <Index.Box
                        className={`select-img-main ${
                          data.selected ? "active" : ""
                        }`}
                        onClick={() => {
                          const dataList = companyData.map((data, index1) => {
                            if (index === index1) {
                              return { ...data, selected: true };
                            } else {
                              return { ...data, selected: false };
                            }
                          });
                          setCompanyName(data?.name);
                          setCompanyData(dataList);
                          handleOpen(data?.name);
                        }}
                      >
                        <Index.Box className="compny-img">
                          <Index.Box className="set-select-img">
                            <img
                              src={data.img}
                              className="compny-img-inner"
                              alt="comp-icon"
                            />
                          </Index.Box>
                          <Index.Box className="select-text-set">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="select-compny-text"
                            >
                              {data.name}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="select-check-box">
                          {!data.selected ? (
                            <img
                              src={Index.Svg.righticon}
                              className="set-modal-close-icon modal-close-icon-small-grey  "
                              alt="right-icon"
                            />
                          ) : (
                            <img
                              src={Index.Svg.righticonblue}
                              className=" modal-close-icon-small-blue"
                              alt="right-icon"
                            />
                          )}
                        </Index.Box>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <SelectModal
        open={open}
        handleClose={handleClose}
        companyName={companyName}
      ></SelectModal>
    </div>
  );
}

export default SelectCompny;
