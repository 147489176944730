import React from "react";
import { useCallback, useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import * as Yup from "yup";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  assignToCraftPreventive,
  getAllBargeList,
  getCraftPreventiveList,
  removeCraftPreventive,
  getPreventiveListForViewWise,
  assignToBargeInPreventive,
  removedAssignedBargeToPreventive,
  getPreventiveListForTestPurpose,
  getAllUserWiseBargeList,
} from "../../../../redux/admin/action";
import moment from "moment";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const HtmlTooltip = Index.styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const ActionItem = Index.styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));

function CraftPreventionList() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [craftPreventionList, setCraftPreventionList] = useState([]);
  const [assignUserIds, setAssignUserIds] = useState([]);
  const [openAssigneeModel, setOpenAssigneeModel] = useState(false);

  const [bargeId, setBargeId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [searchingText, setSearchingText] = useState("");
  const [totalRecordsCount, setTotalRecordCount] = useState(0);
  const [clickedPrevnetiveId, setClickedPreventiveId] = useState(0);

  const [allBargeList, setAllBargeList] = useState([]);

  let initialValues = {
    assignToBarge: "",
  };
  if (assignUserIds) {
    initialValues = {
      assignToBarge: assignUserIds,
    };
  }

  // when the get all the barge list for assignies then used...
  const handleGetAllBargeList = () => {
    dispatch(getAllUserWiseBargeList())
      .then((res) => {
        if (res.status === 200) {
          setAllBargeList(res.data);
        } else {
          setAllBargeList([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setAllBargeList([]);
      });
  };

  // when the first time page load then used...
  const handleGetCraftPreventionList = async () => {
    setLoading(true);
    dispatch(getPreventiveListForTestPurpose())
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setTotalRecordCount(res.totalCount);
          setCurrentPage(parseInt(res?.currentPage));

          let preventionTableArray = [];

          for (let preventionResponseData of res.data) {
            const newFinalPreventiveResultArray = [];
            let preventionData = preventionResponseData;

            let preventionFinalArray = [];

            // here take bargeName and barge Id
            if (preventionData?.responsebility?.length) {
              for (let responsibilityData of preventionData?.responsebility) {
                // here take responsibilityName and responsibilityId
                if (responsibilityData?.subHead?.length) {
                  for (let subHeadData of responsibilityData?.subHead) {
                    // here take subHeadId and subHeadName
                    if (subHeadData?.jobArray?.length) {
                      for (let jobData of subHeadData?.jobArray) {
                        // here take jobID and JobName
                        preventionFinalArray.push({
                          preventiveId: preventionData?.preventiveId,
                          preventiveName: preventionData?.preventiveName,
                          assignToBarge: preventionData?.assignToBarge,
                          remark: preventionData?.remark,
                          bargeId: preventionData?.bargeId,
                          bargeName: preventionData?.bargeName,
                          responsibilityId:
                            responsibilityData?.responsibilityId,
                          responsibilityName:
                            responsibilityData?.responsibilityName,
                          subHeadId: subHeadData?.subHeadId,
                          subHeadName: subHeadData?.subHeadName,
                          jobId: jobData?.jobId,
                          jobName: jobData?.jobName,
                          monthArray: jobData?.monthArray,
                        });
                      }
                    }
                  }
                }
              }
            }

            const newidCounts = {};

            // Calculate counts for each Craft ID , responsibility and subhead
            preventionFinalArray.forEach((item) => {
              const {
                bargeId,
                bargeName,
                responsibilityId,
                subHeadId,
                responsibilityName,
                subHeadName,
                preventiveId,
                preventiveName,
              } = item;

              if (!newidCounts[preventiveId]) {
                newidCounts[preventiveId] = {
                  totalCraftCount: 1,
                  responsibilityCounts: {},
                  subHeadCounts: {},
                  bargeCounts: {},
                  preventiveCounts: {},
                };
              } else {
                newidCounts[preventiveId].totalCraftCount++;
              }

              if (!newidCounts[preventiveId].bargeCounts[preventiveId]) {
                newidCounts[preventiveId].bargeCounts[preventiveId] = {
                  count: 1,
                  name: preventiveName,
                };
              } else {
                newidCounts[preventiveId].bargeCounts[preventiveId].count++;
              }

              if (!newidCounts[preventiveId].preventiveCounts[preventiveId]) {
                newidCounts[preventiveId].preventiveCounts[preventiveId] = {
                  count: 1,
                  name: preventiveName,
                };
              } else {
                newidCounts[preventiveId].preventiveCounts[preventiveId]
                  .count++;
              }

              // Update counts for responsibility ID
              if (
                !newidCounts[preventiveId].responsibilityCounts[
                  responsibilityId
                ]
              ) {
                newidCounts[preventiveId].responsibilityCounts[
                  responsibilityId
                ] = {
                  count: 1,
                  name: responsibilityName,
                };
              } else {
                newidCounts[preventiveId].responsibilityCounts[responsibilityId]
                  .count++;
              }

              // Update counts for sub-head ID
              if (!newidCounts[preventiveId].subHeadCounts[subHeadId]) {
                newidCounts[preventiveId].subHeadCounts[subHeadId] = {
                  count: 1,
                  name: subHeadName,
                };
              } else {
                newidCounts[preventiveId].subHeadCounts[subHeadId].count++;
              }
            });

            // here is conver array instead of object
            const newFormattedPreventionCounts = Object.entries(
              newidCounts
            ).map(
              ([
                id,
                {
                  responsibilityCounts,
                  subHeadCounts,
                  totalCraftCount,
                  bargeCounts,
                  preventiveCounts,
                },
              ]) => ({
                id,
                responsibilityCounts: Object.entries(responsibilityCounts).map(
                  ([id, { name, count }]) => ({
                    id,
                    name,
                    count,
                  })
                ),
                subHeadCounts: Object.entries(subHeadCounts).map(
                  ([id, { name, count }]) => ({
                    id,
                    name,
                    count,
                  })
                ),
                bargeCounts: Object.entries(subHeadCounts).map(
                  ([id, { name, count }]) => ({
                    id,
                    name,
                    count,
                  })
                ),
                preventiveCounts: Object.entries(preventiveCounts).map(
                  ([id, { name, count }]) => ({
                    id,
                    name,
                    count,
                  })
                ),
                totalCraftCount,
              })
            );

            preventionFinalArray?.map((finalResultData) => {
              if (newFinalPreventiveResultArray?.length) {
                const newFindCraftRecord = newFinalPreventiveResultArray?.find(
                  (res) => res.preventiveId === finalResultData?.preventiveId
                );

                if (newFindCraftRecord === undefined) {
                  let findResponsibilitySpan = newFormattedPreventionCounts
                    ?.find((res) => res.id === finalResultData?.preventiveId)
                    ?.responsibilityCounts?.find(
                      (res) => res.id === finalResultData?.responsibilityId
                    );
                  let findSubHeadSpan = newFormattedPreventionCounts
                    ?.find((res) => res.id === finalResultData?.preventiveId)
                    ?.subHeadCounts?.find(
                      (res) => res.id === finalResultData?.subHeadId
                    );
                  newFinalPreventiveResultArray.push({
                    ...finalResultData,
                    responsibilitySpan: findResponsibilitySpan?.count,
                    subHeadSpan: findSubHeadSpan?.count,
                  });
                } else {
                  // here is find responsibility wise data...
                  const newFindResponsibilityWise =
                    newFinalPreventiveResultArray?.find(
                      (res) =>
                        res.preventiveId === finalResultData?.preventiveId &&
                        res.responsibilityId ===
                          finalResultData?.responsibilityId
                    );

                  if (newFindResponsibilityWise === undefined) {
                    let findResponsibilitySpan = newFormattedPreventionCounts
                      ?.find((res) => res.id === finalResultData?.preventiveId)
                      ?.responsibilityCounts?.find(
                        (res) => res.id === finalResultData?.responsibilityId
                      );
                    let findSubHeadSpan = newFormattedPreventionCounts
                      ?.find((res) => res.id === finalResultData?.preventiveId)
                      ?.subHeadCounts?.find(
                        (res) => res.id === finalResultData?.subHeadId
                      );
                    newFinalPreventiveResultArray.push({
                      ...finalResultData,
                      responsibilitySpan: findResponsibilitySpan?.count,
                      subHeadSpan: findSubHeadSpan?.count,
                    });
                  } else {
                    // here is find sub head wise....

                    const newFindResponsibilityWise =
                      newFinalPreventiveResultArray?.find(
                        (res) =>
                          res.preventiveId === finalResultData?.preventiveId &&
                          res.responsibilityId ===
                            finalResultData?.responsibilityId &&
                          res.subHeadId === finalResultData?.subHeadId
                      );

                    if (newFindResponsibilityWise === undefined) {
                      let findSubHeadSpan = newFormattedPreventionCounts
                        ?.find(
                          (res) => res.id === finalResultData?.preventiveId
                        )
                        ?.subHeadCounts?.find(
                          (res) => res.id === finalResultData?.subHeadId
                        );
                      newFinalPreventiveResultArray.push({
                        ...finalResultData,
                        subHeadSpan: findSubHeadSpan?.count,
                      });
                    } else {
                      newFinalPreventiveResultArray.push(finalResultData);
                    }
                  }
                }
              } else {
                let findResponsibilitySpan = newFormattedPreventionCounts
                  ?.find((res) => res.id === finalResultData?.preventiveId)
                  ?.responsibilityCounts?.find(
                    (res) => res.id === finalResultData?.responsibilityId
                  );
                let findSubHeadSpan = newFormattedPreventionCounts
                  ?.find((res) => res.id === finalResultData?.preventiveId)
                  ?.subHeadCounts?.find(
                    (res) => res.id === finalResultData?.subHeadId
                  );

                newFinalPreventiveResultArray.push({
                  ...finalResultData,
                  responsibilitySpan: findResponsibilitySpan?.count,
                  subHeadSpan: findSubHeadSpan?.count,
                });
              }
            });

            preventionTableArray.push({
              assignToBarge: preventionResponseData?.assignToBarge,
              remark: preventionResponseData?.remark,
              preventiveId: preventionResponseData?.preventiveId,
              preventiveName: preventionResponseData?.preventiveName,
              response: newFinalPreventiveResultArray,
            });
          }

          let finalPreventiveTableArray = [];
          if (preventionTableArray?.length) {
            for (let preventionData of preventionTableArray) {
              let preventionResponseArray = [];
              if (preventionData?.response?.length) {
                for (let preventionResponseData of preventionData?.response) {
                  let findRecordCheckSpanBasis = preventionResponseArray?.find(
                    (res) => res.hasOwnProperty("checkboxSpan")
                  );
                  if (
                    findRecordCheckSpanBasis !== undefined &&
                    findRecordCheckSpanBasis !== null
                  ) {
                    preventionResponseArray?.push(preventionResponseData);
                  } else {
                    if (
                      preventionResponseData?.hasOwnProperty(
                        "responsibilitySpan"
                      )
                    ) {
                      preventionResponseArray?.push({
                        ...preventionResponseData,
                        checkboxSpan: preventionData?.response?.length,
                      });
                    } else {
                      preventionResponseArray?.push(preventionResponseData);
                    }
                  }
                }
              }
              finalPreventiveTableArray.push({
                ...preventionData,
                response: preventionResponseArray,
              });
            }
          }

          setCraftPreventionList(finalPreventiveTableArray);
        } else {
          setLoading(false);
          setCraftPreventionList([]);
          setTotalRecordCount(0);
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        setLoading(false);
        setCraftPreventionList([]);
        setCurrentPage(1);
        setTotalRecordCount(0);
        console.error(err);
      });
  };

  // when the page first time load then used....
  useEffect(() => {
    // const values = {
    //   page: 1,
    //   search: searchingText,
    //   size: paginationPerPage,
    // };
    handleGetCraftPreventionList();

    // get all the barge list
    handleGetAllBargeList();
  }, []);

  const handleView = (row) => {
    navigate(`/admin/craftPreventive/view/${row?.preventiveId}`);
  };

  const handleClickMenu = (e, index, preventiveData) => {
    setClickedPreventiveId(preventiveData?.preventiveId);
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //Assign to model
  const handleAssigneeModelOpen = () => {
    setOpenAssigneeModel(true);
  };

  const handleEdit = (row) => {
    navigate("/admin/craftPreventive/edit-craft-preventive", {
      state: { row },
    });
  };

  const handleAssigneeModelClose = () => {
    setOpenAssigneeModel(false);
    setBargeId("");
  };

  const handlePageChange = useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
      };
      handleGetCraftPreventionList(values);
    },
    [paginationPerPage, searchingText, setCurrentPage]
  );

  const handleDeleteOpen = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleAssigneeFormSubmit = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", bargeId);
    urlencoded.append(
      "assignToBarge",
      values?.assignToBarge?.length ? JSON.stringify(values?.assignToBarge) : []
    );
    try {
      dispatch(assignToCraftPreventive(urlencoded)).then((res) => {
        if (res?.status === 200) {
          // here get the prevention listing...
          const values = {
            page: 1,
            search: searchingText,
            size: paginationPerPage,
          };
          handleGetCraftPreventionList(values);
          handleAssigneeModelClose();
        }
      });
    } catch (error) {}
  };

  // when delete record and api side pass data then used...
  const handleDeleteRecord = () => {
    dispatch(removeCraftPreventive({ id: rowId })).then((res) => {
      if (res.status === 200) {
        // here close the delete modal
        handleDeleteClose();
        // here get latest listing for preventive
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
        };
        handleGetCraftPreventionList(values);
      }
    });
  };

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = useCallback(
    (e) => {
      const { value } = e.target;
      setPaginationPerPage(value);
      const values = {
        page: 1,
        search: searchingText,
        size: value,
      };
      handleGetCraftPreventionList(values);
    },
    [searchingText, setPaginationPerPage]
  );

  // when the user search the purchase records then used...
  const handleSearchPurchaseRecord = useCallback(
    (e) => {
      const { value } = e.target;
      if (value?.length > 0) {
        setSearchingText(value);
        const values = {
          page: 1,
          search: value.trim(),
          size: paginationPerPage,
        };
        handleGetCraftPreventionList(values);
      } else {
        setSearchingText("");
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
        };
        handleGetCraftPreventionList(values);
      }
    },
    [paginationPerPage, setSearchingText]
  );

  // when the user want to get preventive logs then used...
  const handleOpenPreventivLogs = (preventiveId) => {
    navigate(`/admin/craftPreventive/preventive-logs/${preventiveId}`);
  };

  // when the user assign the barge then used...
  const handleAssignBargeToPreventive = React.useCallback(
    (e, bargeData, preventiveData) => {
      const { name, value, checked } = e.target;

      if (checked) {
        const urlencoded = new URLSearchParams();
        urlencoded.append("id", preventiveData?.preventiveId);
        urlencoded.append("assignToBarge", bargeData?._id);

        dispatch(assignToBargeInPreventive(urlencoded)).then((res) => {
          if (res?.status === 200) {
            // const value = craftPreventionList?.find(
            //   (res) => res.preventiveId === preventiveData?.preventiveId
            // );
            const updatedCraftValues = {
              ...craftPreventionList?.find(
                (res) => res.preventiveId === preventiveData?.preventiveId
              ),
              assignToBarge: res?.data?.assignToBarge,
            };

            let assienedFinalResult = [];

            craftPreventionList?.map((el) => {
              if (el?.preventiveId === preventiveData?.preventiveId) {
                assienedFinalResult.push(updatedCraftValues);
              } else {
                assienedFinalResult.push(el);
              }
            });

            setCraftPreventionList(assienedFinalResult);

            // here get the prevention listing...
            // const values = {
            //   page: 1,
            //   search: searchingText,
            //   size: paginationPerPage,
            // };
            // handleGetCraftPreventionList(values);
            // handleAssigneeModelClose();
          }
        });
      } else {
        const urlencoded = new URLSearchParams();
        urlencoded.append("id", preventiveData?.preventiveId);
        urlencoded.append("removeToBarge", bargeData?._id);

        dispatch(removedAssignedBargeToPreventive(urlencoded)).then((res) => {
          if (res?.status === 200) {
            const updatedCraftValues = {
              ...craftPreventionList?.find(
                (res) => res.preventiveId === preventiveData?.preventiveId
              ),
              assignToBarge: res?.data?.assignToBarge,
            };

            let assienedFinalResult = [];

            craftPreventionList?.map((el) => {
              if (el?.preventiveId === preventiveData?.preventiveId) {
                assienedFinalResult.push(updatedCraftValues);
              } else {
                assienedFinalResult.push(el);
              }
            });

            setCraftPreventionList(assienedFinalResult);
            // here get the prevention listing...
            // const values = {
            //   page: 1,
            //   search: searchingText,
            //   size: paginationPerPage,
            // };
            // handleGetCraftPreventionList(values);
            // handleAssigneeModelClose();
          }
        });
      }
    },
    [dispatch, setCraftPreventionList, craftPreventionList]
  );

  return (
    <>
      <Index.DynamicTitle title="Craft Preventive" />
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Craft Preventive
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                {/* <Search className="search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchPurchaseRecord(e)}
                  />
                </Search> */}

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/craftPreventive/add-craft-preventive"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Craft Preventive
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box sx={{ marginTop: "30px" }}>
            <Index.TableContainer
              component={Index.Paper}
              className="table-container cus-scroll-table"
            >
              <Index.Table
                aria-label="simple table"
                className="preventive-table"
              >
                {loading ? (
                  <Index.Box className="preventive-no-data">
                    <Index.CircularProgress color="secondary" size={20} />
                  </Index.Box>
                ) : (
                  <Index.TableBody>
                    {craftPreventionList?.length ? (
                      <>
                        {craftPreventionList?.map((craftData) => (
                          <Index.Table
                            aria-label="simple table"
                            className="preventive-table preventive-table-mar"
                          >
                            <Index.TableHead>
                              {craftData?.preventiveName?.length ? (
                                <>
                                  <Index.TableRow className="table-preventive-header ">
                                    <Index.TableCell
                                      align="center"
                                      colSpan={5 + allBargeList?.length}
                                      className="table-title table-main-header"
                                    >
                                      {craftData?.preventiveName}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </>
                              ) : (
                                <></>
                              )}
                              <Index.TableRow className="table-preventive-header">
                                <Index.TableCell align="left">
                                  Responsibility
                                </Index.TableCell>
                                <Index.TableCell align="left">
                                  Item
                                </Index.TableCell>
                                <Index.TableCell align="left">
                                  Job{" "}
                                </Index.TableCell>
                                {allBargeList?.length > 0 ? (
                                  <>
                                    {allBargeList?.map((el) => (
                                      <>
                                        <Index.TableCell align="left">
                                          {el?.bargeName}
                                        </Index.TableCell>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <></>
                                )}
                                <Index.TableCell>Remark</Index.TableCell>
                                <Index.TableCell>Action</Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>

                            <Index.TableBody>
                              {craftData?.response?.length > 0 ? (
                                <>
                                  {craftData?.response?.map((item, index) => (
                                    <Index.TableRow
                                      key={item?.bargeId + (index + 1)}
                                    >
                                      {item.hasOwnProperty(
                                        "responsibilitySpan"
                                      ) ? (
                                        <Index.TableCell
                                          rowSpan={item?.responsibilitySpan}
                                        >
                                          {item?.responsibilityName}
                                        </Index.TableCell>
                                      ) : (
                                        <></>
                                      )}
                                      {item.hasOwnProperty("subHeadSpan") ? (
                                        <Index.TableCell
                                          rowSpan={item?.subHeadSpan}
                                          className="item-col"
                                        >
                                          {item?.subHeadName}
                                        </Index.TableCell>
                                      ) : (
                                        <></>
                                      )}
                                      <Index.TableCell className="job-name-col">
                                        {item?.jobName}
                                      </Index.TableCell>
                                      {item?.hasOwnProperty("checkboxSpan") ? (
                                        <>
                                          {allBargeList?.length > 0 ? (
                                            <>
                                              {allBargeList?.map((el) => (
                                                <>
                                                  <Index.TableCell
                                                    align="left"
                                                    rowSpan={item?.checkboxSpan}
                                                  >
                                                    <Index.Checkbox
                                                      checked={
                                                        craftData?.assignToBarge?.find(
                                                          (res) =>
                                                            res?._id === el?._id
                                                        ) !== undefined
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={(e) =>
                                                        handleAssignBargeToPreventive(
                                                          e,
                                                          el,
                                                          craftData
                                                        )
                                                      }
                                                    />
                                                  </Index.TableCell>
                                                </>
                                              ))}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {item.hasOwnProperty("checkboxSpan") ? (
                                        <Index.TableCell
                                          rowSpan={item?.checkboxSpan}
                                        >
                                          {craftData?.remark}
                                        </Index.TableCell>
                                      ) : (
                                        <></>
                                      )}

                                      {item.hasOwnProperty("checkboxSpan") ? (
                                        <Index.TableCell
                                          // sx={{ display: "flex" }}
                                          rowSpan={item?.checkboxSpan}
                                        >
                                          <Index.IconButton
                                            onClick={(e) => {
                                              handleClickMenu(e, index, item);
                                            }}
                                          >
                                            <Index.MenuIcon className="action-menu-icon" />
                                          </Index.IconButton>
                                          <Index.Menu
                                            id="demo-positioned-menu"
                                            aria-labelledby="demo-positioned-button"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            elevation={2}
                                            open={Boolean(anchorEl)}
                                            onClose={handleCloseMenu}
                                            anchorOrigin={{
                                              vertical: "top",
                                              horizontal: "left",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "left",
                                            }}
                                          >
                                            <ActionItem
                                              className="action-text"
                                              onClick={() =>
                                                handleView(
                                                  craftPreventionList?.find(
                                                    (res) =>
                                                      res?.preventiveId ===
                                                      clickedPrevnetiveId
                                                  )
                                                )
                                              }
                                            >
                                              View
                                            </ActionItem>
                                            <ActionItem
                                              className="action-text"
                                              onClick={() =>
                                                handleEdit(
                                                  craftPreventionList?.find(
                                                    (res) =>
                                                      res?.preventiveId ===
                                                      clickedPrevnetiveId
                                                  )
                                                )
                                              }
                                            >
                                              Edit
                                            </ActionItem>

                                            <ActionItem
                                              className="action-text"
                                              onClick={() => {
                                                setRowId(
                                                  craftPreventionList?.find(
                                                    (res) =>
                                                      res?.preventiveId ===
                                                      clickedPrevnetiveId
                                                  )?.preventiveId
                                                );
                                                handleDeleteOpen();
                                              }}
                                            >
                                              Delete
                                            </ActionItem>

                                            {/* <ActionItem
                                              className="action-text"
                                              onClick={() => {
                                                navigate(
                                                  `/admin/craftPreventive/old-view`
                                                );
                                              }}
                                            >
                                              Old
                                            </ActionItem> */}

                                            <ActionItem
                                              className="action-text"
                                              onClick={() => {
                                                // setRowId(
                                                //   craftPreventionList?.[actionIndex]?._id
                                                // );
                                                // handleDeleteOpen();
                                                handleOpenPreventivLogs(
                                                  craftPreventionList?.find(
                                                    (res) =>
                                                      res?.preventiveId ===
                                                      clickedPrevnetiveId
                                                  )?.preventiveId
                                                );
                                              }}
                                            >
                                              Logs
                                            </ActionItem>
                                          </Index.Menu>
                                        </Index.TableCell>
                                      ) : (
                                        <></>
                                      )}
                                    </Index.TableRow>
                                  ))}
                                </>
                              ) : (
                                <Index.TableRow>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="no-data-in-list"
                                    colSpan={17}
                                    align="center"
                                  >
                                    No data available
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        ))}
                      </>
                    ) : (
                      <Index.Box className="preventive-no-data">
                        No data available
                      </Index.Box>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          {/* <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordsCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box> */}
        </Index.Box>
      </Index.Box>

      {/* Delete basis  */}
      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
        // orderDocument={"orderDocument"}
        customMessage={"Do you want to delete this craft preventive ?"}
      />

      {/* assign Dialoag basis */}
      <Index.Dialog
        open={openAssigneeModel}
        onClose={handleAssigneeModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set craft-assign-model">
          <Index.Box className="title-main">
            <Formik
              initialValues={initialValues}
              // validationSchema={assigneeValidationSchema}
              onSubmit={handleAssigneeFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main" sx={{ marginTop: "10px" }}>
                    <Index.ClearIcon
                      className="model-close-icon"
                      onClick={handleAssigneeModelClose}
                    />
                    <Index.Typography
                      variant="h6"
                      component="h6"
                      className="input-label"
                      sx={{ marginBottom: "15px" }}
                    >
                      Assign To
                    </Index.Typography>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Barges
                        </Index.Typography>
                        <Index.Box className="trader-selection">
                          <Index.Autocomplete
                            disablePortal
                            // className="invoice-select-drop"
                            id="tags-outlined"
                            options={allBargeList || []}
                            getOptionLabel={(option) => option?.bargeName}
                            value={allBargeList.filter((value) =>
                              // values.assignToBarge.includes(value._id)
                              values?.assignToBarge !== "" &&
                              values?.assignToBarge !== null &&
                              values?.assignToBarge?.length
                                ? values?.assignToBarge?.some(
                                    (o2) => value?._id === o2?._id
                                  )
                                : ""
                            )}
                            renderOption={(props, option) => (
                              <>
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  key={option?._id}
                                  {...props}
                                >
                                  {option?.bargeName}
                                </Index.Box>
                              </>
                            )}
                            onChange={(event, newValue) => {
                              // const newSelectedIds = newValue.map(
                              //   (val) => val._id
                              // );
                              setFieldValue("assignToBarge", newValue);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Select barges"
                                variant="outlined"
                              />
                            )}
                            multiple
                          />
                          {/* {touched?.assignToBarge && errors?.assignToBarge && (
                            <Index.FormHelperText error>
                              {errors?.assignToBarge}
                            </Index.FormHelperText>
                          )} */}
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                    <Index.Grid
                      item
                      xs={12}
                      sm={6}
                      md={12}
                      sx={{ marginTop: "105px" }}
                    >
                      <Index.Box
                        className="common-button blue-button flex-end save-btn"
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default CraftPreventionList;
