import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getSalesOrder,
} from "../../../../redux/admin/action";
import { TableSortLabel } from "@mui/material";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function SalesConfirmOrderList() {
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();
  const [salesOrder, setSalesOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);


  // sort start
  const [orderBy, setOrderBy] = useState("vesselName");
  const [order, setOrder] = useState("asc");
  const [finalListData, setFinalListData] = useState([]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  function getNestedPropertyValue(obj, path) {
    const parts = path?.split(".");
    let value = obj;
    for (const part of parts) {
      if (value === null || value === undefined) {
        return null;
      }

      // Handle the ? character in property names
      if (part.includes("?")) {
        const [property, condition] = part?.split("?");
        if (value[property]) {
          value = value[property];
        } else {
          return null;
        }
        if (condition === "length" && Array.isArray(value)) {
          value = value?.length;
        } else if (condition === "default" && value === null) {
          value = "Your default value here"; // Change this to the default value you want
        }
      } else {
        value = value[part];
      }
    }

    return value;
  }
  // sort start useeffect
  useEffect(() => {
    const sortedData = searchedData?.slice()?.sort((a, b) => {
      const valueA = getNestedPropertyValue(a, orderBy);
      const valueB = getNestedPropertyValue(b, orderBy);

      // Handle cases where the property is missing or contains a dash ('-').
      if (
        valueA === null ||
        valueA === undefined ||
        valueA === "-" ||
        valueA === "" ||
        valueA === "0" ||
        valueA == [] ||
        !valueA
      ) {
        return -1;
      }
      if (
        valueB === null ||
        valueB === undefined ||
        valueB === "-" ||
        valueB === "" ||
        valueB === "0" ||
        valueB == [] ||
        !valueB
      ) {
        return 1;
      }

      // Check if the orderBy is "stayHours"
      if (orderBy === "stayHours") {
        // Parse the "stayHours" value to extract the numeric part
        const stayHoursA = parseFloat(valueA);
        const stayHoursB = parseFloat(valueB);

        if (!isNaN(stayHoursA) && !isNaN(stayHoursB)) {
          return order === "asc"
            ? stayHoursA - stayHoursB
            : stayHoursB - stayHoursA;
        } else {
          return 0;
        }
      }

      const dateA = Index.moment(valueA, "DD/MM/YYYY", true);
      const dateB = Index.moment(valueB, "DD/MM/YYYY", true);

      if (!isNaN(valueA) && !isNaN(valueB)) {
        return order === "asc" ? valueA - valueB : valueB - valueA;
      } else if (dateA.isValid() && dateB.isValid()) {
        return order === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        const result =
          order === "asc"
            ? valueA?.toString()?.localeCompare(valueB?.toString(), undefined, {
                sensitivity: "base",
              })
            : valueB?.toString()?.localeCompare(valueA?.toString(), undefined, {
                sensitivity: "base",
              });
        return result;
      }
    });

    setFinalListData(sortedData);
  }, [searchedData, orderBy, order]);

  // sort end

  //get single sales list
  const getSalesOrderList = () => {
    setLoading(true);
    const data = new URLSearchParams();
    data.append("salesId", id);
    dispatch(getSalesOrder(data))
      .then((res) => {
        setSalesOrder(res?.data);
        if (res?.status === 200) {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } else {
           setTimeout(() => {
             setLoading(false);
           }, 2000);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getSalesOrderList();
  }, []);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(salesOrder);
  }, [salesOrder]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(salesOrder);
    setSearchedData(
      salesOrder?.filter(
        (item) =>
          item?.nominationNo
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.inquiryNo
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          Index.moment(item?.nomination_rec_date)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          Index.moment(item?.layStartDate)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          Index.moment(item?.laycam)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          Index.moment(item?.etb)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          Index.moment(item?.loadingDate)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          Index.moment(item?.supplyDate)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          Index.moment(item?.basisTakenOn)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          Index.moment(item?.layEndDate)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          item?.agent?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.salesPerson?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.product?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.laydate?.toString().includes(searched.toLowerCase()) ||
          item?.lowerQuantity
            ?.toString()
            .toLowerCase()
            ?.includes(searched.toLowerCase()) ||
          item?.higherQuantity
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.foreign?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.exmiOrDelivered
            ?.toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.terminal?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.trader?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.port?.portName
            ?.toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.vesselName?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.status?.toLowerCase().includes(searched.toLowerCase()) ||
          (item?.proposedBarge
            ? Array.prototype.map
                .call(item?.proposedBarge, function (ele) {
                  return ele?.bargeName?.toLowerCase();
                })
                .join(",")
            : []
          ).includes(searched.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(finalListData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = finalListData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, finalListData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };



  // Pagination calculation end

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  // const orderView = (id) => {
  //   navigate(`/admin/order/view-order/${id}`);
  // };

  return (
    <>
      <Index.DynamicTitle title="Confirmed Order" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Confirmed Order
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) =>
                      setTimeout(() => {
                        requestSearch(e.target.value);
                      }, 1000)
                    }
                  />
                </Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell> </Index.TableCell>
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "vesselName"}
                        direction={order}
                        onClick={() => handleRequestSort("vesselName")}
                      >
                        Name of Vessel
                      </TableSortLabel>
                    </Index.TableCell>

                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "salesPerson"}
                        direction={order}
                        onClick={() => handleRequestSort("salesPerson")}
                      >
                        Sales Person
                      </TableSortLabel>
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "terminal"}
                        direction={order}
                        onClick={() => handleRequestSort("terminal")}
                      >
                        Terminal
                      </TableSortLabel>
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "trader"}
                        direction={order}
                        onClick={() => handleRequestSort("trader")}
                      >
                        Trader Name
                      </TableSortLabel>
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "port?.portName"}
                        direction={order}
                        onClick={() => handleRequestSort("port?.portName")}
                      >
                        Port
                      </TableSortLabel>
                    </Index.TableCell>
                    <Index.TableCell align="left">Barge</Index.TableCell>
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "agent"}
                        direction={order}
                        onClick={() => handleRequestSort("agent")}
                      >
                        Agent
                      </TableSortLabel>
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "foreign"}
                        direction={order}
                        onClick={() => handleRequestSort("foreign")}
                      >
                        Costal/Foreign
                      </TableSortLabel>
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "product"}
                        direction={order}
                        onClick={() => handleRequestSort("product")}
                      >
                        Product
                      </TableSortLabel>
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "lowerQuantity"}
                        direction={order}
                        onClick={() => handleRequestSort("lowerQuantity")}
                      >
                        Qty(MT)
                      </TableSortLabel>
                    </Index.TableCell>
                    {/* <Index.TableCell align="left">Loading Date</Index.TableCell> */}
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "loadingDate"}
                        direction={order}
                        onClick={() => handleRequestSort("loadingDate")}
                      >
                        Loading Date
                      </TableSortLabel>
                    </Index.TableCell>
                    {/* <Index.TableCell align="left">Supply Date</Index.TableCell> */}
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "supplyDate"}
                        direction={order}
                        onClick={() => handleRequestSort("supplyDate")}
                      >
                        Supply Date
                      </TableSortLabel>
                    </Index.TableCell>
                    {/* <Index.TableCell align="left">
                      Basis Taken On
                    </Index.TableCell> */}
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "basisTakenOn"}
                        direction={order}
                        onClick={() => handleRequestSort("basisTakenOn")}
                      >
                        Basis Taken On
                      </TableSortLabel>
                    </Index.TableCell>
                    {/* <Index.TableCell align="left">Stay Hours</Index.TableCell> */}
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "stayHours"}
                        direction={order}
                        onClick={() => handleRequestSort("stayHours")}
                      >
                        Stay Hours
                      </TableSortLabel>
                    </Index.TableCell>
                    {/* <Index.TableCell align="left">ETA/Laycan</Index.TableCell> */}
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "laycam"}
                        direction={order}
                        onClick={() => handleRequestSort("laycam")}
                      >
                        ETA/Laycan
                      </TableSortLabel>
                    </Index.TableCell>
                    {/* <Index.TableCell align="left">ETB</Index.TableCell> */}
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "etb"}
                        direction={order}
                        onClick={() => handleRequestSort("etb")}
                      >
                        ETB
                      </TableSortLabel>
                    </Index.TableCell>
                    {/* <Index.TableCell align="left">
                      Exmi or Delivered
                    </Index.TableCell> */}
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "exmiOrDelivered"}
                        direction={order}
                        onClick={() => handleRequestSort("exmiOrDelivered")}
                      >
                        Exmi or Delivered
                      </TableSortLabel>
                    </Index.TableCell>
                    {/* <Index.TableCell align="left">
                      Stay/Lay Dates
                    </Index.TableCell> */}
                    <Index.TableCell align="left">
                      <TableSortLabel
                        active={orderBy === "layStartDate"}
                        direction={order}
                        onClick={() => handleRequestSort("layStartDate")}
                      >
                        Stay/Lay Dates
                      </TableSortLabel>
                    </Index.TableCell>
                    <Index.TableCell align="left">Created Date</Index.TableCell>

                    <Index.TableCell align="left">En_No</Index.TableCell>
                    <Index.TableCell align="left">Nomi. No</Index.TableCell>
                    <Index.TableCell align="left">
                      Nomi. Rec. Date
                    </Index.TableCell>
                    <Index.TableCell align="left">Remark</Index.TableCell>

                    <Index.TableCell align="left">
                      Challan Status
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={25} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((item, index) => (
                        <Index.TableRow
                          key={item?._id}
                          // onClick={() => {
                          //   orderView(item?._id);
                          // }}
                          // sx={{ cursor: "pointer" }}
                        >
                          <Index.TableCell>
                            {" "}
                            <Index.Box className="challan-icon-align">
                              <Index.Box className="d-flex">
                                {item?.challanAvailable === false ? (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    sx={{ color: "black" }}
                                    className="challan-icon icon challan-icon-hidden"
                                  />
                                ) : item?.challanStatus === "" ? (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    sx={{ color: "black" }}
                                    className="challan-icon"
                                  />
                                ) : item?.challanStatus === "Approve" ? (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    color="success"
                                    className="challan-icon"
                                  />
                                ) : (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    color="error"
                                    className="challan-icon"
                                  />
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.vesselName ? item?.vesselName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {/* {item?.salesPerson ? item?.salesPerson : "-"} */}
                            {item?.salesId?.salesName
                              ? item?.salesId?.salesName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.terminal ? item?.terminal : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.trader ? item?.trader : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.port?.portName ? item?.port?.portName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.proposedBarge?.length
                              ? item?.proposedBarge
                                ? Array.prototype.map
                                    .call(item?.proposedBarge, function (ele) {
                                      return ele.bargeName;
                                    })
                                    .join(",")
                                : ""
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.agent ? item?.agent : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.foreign ? item?.foreign : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.product ? item?.product : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.lowerQuantity === "0" &&
                            item?.higherQuantity === "0"
                              ? "-"
                              : item?.lowerQuantity &&
                                item?.higherQuantity != "0"
                              ? item?.lowerQuantity +
                                " - " +
                                item?.higherQuantity
                              : item?.lowerQuantity}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.loadingDate
                              ? Index.moment(item?.loadingDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.supplyDate
                              ? Index.moment(item?.supplyDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.basisTakenOn
                              ? Index.moment(item?.basisTakenOn).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.stayHours ? item?.stayHours : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.laycam
                              ? Index.moment(item?.laycam).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.etb
                              ? Index.moment(item?.etb).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.exmiOrDelivered
                              ? item?.exmiOrDelivered
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.layStartDate || item?.layEndDate ? (
                              <>
                                {Index.moment(item?.layStartDate).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                -{" "}
                                {Index.moment(item?.layEndDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "hh:mm:ss DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                          // onClick={() => {
                          //   orderView(item?._id);
                          // }}
                          >
                            <Index.Box className="challan-icon-align">
                              {item?.inquiryNo ? item?.inquiryNo : "-"}
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.nominationNo ? item?.nominationNo : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            {item?.nomination_rec_date
                              ? Index.moment(item?.nomination_rec_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Tooltip title={item?.remark} arrow>
                              <Index.Button className="remark-text">
                                {item?.remark ? (
                                  <>
                                    {item.remark?.length > 10 ? (
                                      <>{`${item.remark.substring(
                                        0,
                                        10
                                      )}...`}</>
                                    ) : (
                                      item.remark
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </Index.Button>
                            </Index.Tooltip>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.challanStatus ? (
                              <b>{item?.challanStatus}</b>
                            ) : (
                              "-"
                            )}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={25}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
export default SalesConfirmOrderList;
