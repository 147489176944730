import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import { useDispatch } from "react-redux";
import {
  getSlopeSludgeChallanList,
  slopeSludgeChallanStatus,
} from "../../../../../redux/admin/action";

//  style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SlopeAndSludgeChallanList = ({ id }) => {
  const dispatch = useDispatch();
  const [challanData, setChallanData] = useState([]);
  const [challanImg, setChallanImg] = useState("");
  const [loading, setLoading] = useState(false);

  //View Image Modal
  const [openImg, setOpenImg] = useState(false);
  const handleImgOpen = (img) => {
    setOpenImg(true);
    setChallanImg(img);
  };
  const handleImgClose = () => setOpenImg(false);

  // status approved
  const changeChallanStatus = (id, status) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("status", status);
    dispatch(slopeSludgeChallanStatus(urlencoded)).then((res) => {
      if (res?.status === 200) {
        getAllChallanList();
      }
    });
  };

  const getAllChallanList = () => {
    setLoading(true);
    dispatch(getSlopeSludgeChallanList(id)).then((res) => {
      if (res?.status === 200) {
         if (res?.data?.[0]?.slopeChallanImage) {
           setChallanData(res?.data);
         } else {
           setChallanData([]);
         }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setChallanData([]);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  useEffect(() => {
    getAllChallanList();
  }, []);
  return (
    <>
      <Index.TableContainer>
        <Index.Table className="challan-table challan-table-set challan-table-font challan-table-header">
          <Index.TableHead>
            <Index.TableRow>
              <Index.TableCell>Created Date</Index.TableCell>
              <Index.TableCell className="img-header-align">
                Image
              </Index.TableCell>
              <Index.TableCell>Updated Date</Index.TableCell>
              {/* <Index.TableCell>Is Editable</Index.TableCell> */}
              <Index.TableCell>Status</Index.TableCell>
              <Index.TableCell>Approve / Reject</Index.TableCell>
            </Index.TableRow>
          </Index.TableHead>
          <Index.TableBody>
            {loading ? (
              <Index.TableRow>
                <Index.TableCell
                  colSpan={24}
                  align="center"
                  className="loading-purchase"
                >
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.TableCell>
              </Index.TableRow>
            ) : challanData?.length ? (
              challanData &&
              challanData?.map((items, index) => {
                return (
                  <Index.TableRow>
                    <Index.TableCell>
                      <Index.Typography>
                        {Index.moment(items?.createdAt).format(
                          "DD/MM/YYYY h:mm:ss A"
                        )}
                      </Index.Typography>
                    </Index.TableCell>
                    <Index.TableCell style={{ padding: 0 }}>
                      <div>
                        {items?.slopeChallanImage ? (
                          <Index.Button
                            onClick={() => {
                              handleImgOpen(items?.slopeChallanImage);
                            }}
                          >
                            <img
                              src={`${
                                process.env.REACT_APP_IMAGE_URL
                              }/slopeChallan/${
                                items?.slopeChallanImage
                                  ? items?.slopeChallanImage
                                  : ""
                              }`}
                              alt="slop-challan"
                              className="challan-img-model"
                            />
                          </Index.Button>
                        ) : (
                          <Index.Box className="user-no-img">
                            No Image
                          </Index.Box>
                        )}
                        <Index.Modal
                          open={openImg}
                          onClose={handleImgClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Index.Box sx={style}>
                            <Index.ClearIcon
                              className="model-close-icon"
                              onClick={handleImgClose}
                            />
                            <Index.Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              {challanImg ? (
                                <img
                                  src={`${
                                    process.env.REACT_APP_IMAGE_URL
                                  }/slopeChallan/${
                                    challanImg ? challanImg : ""
                                  }`}
                                  alt="slop-challan"
                                  className="challan-img"
                                  // className="img-header-align"
                                />
                              ) : (
                                <Index.Box className="user-no-img">
                                  No Image
                                </Index.Box>
                              )}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Modal>
                      </div>
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Typography>
                        {Index.moment(items?.updatedAt).format(
                          "DD/MM/YYYY h:mm:ss A"
                        )}
                      </Index.Typography>
                    </Index.TableCell>

                    <Index.TableCell>
                      {items?.slopeOrderId?.slopeChallanStatus
                        ? items?.slopeOrderId?.slopeChallanStatus
                        : "-"}
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Button
                        variant="contained"
                        onClick={() =>
                          changeChallanStatus(items?._id, "Approved")
                        }
                        className="challan-status"
                        disabled={items?.statusAction}
                      >
                        Approve
                      </Index.Button>
                      <Index.Button
                        variant="contained"
                        align="left"
                        className="challan-status reject-btn-align"
                        onClick={() =>
                          changeChallanStatus(items?._id, "Rejected")
                        }
                        disabled={items?.statusAction}
                      >
                        Reject
                      </Index.Button>
                    </Index.TableCell>
                  </Index.TableRow>
                );
              })
            ) : (
              <Index.TableRow>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="no-data-in-list"
                  colSpan={24}
                  align="center"
                >
                  No data available
                </Index.TableCell>
              </Index.TableRow>
            )}
          </Index.TableBody>
        </Index.Table>
      </Index.TableContainer>
    </>
  );
};

export default SlopeAndSludgeChallanList;
