import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getAllTankerTripsList,
} from "../../../../redux/admin/action";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Export_excel } from "../ExportAll";

const HtmlTooltip = Index.styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function TankerTrips() {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [tankerMasterTripsList, setTankerMasterTripsList] = useState([]);
  const [exportDetails, setExportDetails] = useState([]);
  const [search, setSearch] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  const getTankerTrips = () => {
    setLoading(true);
    dispatch(getAllTankerTripsList()).then((res) => {
      if (res?.status === 200) {
        setTankerMasterTripsList(res?.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    });
  };

  useEffect(() => {
    getTankerTrips();
  }, []);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(
      tankerMasterTripsList?.sort((a, b) =>
        b?.updatedAt?.localeCompare(a?.updatedAt)
      )
    );
  }, [tankerMasterTripsList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(tankerMasterTripsList);
    setSearchedData(
      tankerMasterTripsList?.filter(
        (item) =>
          item?.tanker?.tankerNumber
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.orderId?.vesselName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.loadedQty
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          Index.moment(item?.orderId?.loadingDate)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    const serialNumber = (currentPage - 1) * PER_PAGE + 1;
    setCurrentRows(
      modifiedRows &&
        modifiedRows.map((ele, index) => {
          return { ...ele, sNo: serialNumber + index };
        })
    );
    // setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  // Export details
  useEffect(() => {
    const rows = searchedData?.map((item, index) => {
      return {
        "Sr. No": index + 1,
        "Tanker Number": item?.tanker?.tankerNumber
          ? item?.tanker?.tankerNumber
          : "-",
        "Order Date": item?.orderId?.loadingDate
          ? Index.moment(item?.orderId?.loadingDate).format(
              "DD/MM/YYYY hh:mm:ss"
            )
          : "-",
        "Vessel Name": item?.orderId?.vesselName
          ? item?.orderId?.vesselName
          : "-",
        "Loaded TT In": item?.loadedTTIn
          ? Index.moment(item?.loadedTTIn).format("DD/MM/YYYY hh:mm:ss")
          : "-",
        "Loading Start": item?.loadingStart
          ? Index.moment(item?.loadingStart).format("DD/MM/YYYY hh:mm:ss")
          : "-",
        "Loading Completed": item?.loadingCompleted
          ? Index.moment(item?.loadingCompleted).format("DD/MM/YYYY hh:mm:ss")
          : "-",
        "Loaded Qty": item?.loadedQty ? item?.loadedQty : "-",
        "Loaded TT Out": item?.loadedTTOut
          ? Index.moment(item?.loadedTTOut).format("DD/MM/YYYY hh:mm:ss")
          : "-",
        "Kandala In": item?.kandalaIn
          ? Index.moment(item?.kandalaIn).format("DD/MM/YYYY hh:mm:ss")
          : "-",
        "Unloading Start": item?.unloadingStart
          ? Index.moment(item?.unloadingStart).format("DD/MM/YYYY hh:mm:ss")
          : "-",
        "Unloading Completed": item?.unloadingCompleted
          ? Index.moment(item?.unloadingCompleted).format("DD/MM/YYYY hh:mm:ss")
          : "-",
        Remark: item?.remark ? item?.remark : "-",
        "Dignity Loading Completed": item?.dignityLoadingCompleted
          ? Index.moment(item?.dignityLoadingCompleted).format(
              "DD/MM/YYYY hh:mm:ss"
            )
          : "-",
        "Sounding Calculation": item?.soundingCalculation
          ? item?.soundingCalculation
          : "-",
        "kandala Out": item?.kandalaOut
          ? Index.moment(item?.kandalaOut).format("DD/MM/YYYY hh:mm:ss")
          : "-",
      };
    });
    if (rows && rows?.length > 0) {
      setExportDetails(rows);
    } else {
      setExportDetails([]);
    }
  }, [searchedData]);

  return (
    <>
      <Index.DynamicTitle title="Tanker Trips" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Tanker Trips
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value.trim());
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000);
                    }}
                  />
                </Search>
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.Button
                    variant="contained"
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "DD-MM-YYYY"
                      );
                      Export_excel(exportDetails, `Tanker Trips (${curDate})`);
                    }}
                  >
                    Export CSV
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table tanker-list "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">Sr. No</Index.TableCell>
                    <Index.TableCell align="left">
                      Tanker Number
                    </Index.TableCell>
                    <Index.TableCell align="left">Order Date</Index.TableCell>
                    <Index.TableCell align="left">Vessel Name</Index.TableCell>
                    <Index.TableCell align="left">Loaded TT In</Index.TableCell>
                    <Index.TableCell align="left">
                      Loading Start
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Loading Completed
                    </Index.TableCell>
                    <Index.TableCell align="left">Loaded Qty</Index.TableCell>
                    <Index.TableCell align="left">
                      Loaded TT Out
                    </Index.TableCell>
                    <Index.TableCell align="left">kandala In</Index.TableCell>
                    <Index.TableCell align="left">
                      Unloading Start
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Unloading Completed
                    </Index.TableCell>
                    <Index.TableCell align="left">Remark</Index.TableCell>
                    <Index.TableCell align="left">
                      Dignity Loading Completed
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Sounding Calculation
                    </Index.TableCell>
                    <Index.TableCell align="left">kandala Out</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={17} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((item, index) => (
                        <Index.TableRow
                          key={item?._id}
                          className="tanker-row-color"
                        >
                          <Index.TableCell>{item?.sNo}</Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.tanker?.tankerNumber
                              ? item?.tanker?.tankerNumber
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.orderId?.loadingDate
                              ? Index.moment(item?.orderId?.loadingDate).format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.orderId?.vesselName
                              ? item?.orderId?.vesselName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.loadedTTIn
                              ? Index.moment(item?.loadedTTIn).format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.loadingStart
                              ? Index.moment(item?.loadingStart).format(
                                  "DD/MM/YYYY hh:mm:ss "
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.loadingCompleted
                              ? Index.moment(item?.loadingCompleted).format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.loadedQty ? item?.loadedQty : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.loadedTTOut
                              ? Index.moment(item?.loadedTTOut).format(
                                  "DD/MM/YYYY hh:mm:ss "
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.kandalaIn
                              ? Index.moment(item?.kandalaIn).format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.unloadingStart
                              ? Index.moment(item?.unloadingStart).format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.unloadingCompleted
                              ? Index.moment(item?.unloadingCompleted).format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            <HtmlTooltip title={item?.remark}>
                              <Index.Box className="edit-flex-btn">
                                <Index.Box className="flex-edittext-color">
                                  {item?.remark ? (
                                    <>
                                      {item?.remark.length > 10 ? (
                                        <>
                                          {`${item?.remark.substring(
                                            0,
                                            10
                                          )}...`}
                                        </>
                                      ) : (
                                        item?.remark
                                      )}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </HtmlTooltip>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.dignityLoadingCompleted
                              ? Index.moment(
                                  item?.dignityLoadingCompleted
                                ).format("DD/MM/YYYY hh:mm:ss")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.soundingCalculation
                              ? item?.soundingCalculation
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.kandalaOut
                              ? Index.moment(item?.kandalaOut).format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )
                              : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={17}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default TankerTrips;
