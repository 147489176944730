import React, { useState, useEffect } from "react";
import "./Sales.css";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import APIService from "../../../../../redux/APIService";
import Index from "../../../../Index";
import {
  getTallyWiseMonthlyPurchaseData,
  getTallyWiseMonthlySalesData,
} from "../../../../../redux/admin/action";

const NewSalseChart = ({ month }) => {
  const dispatch = Index.useDispatch();
  const { activeCompany } = Index.useSelector((state) => state.AdminReducer);
  const [salesChartData, setSalesChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getTreeChartData = async () => {
    setLoading(true);
    if (month === "Purchase") {
      dispatch(getTallyWiseMonthlyPurchaseData(activeCompany?.tallyCompanyName))
        .then((res) => {
          if (res.status === 200) {
            let sortedData = null;

            if (Array.isArray(res?.data)) {
              sortedData = res?.data?.sort((a, b) => {
                const monthsOrder = [
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                  "Jan",
                  "Feb",
                  "Mar",
                ];
                return (
                  monthsOrder.indexOf(a?.month) - monthsOrder.indexOf(b?.month)
                );
              });
            }

            const chartData = sortedData ? sortedData : res?.data;

            setSalesChartData(
              chartData?.map((item, i) => {
                return {
                  name: item?.month,
                  previous: +parseFloat(
                    item?.previous < 0 ? item?.previous * -1 : item?.previous
                  )?.toFixed(2),
                  current: +parseFloat(
                    item?.current < 0 ? item?.current * -1 : item?.current
                  )?.toFixed(2),
                  total:
                    parseInt(
                      parseFloat(
                        item?.previous < 0
                          ? item?.previous * -1
                          : item?.previous
                      )?.toFixed(2)
                    ) +
                    parseInt(
                      parseFloat(
                        item?.current < 0 ? item?.current * -1 : item?.current
                      )?.toFixed(2)
                    ),
                };
              })
            );
            setLoading(false);
          } else {
            setSalesChartData([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setSalesChartData([]);
          setLoading(false);
        });
      // let { data } = await APIService.doGet(
      //   `/admin/month-purchase?companyId=${activeCompany?._id}`
      // );

      // if (data?.status === 200) {
      //   let sortedData = null;

      //   sortedData = data?.result?.sort((a, b) => {
      //     const monthsOrder = [
      //       "Apr",
      //       "May",
      //       "Jun",
      //       "Jul",
      //       "Aug",
      //       "Sep",
      //       "Oct",
      //       "Nov",
      //       "Dec",
      //       "Jan",
      //       "Feb",
      //       "Mar",
      //     ];
      //     return monthsOrder.indexOf(a?.month) - monthsOrder.indexOf(b?.month);
      //   });

      //   const chartData = sortedData ? sortedData : data?.result;
      //   setSalesChartData(
      //     chartData?.map((item, i) => {
      //       return {
      //         name: item?.month,
      //         previous: +item?.previous?.toFixed(2),
      //         current: +item?.current?.toFixed(2),
      //         total:
      //           parseInt(item?.previous?.toFixed(2)) +
      //           parseInt(item?.current?.toFixed(2)),
      //       };
      //     })
      //   );
      //   setLoading(false);
      // }
    } else {
      dispatch(getTallyWiseMonthlySalesData(activeCompany?.tallyCompanyName))
        .then((res) => {
          if (res.status === 200) {
            let sortedData = null;

            if (Array.isArray(res?.data)) {
              sortedData = res?.data?.sort((a, b) => {
                const monthsOrder = [
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                  "Jan",
                  "Feb",
                  "Mar",
                ];
                return (
                  monthsOrder.indexOf(a?.month) - monthsOrder.indexOf(b?.month)
                );
              });
            }

            const chartData = sortedData ? sortedData : res?.data;

            setSalesChartData(
              chartData?.map((item, i) => {
                return {
                  name: item?.month,
                  previous: +parseFloat(
                    item?.previous < 0 ? item?.previous * -1 : item?.previous
                  )?.toFixed(2),
                  current: +parseFloat(
                    item?.current < 0 ? item?.current * -1 : item?.current
                  )?.toFixed(2),
                  total:
                    parseInt(
                      parseFloat(
                        item?.previous < 0
                          ? item?.previous * -1
                          : item?.previous
                      )?.toFixed(2)
                    ) +
                    parseInt(
                      parseFloat(
                        item?.current < 0 ? item?.current * -1 : item?.current
                      )?.toFixed(2)
                    ),
                };
              })
            );
            setLoading(false);
          } else {
            setSalesChartData([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setSalesChartData([]);
          setLoading(false);
        });

      // let { data } = await APIService.doGet(
      //   `/admin/month-sales?companyId=${activeCompany?._id}`
      // );
      // if (Array.isArray(data?.result)) {
      //   sortedData = data?.result?.sort((a, b) => {
      //     const monthsOrder = [
      //       "Apr",
      //       "May",
      //       "Jun",
      //       "Jul",
      //       "Aug",
      //       "Sep",
      //       "Oct",
      //       "Nov",
      //       "Dec",
      //       "Jan",
      //       "Feb",
      //       "Mar",
      //     ];
      //     return monthsOrder.indexOf(a?.month) - monthsOrder.indexOf(b?.month);
      //   });
      // }

      // const chartData = sortedData ? sortedData : data?.result;

      // setSalesChartData(
      //   chartData?.map((item, i) => {
      //     return {
      //       name: item?.month,
      //       previous: +item?.previous?.toFixed(2),
      //       current: +item?.current?.toFixed(2),
      //       total:
      //         parseInt(item?.previous?.toFixed(2)) +
      //         parseInt(item?.current?.toFixed(2)),
      //     };
      //   })
      // );
    }
  };

  useEffect(() => {
    getTreeChartData();
  }, [month, activeCompany]);

  return (
    <>
      {loading ? (
        <>
          <Index.Box className="tree-analysis-main">
            <Index.Box className="tree-box-loader">
              <Index.CircularProgress color="secondary" size={20} />
            </Index.Box>
          </Index.Box>
        </>
      ) : (
        <>
          <div className="chartdiv">
            <ResponsiveContainer width="100%" height={215}>
              <BarChart
                width={500}
                height={300}
                data={salesChartData}
                margin={{
                  top: 0,
                  right: 3,
                  left: -15,
                  bottom: 3,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  dataKey="total"
                  tickFormatter={(total) => `${total / 10000000}Cr`}
                />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="previous"
                  fill="#8884d8"
                  activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
                <Bar
                  dataKey="current"
                  fill="#82ca9d"
                  activeBar={<Rectangle fill="gold" stroke="purple" />}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </>
  );
};

export default NewSalseChart;
