import * as Yup from "yup";
// const digitsOnly = (value) =>
//   /^\d*[\.{1}\d*]\d*$/.test(value) || value?.length === 0;

// best case
const digitsOnly = (value) =>
  /^\d*(?:\.\d*)?$/.test(value) || value?.length === 0;

const charSpace = (value) =>
  /^[^-\s][a-zA-Z\s]+$/.test(value) || value.length === 0;

const qtyTest = (value) => /^[0-9]+(\.[0-9]{1,3})?$/.test(value);

const validateStartDate = (value) => {
  if (value === null) {
    return true; // Null value is allowed
  }
  if (value.isValid()) {
    return true; // Valid date
  }
  return false; // Invalid date
};

// rate validation
export const rateValidationSchema = Yup.object().shape({
  particular: Yup.string()
    .required("Particulars is required")
    .matches(/^[^\s].*$/, "Space allowed between character")
    .matches(/^[^0-9]*$/, "Numbers are not allowed")
    .max(30, "Particulars allows maximum 30 character"),
  rate: Yup.string()
    .required("Rate is required")
    .test("rate", "Rate should have digits only", digitsOnly)
    .max(2, "Rate should have 2 digits only"),
});

// party Validation
export const partyValidationSchema = Yup.object().shape({
  partyName: Yup.string()
    .required("Party name is required")
    .matches(/^[^\s].*$/, "Space allowed between character")
    .matches(/^[^0-9]*$/, "Numbers are not allowed")
    .max(100, "Party name allows maximum 100 character"),
  gstNo: Yup.string()
    .required("GST number is required")
    .matches(
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[A-Z\d]{1})$/,
      "Invalid GST number"
    ),
  // email: Yup.string()
  //   .email("Enter the valid email")
  //   .matches(
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  //     "Enter the valid email"
  //   )
  //   .required("Email is required"),
  b2bB2c: Yup.string().required("B2B or B2C is required"),
});

// Barge / craft Validation
export const bargeValidationSchema = Yup.object().shape({
  //  bargeName: Yup.string()
  //    .required("Barge name is required")
  //    .test(
  //      "bargeName",
  //      "Barge name allows only character, and space",
  //      charSpace
  //    )
  //    .max(30, "Barge name allows maximum 30 characters"),
  bargeName: Yup.string()
    .required("Craft name is required")
    .matches(/^[^\s].*$/, "Space allowed between character"),
  bargeCategory: Yup.string().required("Craft category is required"),
  companyId: Yup.array().min(1, "Company is required"),
  portLimit: Yup.array().min(1, "Port limit is required"),
  tankManagement: Yup.array().of(
    Yup.object().shape({
      // tankName: Yup.string()
      //   .required("Tank name is required")
      //   .test(
      //     "tankName",
      //     "Tank name allows only character, and space",
      //     charSpace
      //   )
      //   .max(30, "Tank name allows maximum 30 character"),
      tankName: Yup.string()
        .required("Tank name is required")
        // .matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed")
        .max(30, "Tank name allows maximum 30 character"),
      tankType: Yup.string()
        .required("Tank type is required")
        .matches(/^[a-zA-Z\s]+$/, "Only alphabetic characters are allowed")
        .max(30, "Tank name allows maximum 30 character"),
      tankCapacity: Yup.number()
        .typeError("Tank capacity (In KL) must be a number")
        .positive("Tank capacity (In KL) must be positive")
        .integer("Tank capacity (In KL) must be an integer")
        .min(1, "Tank capacity (In KL) must be at least 1")
        .max(5000000, "Tank capacity (In KL) cannot exceed 5000000")
        .required("Tank capacity (In KL) is required"),
      tankCapacityMl: Yup.number()
        .typeError("Tank capacity (In MT) must be a number")
        .positive("Tank capacity (In MT) must be positive")
        // .integer("Tank capacity (In MT) not allowed in decimal")
        .min(1, "Tank capacity (In MT) must be at least 1")
        .max(5000000, "Tank capacity (In MT) cannot exceed 5000000")
        .required("Tank capacity (In MT) is required"),
    })
  ),
});



export const addEditCraftCrewValidationSchema = (
  isImageUploadedInitially,
  isCrewDocumentUploadedInitially
) => {
  const minimumAgeInYears = 18;
  const minimumDob = new Date();
  minimumDob.setFullYear(minimumDob.getFullYear() - minimumAgeInYears);
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobileNumber: Yup.string()
      .min(10, "Please Enter valid Mobile Number")
      .required("Mobile Number is required"),
    rank: Yup.string().required("Rank is required"),
    nationality: Yup.string().required("Nationality is required"),
    // dob: Yup.date()
    //   .nullable()
    //   .required("D.O.B is required")
    //   .typeError("Invalid Date")
    //   .min(
    //     new Date(Date.now() - 567648000000),
    //     "You must be at least 18 years"
    //   ),
    dob: Yup.date()
      .required("D.O.B is required")
      .typeError("Invalid Date"),
      // .min(minimumDob, "You must be at least 18 years"),

    ppNumber: Yup.string().required("P.P. No is required"),
    ppExpiryDate: Yup.string().required("P.P.Exp. Date is required"),
    cdcNumber: Yup.string().required("C.D.C No is required"),
    cdcExpiryDate: Yup.string().required("CDC Exp is required"),
    poj: Yup.string().required("P.O.J is required"),
    doj: Yup.string()
      .nullable()
      .required("D.O.J is required")
      .typeError("Invalid Date"),
    crewMemberImage: Yup.mixed()
      .test("required", "Please select an Image / PDF", (value) => {
        return isImageUploadedInitially || !!value;
      })
      .test("fileType", "Only Image / PDF files are allowed", (value) => {
        if (!value) return true;
        if (value instanceof File) {
          return [
            "image/jpeg",
            "image/png",
            "image/gif",
            "application/pdf",
          ].includes(value?.type);
        }
        const fileExtension = value.split(".").pop().toLowerCase();
        return ["jpg", "jpeg", "png", "gif", "pdf"].includes(fileExtension);
      }),
    crewDocuments: Yup.array()
      .of(
        Yup.mixed()
          .test("required", "Please select PDF", (value) => {
            return isCrewDocumentUploadedInitially || !!value;
          })
          .test("fileType", "Only PDF allowed", (value) => {
            if (!value) return true;
            if (value instanceof File) {
              return ["application/pdf"].includes(value.type);
            }
            const fileExtension = value.split(".").pop().toLowerCase();
            return ["pdf"].includes(fileExtension);
          })
      )
      .test("minimumDocuments", "Please select PDF", (documents) => {
        return (
          isCrewDocumentUploadedInitially ||
          (documents && documents.length >= 1)
        );
      }),
  });
};

//  Update craft validations
export const updateCraftCrewValidationSchema = Yup.object().shape({
  imoNumber: Yup.string().required("IMO number is required"),
  callSign: Yup.string().required("Call sign is required"),
});

//  Maintenance resolve remark validations
export const maintenanceResolveValidationSchema = Yup.object().shape({
  resolveRemark: Yup.string().required("Remark is required"),
});

//  upload craft document validations
export const uploadCraftDocumentValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Document title is required")
    .test("no-consecutive-spaces", "Spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string
      return !/\s{2,}/.test(value); // Check for consecutive spaces
    })
    .test("no-leading-space", "Spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string after trimming
      return value[0] !== " "; // Check if the first character is not a space
    })
    .test("no-trailing-space", "Spaces are not allowed", (value) => {
      if (!value) return true; // Allow empty string after trimming
      return value[value.length - 1] !== " "; // Check if the last character is not a space
    }),
  craftDocuments: Yup.array()
    .of(
      Yup.mixed()
        .required("Please select PDF")
        .test({
          name: "fileType",
          message: "Only PDF allowed",
          test: function (value) {
            if (!value) return true;

            const supportedFormats = ["application/pdf"];

            if (value?.name) {
              // const extension = value.name.split(".").pop().toLowerCase();
              return supportedFormats.includes(value?.type);
            } else if (typeof value === "string") {
              const extension = value.split(".").pop().toLowerCase();
              return ["pdf"].includes(extension);
            }

            return false;
          },
        })
    )
    .min(1, "Please select PDF"),
  // expireCheckBox: Yup.boolean(), // Field for the checkbox
  // expireDate: Yup.string().when("expireCheckBox", {
  //   is: (value) => value === false, // Check for checkbox value
  //   then: Yup.string().required("Expire Date is required"),
  // }),
  // remainingExpiryDays: Yup.number().when("expireCheckBox", {
  //   is: (value) => value === false, // Check for checkbox value
  //   then: Yup.number()
  //     .required("Remaining Expiry Days are required")
  //     .positive("Remaining Expiry Days must be positive")
  //     .integer("Remaining Expiry Days must be an integer"),
  // }),
  // batch: Yup.string(),
});

// upload document history validations
export const uploadCraftDocumentHistoryValidationSchema = Yup.object().shape({
  expireDate: Yup.string().when("expireCheckBox", {
    is: false,
    then: () => Yup.string().required("Expiry date is required"),
  }),
  craftDocuments: Yup.array()
    .of(
      Yup.mixed()
        .required("Please select PDF")
        .test({
          name: "fileType",
          message:
            // "Unsupported file format. Accepted only jpg, jpeg, png, pdf.",
            "Only PDF allowed ",
          test: function (value) {
            if (!value) return true;

            const supportedFormats = [
              // "image/jpg",
              // "image/jpeg",
              // "image/png",
              "application/pdf",
            ];

            if (value?.name) {
              // const extension = value.name.split(".").pop().toLowerCase();
              return supportedFormats.includes(value?.type);
            } else if (typeof value === "string") {
              const extension = value.split(".").pop().toLowerCase();
              return [
                // "jpg", "jpeg", "png",
                "pdf",
              ].includes(extension);
            }

            return false;
          },
        })
    )
    .min(1, "Please select PDF"),
  expireCheckBox: Yup.boolean(),
});

//  upload craft photo validations
export const uploadCraftPhotoValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  craftPhotos: Yup.array()
    .of(
      Yup.mixed()
        .required("Please select photo")
        .test({
          name: "fileType",
          message: "Unsupported file format",
          test: function (value) {
            if (!value) return true;

            const supportedFormats = [
              "image/jpg",
              "image/jpeg",
              "image/png",
              "application/pdf",
            ];

            if (value?.name) {
              // const extension = value.name.split(".").pop().toLowerCase();
              return supportedFormats.includes(value?.type);
            } else if (typeof value === "string") {
              const extension = value.split(".").pop().toLowerCase();
              return ["jpg", "jpeg", "png", "pdf"].includes(extension);
            }

            return false;
          },
        })
    )
    .min(1, "Please select photo"),
});

// nominator validations
export const nomintorValidationSchema = Yup.object().shape({
  nomination_rec_date: Yup.string().required("Nomination date is required"),
  shippingBill: Yup.string().required("Shipping bill is required"),
  loadingDate: Yup.string().required("Loading date is required"),
  supplyDate: Yup.string().required("Supply date is required"),
  port: Yup.string().required("Port is required"),
  trader: Yup.string()
    .required("Trader name is required")
    .test("trader", "Trader name allows only character, and space", charSpace)
    .max(30, "Trader name allows maximum 30 charaters"),
  exmiOrDelivered: Yup.string().required("Exmi or delivered is required"),
  terminal: Yup.string().required("Terminal is required"),
  vesselName: Yup.string()
    .required("Vessel name is required")
    .matches(/^[^\s].*$/, "Space allowed between character"),
  nominatedQty: Yup.string()
    .required("Nominated qty is required")
    .test(
      "nominatedQty",
      "Nominated qty accept 3 digits only after decimal",
      qtyTest
    ),
  product: Yup.string().required("Product name is required"),
  companyName: Yup.string().required("Company name is required"),
  foreign: Yup.string().required("C/F name is required"),
  remark: Yup.string()
    .required("Remark is required")
    .min(2, "Remark is too short, min 2 characters")
    .matches(/^[^\s].*$/, "Space allowed between character"),
  agent: Yup.string()
    .required("Agent name is required")
    .test("agent", "Agent name allows only character, and space", charSpace)
    .max(30, "Trader name allows maximum 30 characters"),
  laycam: Yup.string().required("ETA for Vessel is required"),
  stayHours: Yup.string().required("Stay Hours is required"),
  etb: Yup.string().required("ETB is required"),
  etd: Yup.string().required("ETD is required"),
  proposedBarge: Yup.array().min(1, "Proposed Barge is required"),
  image: Yup.mixed()
    .required("Image or pdf is required")
    .test(
      "FILE_FORMAT",
      "Allow only jpg, jpeg ,png, pdf file",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png", "pdf"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["image/jpg", "image/jpeg", "image/png", "application/pdf"].includes(
            value?.type
          ))
    )
    .test(
      "fileSize",
      "File size too large, max file size is 2.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 2048000;
        } else {
          return true;
        }
      }
    ),
  laydate: Yup.array()
    .of(
      Yup.mixed().test("isValidDate", "Laycan is required", validateStartDate)
    )
    .required("Laycan is required"),
});

// enquiry validation

export const enquiryValidationSchema = Yup.object().shape({
  // vesselName: Yup.string()
  //   .required("Vessel name is required")
  //   .matches(/^[^\s].*$/, "Space allowed between character"),
  // port: Yup.string().required("Port is required"),
  // terminal: Yup.string().required("Terminal is required"),
  // eta: Yup.string().required("ETA is required"),
  // etb: Yup.string().required("ETB is required"),
  // etd: Yup.string().required("ETD is required"),
  // remark: Yup.string()
  //   .required("Remark is required")
  //   .min(2, "Remark is too short, min 2 characters")
  //   .matches(/^[^\s].*$/, "Space allowed between character"),
  // CF: Yup.string().required("C/F is required"),
  // stayHours: Yup.string().required("Stay Hours is required"),
  laycam: Yup.array()
    .test("date-range", "Laycan From is required", (value) => {
      const [startDate, endDate] = value;
      if (!startDate && endDate) {
        return false;
      }
      return true;
    })
    .test("date-range", "Laycan To is required", (value) => {
      const [startDate, endDate] = value;
      if (startDate && !endDate) {
        return false;
      }
      return true;
    })
    .test("date-range", "Laycan is invalid", (value) => {
      const [startDate, endDate] = value;
      if (startDate && endDate && startDate.isAfter(endDate)) {
        return false;
      }
      return true;
    })

    .nullable(),

  // orderDetails: Yup.array().of(
  //   Yup.object().shape({
  //     product: Yup.string().required("Product name is required"),
  //     // .test("Product name is required", (value) => {
  //     //   console.log(value,212);

  //     // }),
  //     // quantity: Yup.string().required("Quantity is required"),
  //     quantity: Yup.string()
  //       .required("Quantity is required")
  //       .test(
  //         "quantity",
  //         "Quantity accept 3 digits only after decimal",
  //         qtyTest
  //       ),
  //   })
  // ),
});

// port validations
export const portValidationSchema = Yup.object().shape({
  portName: Yup.string()
    .required("Port name is required")
    .test(
      "portName",
      "Port name allows character with space between",
      charSpace
    )
    .max(30, "Port name allows maximum 30 charaters"),
  latitude: Yup.string().required("Latitude is required"),
  longitude: Yup.string().required("Longitude is required"),
});

//  users validations
export const userValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name is too short, min 2 characters")
    .max(16, "First name is too long , max 16 characters")
    .required("First name is required")
    .test(
      "First name",
      "First name allows character with space between",
      charSpace
    ),
  lastName: Yup.string()
    .min(2, "Last name is too short ,min 2 characters")
    .max(16, "Last name is too long ,max 16 characters")
    .required("Last name is required")
    .test(
      "Last  name",
      "Last name allows character with space between",
      charSpace
    ),
  email: Yup.string()
    .email("Enter the valid email")
    .matches(
      /^(([^<>()[\]\\.,;:-\s@#"]+(\.[^<>()[\]\\.,;:-\s@#"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter the valid email"
    )
    .required("Email is required"),
  companyId: Yup.array().min(1, "Company is required"),
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .min(10, "Please enter valid mobile number")
    .max(10, "Please enter valid mobile number"),
  permissionId: Yup.string().required("Role is required"),
  password: Yup.string().when("isEdit", {
    is: false,
    then: () =>
      Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password length should be minimum 8 characters including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
        ),
    otherwise: () => Yup.string().nullable(),
  }),

  image: Yup.mixed().test(
    "FileType",
    "Allow only jpg, jpeg, png file",
    (value) => {
      console.log(value, 302);
      if (!value) return true;
      if (!value?.name) {
        const file = value.split(".")[1];
        return ["jpeg", "jpg", "png"].includes(file); //edit
      } else {
        return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type); //add
      }
    }
  ),
});

//profile Validation schema
export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First Name is too short")
    .max(16, "First Name is too long")
    .required("First Name is required")
    .test(
      "First Name",
      "First Name allows character with space between",
      charSpace
    ),
  lastName: Yup.string()
    .min(2, "Last Name is too short")
    .max(16, "Last Name is too long")
    .required("Last Name is required")
    .test(
      "Last  Name",
      "Last Name allows character with space between",
      charSpace
    ),
  email: Yup.string()
    .email("Enter the valid Email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter the valid Email"
    )
    .required("Email is required"),
  mobileNumber: Yup.string()
    .required(" Mobile Number is required")
    .min(10, "Min 10 digit only")
    .max(10, "Max 10 digit"),
  address: Yup.string().required("Address is required"),
});

// expense add Validation
export const expenseValidationSchema = Yup.object().shape({
  expenseName: Yup.string()
    .required("Expense name is required")
    .matches(/^[^\s].*$/, "Space do not allowed"),
  expenseType: Yup.string().required("Expense type is required"),
  defaultPrice: Yup.string()
    .required("Price is required")
    .matches(/^[^\s].*$/, "Space do not allowed"),
});

//  Trader master validations
export const traderMasterValidationSchema = Yup.object().shape({
  traderName: Yup.string()
    .min(2, " Trader name is too short, min 2 characters")
    .max(30, " Trader name is too long, max 30 characters")
    .required(" Trader name is required")
    .test(
      "Trader name",
      " Trader name allows character with space between",
      charSpace
    ),
  email: Yup.string()
    .email("Enter the valid email")
    .matches(
      /^(([^<>()[\]\\.,;:-\s@#"]+(\.[^<>()[\]\\.,;:-\s@#"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter the valid email"
    )
    .required("Email is required"),
  phoneNumber: Yup.string()
    .required(" Phone number is required")
    .min(10, "Min 10 digit only")
    .max(10, "Max 10 digit"),
});

//  Tanker master validations
export const tankerMasterValidationSchema = Yup.object().shape({
  tankerName: Yup.string().required("Tanker name is required"),
  tankerNumber: Yup.string().required("Tanker number is required"),
  capacity: Yup.string().required("Tanker capacity is required"),
});

//  Role master validations
// export const roleMasterValidationSchema = Yup.object().shape({
//   roleName: Yup.string()
//     .min(2, "Role name is too short, min 2 characters")
//     .max(30, "Role name  is too long, max 30 characters")
//     .required("Role name is required"),
// });
export const roleMasterValidationSchema = Yup.object().shape({
  roleName: Yup.string()
    .matches(
      /^[A-Za-z][A-Za-z\s]*$/,
      "Role name must start with a character and contain only characters and spaces"
    )
    .min(2, "Role name is too short, min 2 characters")
    .max(30, "Role name is too long, max 30 characters")
    .required("Role name is required"),
});

//  Employee  validations
export const employeeValidationSchema = Yup.object().shape({
  employeeId: Yup.string().required("Employee name is required"),
  advanceAmount: Yup.string()
    .required("Advance amount is required")
    .matches(
      /^\d{1,5}(\.\d{1,3})?$/,
      "Invalid amount format. Maximum 5 digits and up to 3 decimal places allowed."
    ),
});

//  Dhboard data validation
export const dashboardDataValidationSchema = Yup.object().shape({
  totalSales: Yup.string().required("Total sales is required"),
  totalProfit: Yup.string().required("Total profit is required"),
  totalPurchase: Yup.string().required("Total purchase is required"),
});

//  Sales master validations
export const salesMasterValidationSchema = Yup.object().shape({
  salesName: Yup.string()
    .min(2, " Sales name is too short, min 2 characters")
    .max(30, " Sales name is too long, max 30 characters")
    .required(" Sales name is required")
    .test(
      "Sales name",
      " Sales name allows character with space between",
      charSpace
    ),
  salesEmail: Yup.string()
    .email("Enter the valid email")
    .matches(
      /^(([^<>()[\]\\.,;:-\s@#"]+(\.[^<>()[\]\\.,;:-\s@#"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter the valid email"
    )
    .required("Email is required"),
  terminal: Yup.string().required(" Terminal is required"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .min(10, "Please enter valid phone number")
    .max(10, "Please enter valid phone number"),
});

export const orderDocumentValidationSchema = Yup.object().shape({
  bunkerPermissionOption: Yup.array().test(
    "fileType",
    "Unsupported file format please upload only image or pdf files",
    (value) => {
      if (!value) return false;
      for (const file of value) {
        const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"]; // Add more valid extensions as needed
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          return false;
        }
      }
      return true;
    }
  ),
  jettyPermission: Yup.array().test(
    "fileType",
    "Unsupported file format please upload only image or pdf files",
    (value) => {
      if (!value) return false;
      for (const file of value) {
        const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"]; // Add more valid extensions as needed
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          return false;
        }
      }
      return true;
    }
  ),
  firePermission: Yup.array().test(
    "fileType",
    "Unsupported file format please upload only image or pdf files",
    (value) => {
      if (!value) return false;
      for (const file of value) {
        const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"]; // Add more valid extensions as needed
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          return false;
        }
      }
      return true;
    }
  ),
  underTaking: Yup.array().test(
    "fileType",
    "Unsupported file format please upload only image or pdf files",
    (value) => {
      if (!value) return false;
      for (const file of value) {
        const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"]; // Add more valid extensions as needed
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          return false;
        }
      }
      return true;
    }
  ),
  shippingBillDoc: Yup.array().test(
    "fileType",
    "Unsupported file format please upload only image or pdf files",
    (value) => {
      if (!value) return false;
      for (const file of value) {
        const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"]; // Add more valid extensions as needed
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          return false;
        }
      }
      return true;
    }
  ),
  shortShipment: Yup.array().test(
    "fileType",
    "Unsupported file format please upload only image or pdf files",
    (value) => {
      if (!value) return false;
      for (const file of value) {
        const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"]; // Add more valid extensions as needed
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          return false;
        }
      }
      return true;
    }
  ),
  pc: Yup.array().test(
    "fileType",
    "Unsupported file format please upload only image or pdf files",
    (value) => {
      if (!value) return false;
      for (const file of value) {
        const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"]; // Add more valid extensions as needed
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          return false;
        }
      }
      return true;
    }
  ),
  ullageReport: Yup.array().test(
    "fileType",
    "Unsupported file format please upload only image or pdf files",
    (value) => {
      if (!value) return false;
      for (const file of value) {
        const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"]; // Add more valid extensions as needed
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          return false;
        }
      }
      return true;
    }
  ),
  bdn: Yup.array().test(
    "fileType",
    "Unsupported file format please upload only image or pdf files",
    (value) => {
      if (!value) return false;
      for (const file of value) {
        const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"]; // Add more valid extensions as needed
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          return false;
        }
      }
      return true;
    }
  ),
  lop: Yup.array().test(
    "fileType",
    "Unsupported file format please upload only image or pdf files",
    (value) => {
      if (!value) return false;
      for (const file of value) {
        const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"]; // Add more valid extensions as needed
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          return false;
        }
      }
      return true;
    }
  ),
});

//  Task master validations
export const taskMasterValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Title is too short, min 2 characters")
    .max(30, "Title is too long, max 30 characters")
    .required("Title is required"),
  description: Yup.string()
    .min(2, "Description is too short, min 2 characters")
    .required("Description is required"),
  assignTo: Yup.string().required("Please select assignee"),
  status: Yup.string().required("Please select task status"),
  priority: Yup.string().required("Please select task priority"),
  startDate: Yup.string().required("Please select start date"),
  deadline: Yup.string().required("Please select deadline"),
});
// Driver Document

//  upload driver document validations
export const uploadDriverDocumentValidationSchema = Yup.object().shape({
  documentType: Yup.string().required("Document type is required"),
  expireDate: Yup.string().when(["expireCheckBox", "documentType"], {
    is: (expireCheckBox, documentType) =>
      expireCheckBox || documentType === "aadhar card",
    then: () => Yup.string().notRequired(),
    otherwise: () =>
      Yup.string().when("expireCheckBox", {
        is: false,
        then: () => Yup.string().required("Expiry Date is required"),
        otherwise: () => Yup.string().notRequired(),
      }),
  }),
  driverDocument: Yup.mixed()
    .test(
      "fileType",
      "Unsupported file format please upload only image or pdf files",
      (value) => {
        if (!value) return false;
        if (value?.type) {
          const validExtensions = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
          ];
          return validExtensions.includes(value?.type);
        } else {
          const validExtensions = ["jpg", "jpeg", "png", "pdf"];
          const fileExtension = value?.name?.split(".")?.pop()?.toLowerCase();
          return validExtensions.includes(fileExtension);
        }
      }
    )
    .required("Document is required"),
});

//Break down management

//  add breakdown validations
export const addBreakDownValidationSchema = Yup.object().shape({
  tankerId: Yup.string().required("Tanker is required"),
  breakdownRemark: Yup.string().required("Remark is required"),
});
//  add breakdown revoke validations
export const addBreakDownRevokeValidationSchema = Yup.object().shape({
  revokeRemark: Yup.string().required("Remark is required"),
});

export const tankerAssignValidationSchema = Yup.object().shape({
  newId: Yup.string().required("Please select order"),
});

// Directory validation
export const directoryValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name is too short, min 2 characters")
    .max(20, "Name is too long , max 20 characters")
    .required("Name is required")
    .test("Name", "Name allows character with space between", charSpace),
  contactNumber: Yup.string()
    .required("Contact number is required")
    .min(10, "Please enter valid contact number")
    .max(10, "Please enter valid contact number"),
  supervisor: Yup.string().required("Supervisor is required"),
  // department: Yup.string().required("Department is required"),
});

// Dashboard bank data validation
export const dashboarHDFCdBankDataSchema = Yup.object().shape({
  HDFCBankAmount: Yup.number()
    .min(0, "You can add minimum 0 amount")
    .positive("Amount must be positive")
    .typeError("Invalid amount")
    .required("Amount is required"),
});

export const dashboarKOTAKdBankDataSchema = Yup.object().shape({
  KOTAKBankAmount: Yup.number()
    .min(0, "You can add minimum 0 amount")
    .positive("Amount must be positive")
    .typeError("Invalid amount")
    .required("Amount is required"),
});

// preventive validation schema
export const preventiveValidationShcema = Yup.object().shape({
  title: Yup.string().required("Please enter Head Of Preventive"),
  preventionManagement: Yup.array().of(
    Yup.object().shape({
      responsibility: Yup.string().required("Please select Responsibility"),
      subHeadOfPreventive: Yup.string().required(
        "Please select Sub Head Of Preventive"
      ),
      jobName: Yup.string().required("Please enter Job Name"),
    })
  ),
});

export const documentRemarkValidationSchema = Yup.object().shape({
  status: Yup.string().required("Remark is required"),
});

export const addPreventiveSubHeadValidationSchema = Yup.object().shape({
  subHead: Yup.string().required("Sub Head is required"),
});

export const companyValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Company is required"),
  tallyCompanyName: Yup.string().required("Tally Company is required"),
  name: Yup.string().required("Short Company Name is required"),
});

export const tugOrderValidationSchema = Yup.object().shape({
  other: Yup.string().when("purpose", {
    is: (value) => value === "Other",
    then: (schema) =>
      schema
        .max(50, "Must be 50 characters or less")
        .required("Other is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
