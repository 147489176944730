import { DateTimePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";
import dayjs from "dayjs";
import { terminalData } from "../../../../../config/CommonTerminalItems";
import { useEffect, useState } from "react";
import {
  getFreshWaterBargeList,
} from "../../../../../redux/admin/action";
import Index from "../../../../Index";

const waterTypeList = [
  { id: 1, type: "Freshwater" },
  { id: 2, type: "Mix" },
  { id: 3, type: "RO" },
];

function WaterSupplyUpdateWaterBargeMdoel({
  openUpdateDatePopUp,
  handleCloseDateEditModal,
  handleUpdateDateViaApi,
  editTimeWaterBargeData,
  isSingleFieldUpdate,
}) {
  const dispatch = Index.useDispatch();
  const [freshWateredBarges, setFreshWateredBarges] = useState([]);

  const initialValues = {
    waterBarge: editTimeWaterBargeData,
  };

  // when the get the fresh water barges then used...
  const handleGetFreshWaterBargeList = () => {
    dispatch(getFreshWaterBargeList())
      .then((res) => {
        if (res.status === 200) {
          setFreshWateredBarges(res.data);
        } else {
          setFreshWateredBarges([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setFreshWateredBarges([]);
      });
  };

  //getAll port and barge
  useEffect(() => {
    handleGetFreshWaterBargeList();
  }, []);

  return (
    <>
      <Index.Dialog
        open={openUpdateDatePopUp}
        onClose={handleCloseDateEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  modal-update-main">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // validationSchema={vesselValidationSchema}
              onSubmit={(values) => handleUpdateDateViaApi(values)}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        Edit Water Barge Name
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleCloseDateEditModal}
                      />
                    </Index.Box>
                    <Index.Box className="update-model-main-box-set">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Water Barge Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="waterBarge"
                          value={values?.waterBarge}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values?.waterBarge}
                          onChange={handleChange}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select Water Barge Name
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {freshWateredBarges?.map((items, index) => (
                            <Index.MenuItem value={items?._id} key={items?._id}>
                              {items?.bargeName}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>

                      <Index.Box className="common-button blue-button flex-end save-btn  modal-update-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                          disabled={isSingleFieldUpdate ? true : false}
                        >
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default WaterSupplyUpdateWaterBargeMdoel;
