import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import {
  getAllHeadPreventiveWiseReport,
  getCraftPreventiveList,
  getAllBargeList,
  getAllUserWiseBargeList,
} from "../../../../redux/admin/action";
import moment from "moment";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ImageIcon from "@mui/icons-material/Image";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

function CraftMaintenancePreventionList() {
  const dispatch = Index.useDispatch();

  const [craftPreventionList, setCraftPreventionList] = useState([]);
  const [loading, setLoading] = useState(false);

  // date basis change
  let today = new Date();
  let currentYear = today.getFullYear();

  // all Head of preventive related states
  const [allHeadOfPrventiveList, setAllHeadOfPreventiveList] = useState([]);
  const [selectedHeadOfPreventive, setSelectedHeadOfPreventive] =
    useState("select-preventive");

  //  craft related states
  const [allCraftList, setAllCraftList] = useState([]);
  const [selectedCraftName, setSelectedCraftName] =
    useState("select-craft-name");

  // year related states
  const [yearSelectionOptions, setYearSelectionOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleChangePreventive = (event) => {
    setSelectedHeadOfPreventive(event.target.value);
    let values;
    if (
      event.target.value?.length &&
      event.target.value !== "select-preventive" &&
      selectedYear &&
      selectedCraftName === "select-craft-name"
    ) {
      //  year and preventive
      values = {
        year: selectedYear,
        preventiveId: event.target.value,
      };
    } else if (
      event.target.value?.length &&
      event.target.value !== "select-preventive" &&
      selectedYear &&
      selectedCraftName !== "select-craft-name"
    ) {
      // year , preventive and craft
      values = {
        year: selectedYear,
        preventiveId: event.target.value,
        bargeId: selectedCraftName,
      };
    } else if (
      event.target.value?.length &&
      event.target.value === "select-preventive" &&
      selectedYear &&
      selectedCraftName === "select-craft-name"
    ) {
      // default and year
      values = {
        year: selectedYear,
      };
    } else if (
      event.target.value?.length &&
      event.target.value === "select-preventive" &&
      selectedYear &&
      selectedCraftName !== "select-craft-name"
    ) {
      // default , year and craft
      values = {
        year: selectedYear,
        bargeId: selectedCraftName,
      };
    } else {
      // default
      values = {
        year: selectedYear,
      };
    }
    handleGetResponsibilityWisePreventionData(values);
  };

  const handleGetResponsibilityWisePreventionData = (values) => {
    setLoading(true);
    dispatch(getAllHeadPreventiveWiseReport(values))
      .then((res) => {
        if (res.status === 200) {
          let preventionTableArray = [];

          for (let preventionResponseData of res.data) {
            const newFinalPreventiveResultArray = [];

            let preventionResponseArray = [];
            for (let preventiveOldData of preventionResponseData?.response) {
              preventionResponseArray.push(preventiveOldData);
            }

            if (preventionResponseArray?.length) {
              let preventionFinalArray = [];

              for (let preventionData of preventionResponseArray) {
                // here take bargeName and barge Id
                if (preventionData?.responsebility?.length) {
                  for (let responsibilityData of preventionData?.responsebility) {
                    // here take responsibilityName and responsibilityId
                    if (responsibilityData?.subHead?.length) {
                      for (let subHeadData of responsibilityData?.subHead) {
                        // here take subHeadId and subHeadName
                        if (subHeadData?.jobArray?.length) {
                          for (let jobData of subHeadData?.jobArray) {
                            // here take jobID and JobName
                            preventionFinalArray.push({
                              bargeId: preventionData?.bargeId,
                              bargeName: preventionData?.bargeName,
                              responsibilityId:
                                responsibilityData?.responsibilityId,
                              responsibilityName:
                                responsibilityData?.responsibilityName,
                              subHeadId: subHeadData?.subHeadId,
                              subHeadName: subHeadData?.subHeadName,
                              jobId: jobData?.jobId,
                              jobName: jobData?.jobName,
                              monthArray: jobData?.monthArray,
                            });
                          }
                        }
                      }
                    }
                  }
                }
              }

              const newidCounts = {};

              // Calculate counts for each Craft ID , responsibility and subhead
              preventionFinalArray.forEach((item) => {
                const {
                  bargeId,
                  bargeName,
                  responsibilityId,
                  subHeadId,
                  responsibilityName,
                  subHeadName,
                } = item;

                if (!newidCounts[bargeId]) {
                  newidCounts[bargeId] = {
                    totalCraftCount: 1,
                    responsibilityCounts: {},
                    subHeadCounts: {},
                    bargeCounts: {},
                  };
                } else {
                  newidCounts[bargeId].totalCraftCount++;
                }

                if (!newidCounts[bargeId].bargeCounts[bargeId]) {
                  newidCounts[bargeId].bargeCounts[bargeId] = {
                    count: 1,
                    name: bargeName,
                  };
                } else {
                  newidCounts[bargeId].bargeCounts[bargeId].count++;
                }

                // Update counts for responsibility ID
                if (
                  !newidCounts[bargeId].responsibilityCounts[responsibilityId]
                ) {
                  newidCounts[bargeId].responsibilityCounts[responsibilityId] =
                    {
                      count: 1,
                      name: responsibilityName,
                    };
                } else {
                  newidCounts[bargeId].responsibilityCounts[responsibilityId]
                    .count++;
                }

                // Update counts for sub-head ID
                if (!newidCounts[bargeId].subHeadCounts[subHeadId]) {
                  newidCounts[bargeId].subHeadCounts[subHeadId] = {
                    count: 1,
                    name: subHeadName,
                  };
                } else {
                  newidCounts[bargeId].subHeadCounts[subHeadId].count++;
                }
              });

              // here is conver array instead of object
              const newFormattedPreventionCounts = Object.entries(
                newidCounts
              ).map(
                ([
                  id,
                  {
                    responsibilityCounts,
                    subHeadCounts,
                    totalCraftCount,
                    bargeCounts,
                  },
                ]) => ({
                  id,
                  responsibilityCounts: Object.entries(
                    responsibilityCounts
                  ).map(([id, { name, count }]) => ({
                    id,
                    name,
                    count,
                  })),
                  subHeadCounts: Object.entries(subHeadCounts).map(
                    ([id, { name, count }]) => ({
                      id,
                      name,
                      count,
                    })
                  ),
                  bargeCounts: Object.entries(subHeadCounts).map(
                    ([id, { name, count }]) => ({
                      id,
                      name,
                      count,
                    })
                  ),
                  totalCraftCount,
                })
              );

              preventionFinalArray?.map((finalResultData) => {
                if (newFinalPreventiveResultArray?.length) {
                  const newFindCraftRecord =
                    newFinalPreventiveResultArray?.find(
                      (res) => res.bargeId === finalResultData?.bargeId
                    );

                  if (newFindCraftRecord === undefined) {
                    let craftSpanCount = newFormattedPreventionCounts?.find(
                      (res) => res.id === finalResultData?.bargeId
                    );
                    let findResponsibilitySpan = newFormattedPreventionCounts
                      ?.find((res) => res.id === finalResultData?.bargeId)
                      ?.responsibilityCounts?.find(
                        (res) => res.id === finalResultData?.responsibilityId
                      );
                    let findSubHeadSpan = newFormattedPreventionCounts
                      ?.find((res) => res.id === finalResultData?.bargeId)
                      ?.subHeadCounts?.find(
                        (res) => res.id === finalResultData?.subHeadId
                      );
                    newFinalPreventiveResultArray.push({
                      ...finalResultData,
                      craftSpanCount: craftSpanCount?.totalCraftCount,
                      responsibilitySpan: findResponsibilitySpan?.count,
                      subHeadSpan: findSubHeadSpan?.count,
                    });
                  } else {
                    // here is find responsibility wise data...
                    const newFindResponsibilityWise =
                      newFinalPreventiveResultArray?.find(
                        (res) =>
                          res.bargeId === finalResultData?.bargeId &&
                          res.responsibilityId ===
                            finalResultData?.responsibilityId
                      );

                    if (newFindResponsibilityWise === undefined) {
                      let findResponsibilitySpan = newFormattedPreventionCounts
                        ?.find((res) => res.id === finalResultData?.bargeId)
                        ?.responsibilityCounts?.find(
                          (res) => res.id === finalResultData?.responsibilityId
                        );
                      let findSubHeadSpan = newFormattedPreventionCounts
                        ?.find((res) => res.id === finalResultData?.bargeId)
                        ?.subHeadCounts?.find(
                          (res) => res.id === finalResultData?.subHeadId
                        );
                      newFinalPreventiveResultArray.push({
                        ...finalResultData,
                        responsibilitySpan: findResponsibilitySpan?.count,
                        subHeadSpan: findSubHeadSpan?.count,
                      });
                    } else {
                      // here is find sub head wise....

                      const newFindResponsibilityWise =
                        newFinalPreventiveResultArray?.find(
                          (res) =>
                            res.bargeId === finalResultData?.bargeId &&
                            res.responsibilityId ===
                              finalResultData?.responsibilityId &&
                            res.subHeadId === finalResultData?.subHeadId
                        );

                      if (newFindResponsibilityWise === undefined) {
                        let findSubHeadSpan = newFormattedPreventionCounts
                          ?.find((res) => res.id === finalResultData?.bargeId)
                          ?.subHeadCounts?.find(
                            (res) => res.id === finalResultData?.subHeadId
                          );
                        newFinalPreventiveResultArray.push({
                          ...finalResultData,
                          subHeadSpan: findSubHeadSpan?.count,
                        });
                      } else {
                        newFinalPreventiveResultArray.push(finalResultData);
                      }
                    }
                  }
                } else {
                  let craftSpanCount = newFormattedPreventionCounts?.find(
                    (res) => res.id === finalResultData?.bargeId
                  );
                  let findResponsibilitySpan = newFormattedPreventionCounts
                    ?.find((res) => res.id === finalResultData?.bargeId)
                    ?.responsibilityCounts?.find(
                      (res) => res.id === finalResultData?.responsibilityId
                    );
                  let findSubHeadSpan = newFormattedPreventionCounts
                    ?.find((res) => res.id === finalResultData?.bargeId)
                    ?.subHeadCounts?.find(
                      (res) => res.id === finalResultData?.subHeadId
                    );
                  newFinalPreventiveResultArray.push({
                    ...finalResultData,
                    craftSpanCount: craftSpanCount?.totalCraftCount,
                    responsibilitySpan: findResponsibilitySpan?.count,
                    subHeadSpan: findSubHeadSpan?.count,
                  });
                }
              });
            }

            preventionTableArray.push({
              preventiveId: preventionResponseData?.preventiveId,
              preventiveName: preventionResponseData?.preventiveName,
              response: newFinalPreventiveResultArray,
            });
          }

          setCraftPreventionList(preventionTableArray);
          setLoading(false);
        } else {
          setCraftPreventionList([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setCraftPreventionList([]);
        setLoading(false);
        console.error(err);
      });
  };

  // when the get all the head of preventive list then used...
  const handleGetAllHeadOfPreventiveList = () => {
    dispatch(getCraftPreventiveList())
      .then((res) => {
        if (res.status === 200) {
          setAllHeadOfPreventiveList(res.data);
        } else {
          setAllHeadOfPreventiveList([]);
        }
      })
      .catch((error) => {
        setAllHeadOfPreventiveList([]);
        console.error(error);
      });
  };

  // when the get all the craft list then used...
  const handleGetAllCraftList = () => {
    dispatch(getAllUserWiseBargeList())
      .then((res) => {
        if (res.status === 200) {
          setAllCraftList(res.data);
        } else {
          setAllCraftList([]);
        }
      })
      .catch((error) => {
        setAllCraftList([]);
        console.error(error);
      });
  };

  // when the get year selection's then used...
  const handleGetYearOptions = () => {
    let lastIndex = currentYear - 2023;
    let yearOptionArray = [];
    for (let i = 0; i <= lastIndex; i++) {
      yearOptionArray.push(currentYear + i);
    }
    setYearSelectionOptions(yearOptionArray);
  };

  // when the page first time load then used...
  useEffect(() => {
    handleGetResponsibilityWisePreventionData({
      year: currentYear,
    });
    handleGetAllHeadOfPreventiveList();
    handleGetAllCraftList();
    handleGetYearOptions();
  }, []);

  // when the user select the craft then used...
  const handleSelectCraft = (event) => {
    setSelectedCraftName(event.target.value);
    let values;
    if (
      event.target.value?.length &&
      event?.target?.value !== "select-craft-name" &&
      selectedYear &&
      selectedHeadOfPreventive === "select-preventive"
    ) {
      // year and craft
      values = {
        year: selectedYear,
        bargeId: event.target.value,
      };
    } else if (
      event.target.value?.length &&
      event?.target?.value !== "select-craft-name" &&
      selectedYear &&
      selectedHeadOfPreventive !== "select-preventive"
    ) {
      // year , craft and preventive
      values = {
        year: selectedYear,
        preventiveId: selectedHeadOfPreventive,
        bargeId: event.target.value,
      };
    } else if (
      event.target.value?.length &&
      event?.target?.value === "select-craft-name" &&
      selectedYear &&
      selectedHeadOfPreventive === "select-preventive"
    ) {
      // default and year
      values = {
        year: selectedYear,
      };
    } else if (
      event.target.value?.length &&
      event?.target?.value === "select-craft-name" &&
      selectedYear &&
      selectedHeadOfPreventive !== "select-preventive"
    ) {
      // default , year and preventive
      values = {
        year: selectedYear,
        preventiveId: selectedHeadOfPreventive,
      };
    } else {
      // default
      values = {
        year: selectedYear,
      };
    }
    handleGetResponsibilityWisePreventionData(values);
  };

  // when the user select the year then used...
  const handleChangeYear = (event) => {
    const { value } = event.target;
    setSelectedYear(value);
    let values;

    if (
      value &&
      selectedHeadOfPreventive !== "select-preventive" &&
      selectedCraftName === "select-craft-name"
    ) {
      values = {
        year: value,
        preventiveId: selectedHeadOfPreventive,
      };
    } else if (
      value &&
      selectedHeadOfPreventive === "select-preventive" &&
      selectedCraftName !== "select-craft-name"
    ) {
      values = {
        year: value,
        bargeId: selectedCraftName,
      };
    } else if (
      value &&
      selectedHeadOfPreventive !== "select-preventive" &&
      selectedCraftName !== "select-craft-name"
    ) {
      values = {
        year: value,
        preventiveId: selectedHeadOfPreventive,
        bargeId: selectedCraftName,
      };
    } else {
      values = {
        year: value,
      };
    }
    handleGetResponsibilityWisePreventionData(values);
  };

  return (
    <>
      <Index.DynamicTitle title="Craft Preventive" />
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box
                className="title-main"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Craft Preventive
                </Index.Typography>
              </Index.Box>
              <Index.Box
                className="d-flex align-items-center justify-end res-set-search"
                sx={{ justifyContent: "space-between", width: "350px" }}
              >
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.Box className="input-design-div">
                    <Select
                      value={selectedYear}
                      onChange={handleChangeYear}
                      sx={{ minWidth: "135px" }}
                      MenuProps={MenuProps}
                      className="placeholder-set-text-box"
                    >
                      {/* <MenuItem value="select-year">Select Year</MenuItem> */}
                      {yearSelectionOptions?.map((item) => (
                        <Index.MenuItem value={item} key={item}>
                          {item}
                        </Index.MenuItem>
                      ))}
                    </Select>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.Box className="input-design-div">
                    <Select
                      value={selectedHeadOfPreventive}
                      onChange={handleChangePreventive}
                      sx={{ minWidth: "135px" }}
                      MenuProps={MenuProps}
                      className="placeholder-set-text-box"
                    >
                      <MenuItem value="select-preventive">
                        Select Preventive
                      </MenuItem>
                      {allHeadOfPrventiveList?.map((items, index) => (
                        <Index.MenuItem value={items?._id} key={items?._id}>
                          {items?.headOfPreventive}
                        </Index.MenuItem>
                      ))}
                    </Select>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.Box className="input-design-div">
                    <Select
                      value={selectedCraftName}
                      onChange={handleSelectCraft}
                      sx={{ minWidth: "135px" }}
                      MenuProps={MenuProps}
                      className="placeholder-set-text-box"
                    >
                      <MenuItem value="select-craft-name">
                        Select Craft
                      </MenuItem>
                      {allCraftList?.map((items, index) => (
                        <Index.MenuItem value={items?._id} key={items?._id}>
                          {items?.bargeName}
                        </Index.MenuItem>
                      ))}
                    </Select>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="" sx={{ marginTop: "30px" }}>
            <Index.TableContainer
              component={Index.Paper}
              className="table-container cus-scroll-table cus-scroll-four"
            >
              {loading ? (
                <>
                  <Index.Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Index.CircularProgress color="secondary" size={20} />
                  </Index.Box>
                </>
              ) : (
                <>
                  {craftPreventionList?.length ? (
                    <>
                      {craftPreventionList?.map((craftData) => (
                        <Index.Table
                          aria-label="simple table"
                          className="preventive-table preventive-table-mar"
                          // className="table-design-main barge-table"
                        >
                          <Index.TableHead>
                            {craftData?.preventiveName?.length ? (
                              <>
                                <Index.TableRow className="table-preventive-header">
                                  <Index.TableCell
                                    align="center"
                                    colSpan={17}
                                    className="table-main-header"
                                  >
                                    {craftData?.preventiveName}
                                  </Index.TableCell>
                                </Index.TableRow>
                              </>
                            ) : (
                              <></>
                            )}
                            <Index.TableRow className="table-preventive-header">
                              {/* <Index.TableCell align="left">Sr No</Index.TableCell> */}
                              <Index.TableCell
                                align="left"
                                className="craft-th"
                              >
                                Craft
                              </Index.TableCell>
                              <Index.TableCell
                                align="left"
                                className="responsibility-th"
                              >
                                Responsibility
                              </Index.TableCell>
                              <Index.TableCell align="left" className="item-th">
                                Item
                              </Index.TableCell>
                              <Index.TableCell
                                align="left"
                                className="craft-job-th"
                              >
                                Job{" "}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Jan
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Feb
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Mar
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Apr
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                May
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Jun
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                July
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Aug
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Sep
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Oct
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Nov
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                Dec
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>

                          <Index.TableBody>
                            {craftData?.response?.length > 0 ? (
                              <>
                                {craftData?.response?.map((item, index) => (
                                  <Index.TableRow
                                    key={item?.bargeId + (index + 1)}
                                  >
                                    {item.hasOwnProperty("craftSpanCount") ? (
                                      <Index.TableCell
                                        rowSpan={item?.craftSpanCount}
                                        className="craft-name"
                                      >
                                        {item?.bargeName}
                                      </Index.TableCell>
                                    ) : (
                                      <></>
                                    )}

                                    {item.hasOwnProperty(
                                      "responsibilitySpan"
                                    ) ? (
                                      <Index.TableCell
                                        rowSpan={item?.responsibilitySpan}
                                        className="responsibility-col"
                                      >
                                        {item?.responsibilityName}
                                      </Index.TableCell>
                                    ) : (
                                      <></>
                                    )}
                                    {item.hasOwnProperty("subHeadSpan") ? (
                                      <Index.TableCell
                                        rowSpan={item?.subHeadSpan}
                                        className="item-col"
                                      >
                                        {item?.subHeadName}
                                      </Index.TableCell>
                                    ) : (
                                      <></>
                                    )}
                                    <Index.TableCell className="craft-job-name">
                                      {item?.jobName}
                                    </Index.TableCell>
                                    <Index.TableCell className="table-jan">
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "January"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "January"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "January"
                                                )?.preventiveType
                                              }
                                            </li>

                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "January"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") ===
                                                      "January"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "January"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "February"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "February"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "February"
                                                )?.preventiveType
                                              }
                                            </li>
                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "February"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") ===
                                                      "February"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "February"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "March"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "March"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") === "March"
                                                )?.preventiveType
                                              }
                                            </li>
                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "March"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") ===
                                                      "March"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") === "March"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "April"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "April"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") === "April"
                                                )?.preventiveType
                                              }
                                            </li>
                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "April"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") ===
                                                      "April"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") === "April"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "May"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "May"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") === "May"
                                                )?.preventiveType
                                              }
                                            </li>
                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "May"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") === "May"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") === "May"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "June"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "June"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") === "June"
                                                )?.preventiveType
                                              }
                                            </li>
                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "June"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") ===
                                                      "June"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") === "June"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "July"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "July"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") === "July"
                                                )?.preventiveType
                                              }
                                            </li>
                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "July"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") ===
                                                      "July"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") === "July"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "August"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "August"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "August"
                                                )?.preventiveType
                                              }
                                            </li>
                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "August"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") ===
                                                      "August"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "August"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "September"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "September"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "September"
                                                )?.preventiveType
                                              }
                                            </li>
                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "September"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") ===
                                                      "September"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "September"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "October"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "October"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "October"
                                                )?.preventiveType
                                              }
                                            </li>
                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "October"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") ===
                                                      "October"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "October"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "November"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "November"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "November"
                                                )?.preventiveType
                                              }
                                            </li>
                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "November"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") ===
                                                      "November"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "November"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {item?.monthArray?.find(
                                        (res) =>
                                          moment(res.startDate).format(
                                            "MMMM"
                                          ) === "December"
                                      ) !== undefined ? (
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "December"
                                        ).preventiveStatusMonth === "Yes" ? (
                                          <>
                                            <li>&#10003;</li>
                                            <li>
                                              {
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "December"
                                                )?.preventiveType
                                              }
                                            </li>
                                            {item?.monthArray?.find(
                                              (res) =>
                                                moment(res.startDate).format(
                                                  "MMMM"
                                                ) === "December"
                                            )?.preventiveJobImage?.length ? (
                                              <>
                                                {item?.monthArray
                                                  ?.find(
                                                    (res) =>
                                                      moment(
                                                        res.startDate
                                                      ).format("MMMM") ===
                                                      "December"
                                                  )
                                                  ?.preventiveJobImage?.map(
                                                    (el) => (
                                                      <>
                                                        <Index.ImageIcon
                                                          sx={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              `${process.env.REACT_APP_IMAGE_URL}/preventiveJob/${el}`,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      </>
                                                    )
                                                  )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            <li>
                                              {moment(
                                                item?.monthArray?.find(
                                                  (res) =>
                                                    moment(
                                                      res.startDate
                                                    ).format("MMMM") ===
                                                    "December"
                                                )?.preventiveDate
                                              ).format("DD-MM-YYYY")}
                                            </li>
                                          </>
                                        ) : (
                                          <></> //No
                                        )
                                      ) : (
                                        <></> //No
                                      )}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))}
                              </>
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="no-data-in-list"
                                  colSpan={17}
                                  align="center"
                                >
                                  No data available
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      ))}
                    </>
                  ) : (
                    <Index.Box className="preventive-no-data">
                      No data available
                    </Index.Box>
                  )}
                </>
              )}
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
export default CraftMaintenancePreventionList;
