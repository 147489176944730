import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import APIService from "../../../redux/APIService";
import { toast } from "react-toastify";

export const Export_excel = (csvData, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(csvData);

  // delete ws["H1"];
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const NEW_EXPORT_EXCEL = async (newUrl, newFileName, parameters) => {
  try {
    const excelResponse = await APIService.doPost(
      `${newUrl}`,
      parameters !== null && parameters !== undefined ? parameters : "",
      { responseType: "blob" }
    );

    if (excelResponse?.status === 200) {
      // Create a Blob object from the excelResponse data.
      const blob = new Blob([excelResponse.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);
      const filename = `${newFileName}${Date.now()}.csv`;
      // Create a link element and trigger a click event to start the download
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      // Clean up the temporary URL
      window.URL.revokeObjectURL(url);
    } else {
      toast.error(
        excelResponse?.response?.data?.message || "Something went wrong"
      );
    }
  } catch (error) {
    console.error("CSV export failed:", error);
  }
};
