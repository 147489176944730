import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  addOrder,
  getPortList,
  getBargeList,
  getNameOfVessel,
  cancelOrder,
  getBargeListByCategory,
  getOrderTraderList,
} from "../../../../redux/admin/action";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { terminalData } from "../../../../config/CommonTerminalItems";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import {
  productData,
  transportationData,
} from "../../../../config/CommonProductsItem";
import { commonCFData } from "../../../../config/CommonCF";
// import { nomintorValidationSchema } from "../../../../validation/AdminValidation";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useDateTimePicker } from "../../../../context/DatePickerContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function OrderAdd() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { openPicker, closePicker } = useDateTimePicker();
  const [vesselNameList, setVesselNameList] = useState([]);
  const [allPortList, setAllPortList] = useState([]);
  const [allOrderTraderList, SetAllOrderTraderList] = useState([]);
  const [allTerminalData, setAllTerminalData] = useState([]);
  const [allProductData, setAllProductData] = useState([]);
  const [bargeListCatData, setBargeListCatData] = useState([]);
  const [pdfName, setPdfName] = useState("");
  const [wharfagePdfName, setWharfagePdfName] = useState("");
  const [pdfFilesName, setPdfFilesName] = useState([]);

  // const [portID, setPortID] = useState("");

  //redux data
  const portList = Index.useSelector((state) => state.AdminReducer.portList);
  const { orderTraderList } = Index.useSelector((state) => state.AdminReducer);
  console.log(orderTraderList, 38);

  // const bargeList = Index.useSelector((state) => state.AdminReducer.bargeList);
  const { nameOfVesselList } = Index.useSelector((state) => state.AdminReducer);
  const login = Index.useSelector((state) => state.AdminReducer.adminDetails);
  const { state } = Index.useLocation();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    wharfageFile: Yup.array()
      // .min(1, "Select at least one pdf file")
      .test("fileType", "Unsupported file format. only PDF allow", (value) => {
        // Check if each file has a valid image extension
        if (!value) return false;
        for (const file of value) {
          const validExtensions = ["pdf"]; // Add more valid extensions as needed
          const fileExtension = file?.name
            ? file?.name?.split(".")?.pop().toLowerCase()
            : file.split(".")[1]?.toLowerCase();
          if (!validExtensions.includes(fileExtension)) {
            return false;
          }
        }
        return true;
      }),
    nominationPdf: Yup.mixed().test(
      "FileType",
      "Unsupported file format. only PDF allow",
      (value) => {
        console.log(value, 302);
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["pdf"].includes(file); //edit
        } else {
          return ["application/pdf"].includes(value?.type); //add
        }
      }
    ),
  });

  //edit order
  let editData = state?.row;
  const tabType = state?.type;

  console.log(editData, 78);

  useEffect(() => {
    const vesselData = nameOfVesselList?.filter((d) =>
      d?.vesselName?.toUpperCase()
    );
    const uniqueArray = vesselData?.filter(
      (v, i, a) => a?.findIndex((t) => t?.vesselName === v?.vesselName) === i
    );
    if (login?.role === "Operation") {
      // Role Operation then vesselName show in uppercase
      const newData = uniqueArray?.sort((a, b) =>
        a?.vesselName?.localeCompare(b?.vesselName)
      );
      setVesselNameList(
        newData?.map((e) => ({
          ...e,
          vesselName: e?.vesselName?.toUpperCase(),
        }))
      );
    } else {
      setVesselNameList(
        uniqueArray?.sort((a, b) => a?.vesselName?.localeCompare(b?.vesselName))
      );
    }
    setAllPortList(
      portList?.sort((a, b) => a?.portName?.localeCompare(b?.portName))
    );
    SetAllOrderTraderList(
      orderTraderList?.sort((a, b) =>
        a?.traderName?.localeCompare(b?.traderName)
      )
    );
    setAllTerminalData(
      terminalData?.sort((a, b) => a?.item?.localeCompare(b?.item))
    );
    setAllProductData(
      productData?.sort((a, b) => a?.item?.localeCompare(b?.item))
    );
  }, [nameOfVesselList, portList, terminalData, productData]);

  const filter = createFilterOptions();

  if (editData) {
    editData.id = editData?._id;
    let portId = editData?.port?._id;
    if (portId) {
      editData.port = portId;
    }

    let traderID = editData?.traderId?._id;
    if (traderID) {
      editData.traderId = traderID;
    }

    // if (editData?.proposedBarge) {
    //   let bargeIds = editData
    //     ? editData?.proposedBarge?.map((item) => item?._id)
    //     : [];
    //   if (bargeIds[0]) {
    //     editData.proposedBarge = bargeIds;
    //   }
    // }
  }

  // initial value

  let initialValues = {
    nomination_rec_date: "",
    vesselName: "",
    exmiOrDelivered: "",
    port: "",
    traderId: "",
    terminal: "",
    foreign: "",
    nominatedQty: "",
    product: "",
    agent: "",
    laycam: "",
    laydate: [dayjs(""), dayjs("")],
    loadingDate: "",
    supplyDate: "",
    basisTakenOn: "",
    etb: "",
    etd: "",
    stayHours: "",
    companyName: "",
    transportation: "",
    // proposedBarge: [],
    nominationPdf: "",
    wharfageFile: [],
    shippingBill: false,
    wharfage: false,
    harbourMaster: false,
    bunkerCall: false,
    remark: "",
    // orderDetails: [
    //   { index: Math.random(), product: "", nominatedQty: "" },
    // ],
  };

  if (editData) {
    initialValues = editData;
    initialValues.laydate = [
      dayjs(editData?.layStartDate),
      dayjs(editData?.layEndDate),
    ];

    initialValues.nominatedQty =
      editData?.lowerQuantity === "0" && editData?.higherQuantity === "0"
        ? ""
        : editData?.lowerQuantity && editData?.higherQuantity != "0"
        ? editData?.lowerQuantity + " - " + editData?.higherQuantity
        : editData?.lowerQuantity;

    // const orderDetailsModified = editData?.orderDetails?.map((item) => {
    //   return { ...item, nominatedQty: item?.lowerQuantity + "-" + item?.higherQuantity };
    // });
    // initialValues.orderDetails = orderDetailsModified;
  }

  useEffect(() => {
    if (pdfFilesName) {
      setWharfagePdfName(pdfFilesName);
    }
  }, [pdfFilesName]);

  const bargeListByCategory = (id) => {
    dispatch(getBargeListByCategory(id)).then((res) => {
      if (res) {
        setBargeListCatData(
          res?.data?.sort((a, b) => a?.bargeName?.localeCompare(b?.bargeName))
        );
      }
    });
  };

  useEffect(() => {
    setPdfName(editData?.nominationPdf);
    if (editData?.port) {
      bargeListByCategory(editData?.port);
    } else {
      bargeListByCategory();
    }
    if (editData?.wharfageFile?.length) {
      setWharfagePdfName(editData?.wharfageFile);
    }
  }, [editData]);

  const handleFormSubmit = async (values) => {
    const data = new FormData();
    if (values?.nomination_rec_date) {
      data.append("nomination_rec_date", values?.nomination_rec_date);
    }
    if (values?.vesselName) {
      data.append("vesselName", values?.vesselName?.trim());
    }
    if (values?.port) {
      data.append("port", values?.port);
    }
    if (values?.traderId) {
      data.append("traderId", values?.traderId);
    }
    if (values?.terminal != undefined) {
      data.append("terminal", values?.terminal);
    }
    // if (values?.foreign) {
    data.append("foreign", values?.foreign);
    // }
    if (values?.nominatedQty) {
      data.append("nominatedQty", values.nominatedQty);
    }
    // if (values?.product) {
    data.append("product", values?.product);
    // }
    // if (values?.agent) {
    data.append("agent", values?.agent ? values?.agent : "");
    // }
    // if (values?.laycam) {
    //   data.append("laycam", values?.laycam);
    // }
    if (values?.laycam) {
      data.append(
        "laycam",
        Index.moment(values?.laycam).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }

    if (values?.laydate) {
      const layStartDate = values.laydate[0]["$D"];
      const layEndDate = values.laydate[1]["$D"];

      if (!isNaN(layStartDate) && !isNaN(layEndDate)) {
        data.append(
          "laydate",
          values?.laydate?.map((date) => {
            return Index.moment(date.$d).format("YYYY-MM-DD");
          })
        );
      }
    }
    if (values?.loadingDate) {
      data.append("loadingDate", values?.loadingDate);
    }
    // if (values?.supplyDate) {
    //   data.append("supplyDate", values?.supplyDate);
    // }
    if (values?.supplyDate) {
      data.append(
        "supplyDate",
        Index.moment(values?.supplyDate).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.basisTakenOn) {
      data.append("basisTakenOn", values?.basisTakenOn);
    }
    // if (values?.proposedBarge?.length) {
    //   data.append("proposedBarge", values?.proposedBarge);
    // }
    
    if (values?.etb) {
      data.append(
        "etb",
        Index.moment(values?.etb).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    // if (values?.etd) {
    //   data.append("etd", values?.etd);
    // }
    if (values?.etd) {
      data.append(
        "etd",
        Index.moment(values?.etd).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    // if (values?.stayHours) {
    data.append("stayHours", values?.stayHours ? values?.stayHours : "");
    // }
    if (values?.companyName != undefined) {
      data.append("companyName", values?.companyName);
    }
    if (values?.transportation != undefined) {
      data.append("transportation", values?.transportation);
    }
    if (values?.nominationPdf) {
      data.append("nominationPdf", values?.nominationPdf);
    }
    if (pdfFilesName?.length > 0) {
      // console.log('abcd')
      values.wharfageFile.forEach((ele, i) => {
        data.append("wharfageFile", ele);
      });
    }
    if (values?.exmiOrDelivered != undefined) {
      data.append("exmiOrDelivered", values?.exmiOrDelivered);
    }
    // if (values?.orderDetails) {
    //   values?.orderDetails.forEach((element,i) => {
    //     data.append(`orderDetails[${i}][product]`, element.product);
    //     data.append(`orderDetails[${i}][nominatedQty]`,element.nominatedQty);
    //   });
    // }
    //if (values?.remark) {
    data.append("remark", values?.remark);
    //}
    data.append("shippingBill", values?.shippingBill);
    data.append("wharfage", values?.wharfage);
    data.append("harbourMaster", values?.harbourMaster);
    data.append("bunkerCall", values?.bunkerCall);

    setLoading(true);
    if (editData) {
      data.append("id", editData?._id);
    }
    dispatch(addOrder(data, navigate)).then((res) => {
      if (tabType === "cancelOrder") {
        navigate("/admin/order", { state: { value: 1 } });
      } else if (tabType === "completedOrder") {
        navigate("/admin/order", { state: { value: 2 } });
      } else {
        navigate("/admin/order");
      }
    });
  };

  // focus handler for disabled submit button

  const handleFocus = () => {
    setLoading(false);
  };

  //getAll port and barge
  useEffect(() => {
    dispatch(getPortList());
    dispatch(getBargeList());
    dispatch(getNameOfVessel());
    dispatch(getOrderTraderList());
  }, [dispatch]);

  const backBtn = () => {
    navigate("/admin/order");
    if (tabType === "cancelOrder") {
      navigate("/admin/order", { state: { value: 1 } });
    }
    if (tabType === "completedOrder") {
      navigate("/admin/order", { state: { value: 2 } });
    }
  };
  // const cancelOrderBtn = () => {
  //   const data = new URLSearchParams();
  //   data.append("id", editData?.id);
  //   data.append("cancelStatus", "Cancel");
  //   dispatch(cancelOrder(data, navigate));
  // };

  // useEffect(() => {
  //   bargeListByCategory();
  // }, [portID]);

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit Order" : "Add order"}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                {/* {editData?.orderStatus !== "Cancel" ? (
                  <Index.Button
                    variant="contained"
                    type="submit"
                    className="Particular_model_submit ml-0"
                    onClick={cancelOrderBtn}
                  >
                    Cancel Order
                  </Index.Button>
                ) : (
                  ""
                )} */}
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0 cancel-order-btn"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            // enableReinitialize
            enableReinitialize={editData ? true : false}
            initialValues={initialValues}
            // validationSchema={nomintorValidationSchema}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                {console.log(values, 432)}
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Enquiry No.
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Enquiry No."
                          disabled
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          defaultValue={
                            editData?.inquiryNo ? editData?.inquiryNo : "-"
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Nomination Receiving Date
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box
                          className="set-date set-date-picker picker-box-set "
                          onFocus={handleFocus}
                        >
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  fullWidth
                                  format="DD/MM/YYYY"
                                  name="nomination_rec_date"
                                  maxDate={Index.dayjs(new Date())}
                                  defaultValue={
                                    editData
                                      ? Index.dayjs(
                                          editData?.nomination_rec_date
                                            ? editData?.nomination_rec_date
                                            : "-"
                                        )
                                      : ""
                                  }
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY/MM/DD");
                                    setFieldValue(
                                      "nomination_rec_date",

                                      formatedDate
                                    );
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                          {touched?.nomination_rec_date &&
                            errors?.nomination_rec_date && (
                              <Index.FormHelperText error>
                                {errors?.nomination_rec_date}
                              </Index.FormHelperText>
                            )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Vessel Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Autocomplete
                          className="invoice-select-drop enquiry-vessel-autocomplete"
                          fullWidth
                          value={values?.vesselName}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setFieldValue("vesselName", newValue);
                            } else if (newValue && newValue?.inputValue) {
                              setFieldValue("vesselName", newValue?.inputValue);
                            } else {
                              setFieldValue("vesselName", newValue?.vesselName);
                            }
                          }}
                          onBlur={(event, newValue) => {
                            setFieldValue("vesselName", event.target.value);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;

                            // Suggest the creation of a new value
                            const isExisting = options?.some(
                              (option) => inputValue === option?.vesselName
                            );

                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                vesselName: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={vesselNameList}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xyz" option created dynamically
                            if (option?.inputValue) {
                              return option?.inputValue;
                            }
                            // Regular option
                            return option?.vesselName;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option?.vesselName}</li>
                          )}
                          freeSolo
                          renderInput={(params) => (
                            <Index.TextField
                              className="admin-input-design input-placeholder"
                              {...params}
                              fullWidth
                              placeholder="Vessel name"
                              // label="Free solo with text demo"
                            />
                          )}
                        />
                        {touched?.vesselName && errors?.vesselName && (
                          <Index.FormHelperText error>
                            {errors?.vesselName}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Exmi Or Delivered
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="exmiOrDelivered"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.exmiOrDelivered : ""}
                          onChange={(e) => {
                            setFieldValue("exmiOrDelivered", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select exmi or delivered
                            </Index.MenuItem>
                          </Index.MenuItem>
                          <Index.MenuItem value="Delivered">
                            Delivered
                          </Index.MenuItem>
                          <Index.MenuItem value="Exmi">Exmi</Index.MenuItem>
                        </Index.Select>
                        {touched?.exmiOrDelivered &&
                          errors?.exmiOrDelivered && (
                            <Index.FormHelperText error>
                              {errors?.exmiOrDelivered}
                            </Index.FormHelperText>
                          )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Port
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.port ? (
                          allPortList?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={allPortList}
                              getOptionLabel={(option) => option?.portName}
                              value={
                                allPortList[
                                  allPortList.findIndex(
                                    (e) => e._id === values?.port
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.portName}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("port", newValue?._id);
                                // setFieldValue("proposedBarge", []);
                                bargeListByCategory(newValue?._id);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Port name"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={allPortList}
                            getOptionLabel={(option) => option?.portName}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.portName}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("port", newValue?._id);
                              // setFieldValue("proposedBarge", []);
                              bargeListByCategory(newValue?._id);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Port name"
                                variant="outlined"
                              />
                            )}
                          />
                        )}

                        {touched?.port && errors?.port && (
                          <Index.FormHelperText error>
                            {errors?.port}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>
                    {/* 
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Order Received From / Trader
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Order received from / trader"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="trader"
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          value={values?.trader}
                          error={Boolean(
                            errors?.trader ? touched?.trader : undefined
                          )}
                          helperText={
                            touched?.trader ? errors?.trader : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid> */}
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Order Received From / Trader
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.traderId ? (
                          allOrderTraderList?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={allOrderTraderList}
                              getOptionLabel={(option) => option?.traderName}
                              value={
                                allOrderTraderList[
                                  allOrderTraderList.findIndex(
                                    (e) => e._id === values?.traderId
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.traderName}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("traderId", newValue?._id);
                                // setFieldValue("proposedBarge", []);
                                // bargeListByCategory(newValue?._id);
                                // setPortID(newValue?._id);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Order Received From / Trader"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={allOrderTraderList}
                            getOptionLabel={(option) => option?.traderName}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.traderName}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("traderId", newValue?._id);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Order Received From / Trader"
                                variant="outlined"
                              />
                            )}
                          />
                        )}

                        {touched?.traderId && errors?.traderId && (
                          <Index.FormHelperText error>
                            {errors?.traderId}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Terminal
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.terminal ? (
                          allTerminalData?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={allTerminalData}
                              getOptionLabel={(option) => option?.item}
                              value={
                                allTerminalData[
                                  allTerminalData.findIndex(
                                    (e) => e.item === values?.terminal
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.item}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("terminal", newValue?.item);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Terminal name"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={allTerminalData}
                            getOptionLabel={(option) => option?.item}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.item}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("terminal", newValue?.item);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Terminal name"
                                variant="outlined"
                              />
                            )}
                          />
                        )}

                        {touched?.terminal && errors?.terminal && (
                          <Index.FormHelperText error>
                            {errors?.terminal}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        C/F
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.foreign ? (
                          commonCFData?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={commonCFData}
                              getOptionLabel={(option) => option?.name}
                              value={
                                commonCFData[
                                  commonCFData.findIndex(
                                    (e) => e?.name === values?.foreign
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.name}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("foreign", newValue?.name);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="C/F name"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={commonCFData}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.name}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("foreign", newValue?.name);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="C/F name"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                        {touched?.foreign && errors?.foreign && (
                          <Index.FormHelperText error>
                            {errors?.foreign}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Product Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="terminal"
                          value={values?.product}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.product : ""}
                          onChange={(e) => {
                            setFieldValue("product", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select product
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {allProductData?.map((items, index) => (
                            <Index.MenuItem
                              value={items?.item}
                              key={items?._id}
                            >
                              {items?.item}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                        {touched.product && errors.product && (
                          <Index.FormHelperText error>
                            {errors.product}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Nominated Qty (In MT)
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Nominated qty (In MT)"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="nominatedQty"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                          value={values?.nominatedQty}
                          // inputProps={}
                          step={1.0}
                          error={Boolean(
                            errors?.nominatedQty
                              ? touched?.nominatedQty
                              : undefined
                          )}
                          helperText={
                            touched?.nominatedQty
                              ? errors?.nominatedQty
                              : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Agent
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Agent"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="agent"
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.agent}
                          error={Boolean(
                            errors?.agent ? touched?.agent : undefined
                          )}
                          helperText={
                            touched?.agent ? errors?.agent : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        ETA for Vessel
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="laycam"
                                clearButton
                                defaultValue={
                                  values?.laycam
                                    ? dayjs(
                                        Index.moment(editData?.laycam).format(
                                          "YYYY-MM-DDTHH:mm"
                                        )
                                      )
                                    : ""
                                }
                                onOpen={() => {
                                  openPicker();
                                }}
                                onClose={() => {
                                  closePicker();
                                }}
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("laycam", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        ETB
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="etb"
                                defaultValue={
                                  values?.etb
                                    ? dayjs(
                                        Index.moment(editData?.etb).format(
                                          "YYYY-MM-DDTHH:mm"
                                        )
                                      )
                                    : ""
                                }
                                onOpen={() => {
                                  openPicker();
                                }}
                                onClose={() => {
                                  closePicker();
                                }}
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("etb", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        ETD
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="etd"
                                defaultValue={
                                  values?.etd
                                    ? dayjs(
                                        Index.moment(editData?.etd).format(
                                          "YYYY-MM-DDTHH:mm"
                                        )
                                      )
                                    : ""
                                }
                                onOpen={() => {
                                  openPicker();
                                }}
                                onClose={() => {
                                  closePicker();
                                }}
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("etd", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Stay Hours
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Stay hours"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="stayHours"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                          value={values?.stayHours}
                          error={Boolean(
                            errors?.stayHours ? touched?.stayHours : undefined
                          )}
                          helperText={
                            touched?.stayHours ? errors?.stayHours : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Company Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="companyName"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.companyName : ""}
                          onChange={(e) => {
                            setFieldValue("companyName", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select company
                            </Index.MenuItem>
                          </Index.MenuItem>
                          <Index.MenuItem value="BSJPL">BSJPL</Index.MenuItem>
                          <Index.MenuItem value="SKQPL">SKQPL</Index.MenuItem>
                        </Index.Select>
                        {touched?.companyName && errors?.companyName && (
                          <Index.FormHelperText error>
                            {errors?.companyName}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Laycan Date
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  format="DD/MM/YYYY"
                                  minDate={Index.dayjs(new Date())}
                                  defaultValue={
                                    editData
                                      ? Index.dayjs(editData?.laydate)
                                      : ""
                                  }
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DD");
                                    setFieldValue("laydate", formatedDate);
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid> */}

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Laycan From to To
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker remove-name picker-box-set">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "DateRangePicker",
                                "DateRangePicker",
                              ]}
                            >
                              <DateRangePicker
                                format="DD/MM/YYYY"
                                value={values?.laydate}
                                onChange={(newValue) => {
                                  setFieldValue("laydate", newValue);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                          {touched?.laydate && errors?.laydate && (
                            <Index.FormHelperText error>
                              {errors?.laydate[0]}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Loading Planned Date
                      </Index.Typography>
                      <Index.Box className="input-design-div ">
                        <Index.Box
                          className="set-date set-date-picker picker-box-set"
                          onFocus={handleFocus}
                        >
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  name="loadingDate"
                                  format="DD/MM/YYYY"
                                  defaultValue={
                                    editData
                                      ? Index.dayjs(
                                          editData?.loadingDate
                                            ? editData?.loadingDate
                                            : "-"
                                        )
                                      : ""
                                  }
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DD");
                                    setFieldValue("loadingDate", formatedDate);
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                          {touched?.loadingDate && errors?.loadingDate && (
                            <Index.FormHelperText error>
                              {errors?.loadingDate}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Transportation
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="transportation"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.transportation : ""}
                          onChange={(e) => {
                            setFieldValue("transportation", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select transportation
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {transportationData?.map((item, index) => {
                            return (
                              <Index.MenuItem value={item?.name}>
                                {item?.name}
                              </Index.MenuItem>
                            );
                          })}
                        </Index.Select>
                        {touched?.transportation && errors?.transportation && (
                          <Index.FormHelperText error>
                            {errors?.transportation}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Supply Date
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="supplyDate"
                                defaultValue={
                                  values?.supplyDate
                                    ? dayjs(
                                        Index.moment(
                                          editData?.supplyDate
                                        ).format("YYYY-MM-DDTHH:mm")
                                      )
                                    : ""
                                }
                                onOpen={() => {
                                  openPicker();
                                }}
                                onClose={() => {
                                  closePicker();
                                }}
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("supplyDate", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Basis Taken On
                      </Index.Typography>
                      <Index.Box className="input-design-div ">
                        <Index.Box
                          className="set-date set-date-picker picker-box-set"
                          onFocus={handleFocus}
                        >
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  name="basisTakenOn"
                                  format="DD/MM/YYYY"
                                  defaultValue={
                                    editData
                                      ? Index.dayjs(
                                          editData?.basisTakenOn
                                            ? editData?.basisTakenOn
                                            : "-"
                                        )
                                      : ""
                                  }
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY/MM/DD");
                                    setFieldValue("basisTakenOn", formatedDate);
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                          {touched?.basisTakenOn && errors?.basisTakenOn && (
                            <Index.FormHelperText error>
                              {errors?.basisTakenOn}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Box className="input-design-div with-border">
                        <Index.FormControlLabel
                          control={
                            <Index.Checkbox
                              checked={values?.shippingBill}
                              onChange={(event) => {
                                setFieldValue(
                                  "shippingBill",
                                  event.target.checked
                                );
                              }}
                            />
                          }
                          label="Shipping Bill"
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Box className="input-design-div with-border">
                        <Index.FormControlLabel
                          control={
                            <Index.Checkbox
                              checked={values?.wharfage}
                              onChange={(event) => {
                                setFieldValue("wharfage", event.target.checked);
                              }}
                            />
                          }
                          label="Wharfage"
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Box className="input-design-div with-border">
                        <Index.FormControlLabel
                          control={
                            <Index.Checkbox
                              checked={values?.harbourMaster}
                              onChange={(event) => {
                                setFieldValue(
                                  "harbourMaster",
                                  event.target.checked
                                );
                              }}
                            />
                          }
                          label="Harbour Master Permission"
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Box className="input-design-div with-border">
                        <Index.FormControlLabel
                          control={
                            <Index.Checkbox
                              checked={values?.bunkerCall}
                              onChange={(event) => {
                                setFieldValue(
                                  "bunkerCall",
                                  event.target.checked
                                );
                              }}
                            />
                          }
                          label="Bunker Call"
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={6}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Proforma Invoice PDF
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border position-relative">
                        <Index.Box className="pdf-design">
                          Upload PDF :
                        </Index.Box>
                        <Index.TextField
                          fullWidth
                          type="file"
                          name="nominationPdf"
                          id="filled-hidden-label-normal"
                          variant="filled"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          className="admin-input-design input-placeholder pdf-upload-design"
                          onChange={(e) => {
                            setFieldValue("nominationPdf", e.target?.files[0]);
                            setPdfName(e.target?.files[0]?.name);
                          }}
                          inputProps={{
                            className: "input_props",
                          }}
                          FormHelperTextProps={{
                            className: "input_label_props",
                          }}
                        />
                        {touched?.nominationPdf && errors?.nominationPdf && (
                          <Index.FormHelperText error>
                            {errors?.nominationPdf}
                          </Index.FormHelperText>
                        )}
                        <br />
                        {pdfName && (
                          <>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              {pdfName}
                            </Index.Typography>
                          </>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={6}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Wharfage PDF
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border position-relative">
                        <Index.Box className="pdf-design">
                          Upload PDF :
                        </Index.Box>
                        <Index.TextField
                          fullWidth
                          type="file"
                          name="wharfageFile"
                          multiple
                          id="filled-hidden-label-normal"
                          variant="filled"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          className="admin-input-design input-placeholder pdf-upload-design"
                          onChange={(event) => {
                            const files = Array.from(event.target.files);
                            setFieldValue("wharfageFile", [...files]);
                            const fileNames = files.map((item) => item.name);
                            // console.log(files, 'files', fileNames);
                            setPdfFilesName(fileNames);
                          }}
                          inputProps={{
                            multiple: true,
                          }}
                          FormHelperTextProps={{
                            className: "input_label_props",
                          }}
                        />
                        {touched?.wharfageFile && errors?.wharfageFile && (
                          <Index.FormHelperText error>
                            {errors?.wharfageFile}
                          </Index.FormHelperText>
                        )}
                        <br />
                        {wharfagePdfName?.length > 0 &&
                          wharfagePdfName?.map((item, i) => {
                            return (
                              <>
                                <Index.Typography
                                  key={i}
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  {i + 1}. {item}
                                </Index.Typography>
                              </>
                            );
                          })}
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Proposed Barge
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border ">
                        <Index.Select
                          className="propose-drop"
                          fullWidth
                          name="proposedBarge"
                          multiple
                          displayEmpty
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "without label" }}
                          value={
                            values?.proposedBarge ? values?.proposedBarge : []
                          }
                          renderValue={
                            values?.proposedBarge != ""
                              ? undefined
                              : () => "Please select barge"
                          }
                          onFocus={handleFocus}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            setFieldValue(
                              "proposedBarge",
                              typeof value === "string"
                                ? value.split(",")
                                : value
                            );
                          }}
                        >
                          <Index.MenuItem disabled value="">
                            {bargeListCatData?.length > 0 ? (
                              <em>Please select a barge</em>
                            ) : (
                              ""
                            )}
                          </Index.MenuItem>
                          {bargeListCatData?.length === 0 ? (
                            <Index.MenuItem disabled>
                              <Index.Box className="multiselect-item">
                                No data found
                              </Index.Box>
                            </Index.MenuItem>
                          ) : (
                            bargeListCatData.map((item) => (
                              <Index.MenuItem key={item?._id} value={item?._id}>
                                <Index.Box className="multiselect-item">
                                  {item?.bargeName}
                                </Index.Box>
                              </Index.MenuItem>
                            ))
                          )}
                        </Index.Select>
                        {touched?.proposedBarge && errors?.proposedBarge && (
                          <Index.FormHelperText error>
                            {errors?.proposedBarge}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid> */}
                    <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Remark
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextareaAutosize
                          hiddenLabel
                          minRows={3}
                          id="filled-hidden-label-normal"
                          className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set "
                          placeholder="Remark"
                          variant="filled"
                          name="remark"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // onFocus={handleFocus}
                          value={values?.remark}
                        />{" "}
                        {touched?.remark && errors?.remark && (
                          <Index.FormHelperText error>
                            {errors?.remark}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>

                  <Index.Box className="line" mt={3} mb={4}></Index.Box>
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default OrderAdd;
