import { useEffect, useState } from "react";
import Index from "../../../Index";
import { deleteTask, getAllTasks } from "../../../../redux/admin/action";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function TaskList() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [filterByMe, setFilterByMe] = useState("All");
  const [filterByPriority, setFilterByPriority] = useState("");
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);

  // Delete record state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  //Delete Model
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setRowId("");
  };

  //Handle task edit
  const handleEdit = (item) => {
    navigate(`/admin/tasks/edit/${item?._id}`);
  };
  //Handle task view
  const handleTaskView = (id) => {
    navigate(`/admin/tasks/view/${id}`);
  };

  //Delete record

  const handleDeleteRecord = () => {
    let data = {
      id: rowId,
    };
    try {
      dispatch(deleteTask(data)).then((res) => {
        if (res?.status) {
          getTaskList();
        }
      });
    } catch (error) {}
    handleDeleteClose();
  };

  // Initial data filter by priority
  useEffect(() => {
    const filtered = taskList?.filter((ele) =>
      filterByPriority ? ele?.priority?.toLowerCase() === filterByPriority : ele
    );
    if (filtered?.length > 0) {
      setFilterData(filtered);
    } else {
      setFilterData([]);
    }
  }, [taskList, filterByPriority]);

  // Search on table

  useEffect(() => {
    const filtered = filterData?.filter((item) => {
      return !search
        ? item
        : item?.title
            ?.toString()
            .toLowerCase()
            .includes(search?.toLowerCase()) ||
            item?.status
              ?.toString()
              .toLowerCase()
              .includes(search?.toLowerCase()) ||
            item?.priority
              ?.toString()
              .toLowerCase()
              .includes(search?.toLowerCase()) ||
            Index.moment(item?.startDate)
              .format("DD/MM/YYYY")
              ?.toString()
              .toLowerCase()
              .includes(search?.toLowerCase()) ||
            Index.moment(item?.deadline)
              .format("DD/MM/YYYY")
              ?.toString()
              .toLowerCase()
              .includes(search?.toLowerCase()) ||
            item?.assignTo?.firstName
              ?.toString()
              .toLowerCase()
              .includes(search?.toLowerCase()) ||
            item?.createdBy?.firstName
              ?.toString()
              .toLowerCase()
              .includes(search?.toLowerCase()) ||
            item?.status
              ?.toString()
              .toLowerCase()
              .includes(search?.toLowerCase());
    });

    if (filtered?.length > 0) {
      setSearchedData(filtered);
    } else {
      setSearchedData([]);
    }
  }, [filterData, search]);

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    const serialNumber = (currentPage - 1) * PER_PAGE + 1;
    setCurrentRows(
      modifiedRows &&
        modifiedRows.map((ele, index) => {
          return { ...ele, sNo: serialNumber + index };
        })
    );
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getTaskList = () => {
    setLoading(true);
    let data = {
      relatedTo: filterByMe?.toUpperCase(),
    };
    try {
      dispatch(getAllTasks(data)).then((res) => {
        if (res?.status === 200) {
          setTaskList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    getTaskList();
  }, [filterByMe]);
  return (
    <>
      <Index.DynamicTitle title="Trader Master" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Task List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    value={search}
                    onChange={
                      (e) => setSearch(e.target.value.trim())
                      // setTimeout(() => {
                      //   requestSearch(e.target.value.trim());
                      // }, 1000)
                    }
                  />
                </Search>
                <Search className="search ">
                  <Index.Box className="input-design-div with-border filter-input-design">
                    <Index.Autocomplete
                      disablePortal
                      className="invoice-select-drop"
                      id="combo-box-demo"
                      options={[
                        { name: "Low" },
                        { name: "Medium" },
                        { name: "High" },
                      ]}
                      getOptionLabel={(option) => option?.name}
                      renderOption={(props, option) => (
                        <Index.Box
                          component="li"
                          sx={{
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          {option?.name}
                        </Index.Box>
                      )}
                      onChange={(event, newValue) => {
                        setTimeout(() => {
                          setFilterByPriority(newValue?.name?.toLowerCase());
                        }, 1000);
                        setSearch("");
                      }}
                      value={
                        [
                          { name: "Low" },
                          { name: "Medium" },
                          { name: "High" },
                        ]?.find(
                          (ele) =>
                            ele?.name?.toLocaleLowerCase() === filterByPriority
                        ) || null
                      }
                      renderInput={(params) => (
                        <Index.TextField
                          {...params}
                          placeholder="Sort"
                          variant="outlined"
                        />
                      )}
                    />
                  </Index.Box>
                </Search>
                <Search className="search ">
                  <Index.Box className="input-design-div with-border filter-input-design">
                    <Index.Autocomplete
                      disablePortal
                      className="invoice-select-drop"
                      id="combo-box-demo"
                      options={[{ name: "All" }, { name: "Me" }]}
                      getOptionLabel={(option) => option?.name}
                      renderOption={(props, option) => (
                        <Index.Box
                          component="li"
                          sx={{
                            "& > img": { mr: 2, flexShrink: 0 },
                          }}
                          {...props}
                        >
                          {option?.name}
                        </Index.Box>
                      )}
                      onChange={(event, newValue) => {
                        setFilterByMe(newValue?.name);
                        setFilterByPriority("");
                        setSearch("");
                      }}
                      value={[{ name: "All" }, { name: "Me" }]?.find(
                        (ele) => ele?.name === filterByMe
                      )}
                      renderInput={(params) => (
                        <Index.TextField
                          {...params}
                          placeholder="Related To"
                          variant="outlined"
                        />
                      )}
                    />
                  </Index.Box>
                </Search>
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/tasks/add"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Task
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table trader-list "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">Sr. No</Index.TableCell>
                    <Index.TableCell align="left">Title</Index.TableCell>
                    <Index.TableCell align="left">Start Date</Index.TableCell>
                    <Index.TableCell align="left">Deadline</Index.TableCell>
                    <Index.TableCell align="left">Assigned To</Index.TableCell>
                    <Index.TableCell align="left">Status</Index.TableCell>
                    <Index.TableCell align="left">Priority</Index.TableCell>
                    <Index.TableCell align="left">Created By</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>{item?.sNo}</Index.TableCell>
                          <Index.TableCell>{item.title || "-"}</Index.TableCell>

                          <Index.TableCell>
                            {item.startDate
                              ? Index.moment(item?.startDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item.deadline
                              ? Index.moment(item?.deadline).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item.assignTo?.firstName
                              ? `${item.assignTo?.firstName} ${item.assignTo?.lastName}`
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item.status || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item.priority || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item.createdBy?.firstName
                              ? `${item.createdBy?.firstName}`
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.IconButton>
                              <Index.VisibilitySharpIcon
                                onClick={() => handleTaskView(item?._id)}
                              />
                            </Index.IconButton>
                            <Index.IconButton>
                              <Index.EditIcon
                                className="edit-icon-set"
                                onClick={() => handleEdit(item)}
                              />
                            </Index.IconButton>
                            <Index.IconButton>
                            <Index.DeleteIcon
                                color="error"
                                onClick={() => {
                                  setRowId(item?._id);
                                  handleDeleteOpen();
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
}

export default TaskList;
